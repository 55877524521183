<dx-data-grid #invoiceDataGridRef id="gridContainer" [dataSource]="invoicesDataSource" keyExpr="guid"
	[wordWrapEnabled]="true" [hoverStateEnabled]="true" [showBorders]="true" [showColumnLines]="true"
	[showRowLines]="false" [allowColumnResizing]="true" [filterSyncEnabled]="true" [filterValue]="filterValue"
	[remoteOperations]="false" [columnAutoWidth]="true" [allowColumnReordering]="true" [width]="'100%'"
	(onEditorPreparing)="doOnEditorPreparingForInvoice($event)" (onToolbarPreparing)="doInvoiceToolbarPreparing($event)"
	(onInitialized)="initializedInvoiceDataGrid($event)" class="cellColor hoverClr hideFilterPanelInx"
	(onCellClick)="doOnCellClick($event)" [customizeColumns]="doCustomizeColumnsDataGrid">
	<dxo-scrolling columnRenderingMode="virtual" [scrollByThumb]="true" [showScrollbar]="'always'"
		[useNative]="false"></dxo-scrolling>
	<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
	<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
		[showInfo]="true">
	</dxo-pager>
	<dxo-filter-row [visible]="true"></dxo-filter-row>
	<dxo-filter-panel [visible]="true"></dxo-filter-panel>
	<dxo-group-panel [visible]="true"></dxo-group-panel>
	<dxo-header-filter [visible]="false"></dxo-header-filter>
	<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
	<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadInvoiceState" [customSave]="doSaveInvoiceState"
		[storageKey]="stateStorageKey">
	</dxo-state-storing>
	<div *dxTemplate="let data of 'templateFilterText'">
		<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
			<span>{{filterText}}</span>
		</div>
	</div>

	<div *dxTemplate="let data of 'templateActionBtns'">
		<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false" [showArrowIcon]="false"
			text="Action" class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
			[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code" icon="spindown"
			(onButtonClick)="doActionsBtnClick($event)" (onItemClick)="doActionsBtnItemClick($event)"
			[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
	</div>
	<dxi-column dataField="clientName" caption="Customer Name" dataType="string" [width]="'auto'"></dxi-column>
	<dxi-column dataField="customer_age" caption="Customer Age" [visible]="isCustomerAgeTiers" dataType="string"
		[width]="'auto'" [showInColumnChooser]="isCustomerAgeTiers">
	</dxi-column>
	<dxi-column dataField="document_type" dataType="string" [allowSorting]="true" caption="Doc. Type"
		[allowFiltering]="false" [allowHeaderFiltering]="true" cellTemplate="cellTemplateDocType" [width]="'auto'">
		<dxo-header-filter [dataSource]="listOfDocTypeHeaderFilter">
		</dxo-header-filter>
		<div *dxTemplate="let element of 'cellTemplateDocType'">
			{{element.data.document_type | filterFromList:listOfDocTypeHeaderFilter : 'value'
			: 'text' }}
		</div>
	</dxi-column>
	<dxi-column dataField="classname" dataType="string" [showInColumnChooser]="enable_classification_for_commision"
		caption="Class" [width]="'auto'"></dxi-column>
	<dxi-column dataField="description" dataType="string" [showInColumnChooser]="!enable_classification_for_commision"
		caption="Description" [width]="'auto'">
	</dxi-column>
	<dxi-column dataField="itemname" dataType="string" [allowSorting]="true"
		[showInColumnChooser]="!enable_classification_for_commision" caption="Items" [allowHeaderFiltering]="false"
		[width]="'auto'">
	</dxi-column>
	<dxi-column dataField="invoiceCode" dataType="string" [allowSorting]="true" caption="Doc. No"
		[allowHeaderFiltering]="false" [width]="'auto'" cellTemplate="cellTemplateDocNo">
		<div *dxTemplate="let element of 'cellTemplateDocNo'">
			<span class="highlight pointerMark">{{element.data.invoiceCode}}</span>
		</div>
	</dxi-column>
	<dxi-column dataField="branch" dataType="string" [allowSorting]="true"
		[showInColumnChooser]="erp_type === ErpTypeValEnum.EPICOR_PROPHET21" caption="Branch"
		[allowHeaderFiltering]="false" [width]="'auto'">
	</dxi-column>
	<dxi-column dataField="uomcode" dataType="string" caption="Doc. UOM" cellTemplate="cellTemplateUom"
		[allowFiltering]="false" [allowHeaderFiltering]="true" [allowSorting]="true" [width]="'auto'">
		<dxo-header-filter [dataSource]="listOfUomsHeaderFilter">
		</dxo-header-filter>
		<div *dxTemplate="let element of 'cellTemplateUom'">
			{{element.data.uomcode || '' | filterFromList:listOfUoms : 'code' : 'name' |
			uppercase }}
		</div>
	</dxi-column>
	<dxi-column dataField="quantity" [allowSorting]="true" caption="Doc. Qty" dataType="number"
		cellTemplate="cellTemplateQuantity" [allowHeaderFiltering]="false" [width]="'auto'">
		<div *dxTemplate="let element of 'cellTemplateQuantity'">
			{{element.data.quantity || 0 }}
		</div>
	</dxi-column>
	<dxi-column dataField="contract_selectedUom" dataType="string" caption="Contract UOM"
		cellTemplate="cellTemplateContractUom" [allowFiltering]="false" [allowHeaderFiltering]="true"
		[allowSorting]="true" [width]="'auto'">
		<dxo-header-filter [dataSource]="listOfUomsHeaderFilter">
		</dxo-header-filter>
		<div *dxTemplate="let element of 'cellTemplateContractUom'">
			{{element.data.contract_selectedUom || '' | filterFromList:listOfUoms :'code' :
			'name' |
			uppercase }}
		</div>
	</dxi-column>

	<dxi-column dataField="invoiceDate" dataType="string" [allowSorting]="true" caption="Doc. Date"
		[allowHeaderFiltering]="false" [width]="'auto'" cellTemplate="cellTemplateDocDate">
		<div *dxTemplate="let element of 'cellTemplateDocDate'">
			{{element.data.invoiceDate}}
		</div>
	</dxi-column>

	<dxi-column dataField="doc_close_date" dataType="string" [allowSorting]="true" caption="Date Paid"
		[allowHeaderFiltering]="false" [width]="'auto'" cellTemplate="cellTemplateDocCloseDate">
		<div *dxTemplate="let element of 'cellTemplateDocCloseDate'">
			{{element.data.doc_close_date}}
		</div>
	</dxi-column>

	<dxi-column dataField="contract_quantity" [allowSorting]="true" caption="Contract Qty" dataType="number"
		cellTemplate="cellTemplateContractQty" [allowHeaderFiltering]="false" [width]="'auto'">
		<div *dxTemplate="let element of 'cellTemplateContractQty'">
			{{element.data.contract_quantity || 0}}
		</div>
	</dxi-column>
	<dxi-column dataField="tier_basis" dataType="string" caption="Commission Type" cellTemplate="cellTemplateTierBasis"
		[allowFiltering]="false" [allowHeaderFiltering]="true" [allowSorting]="true" [width]="'auto'">
		<dxo-header-filter [dataSource]="listOfTierBasisHeaderFilter">
		</dxo-header-filter>
		<div *dxTemplate="let element of 'cellTemplateTierBasis'">
			{{element.data.tier_basis || '' | filterFromList:listOfTierBasis : 'code' :
			'label' | uppercase}}
		</div>
	</dxi-column>
	<dxi-column dataField="commission_basis" dataType="string" caption="Commission Basis" [width]="'auto'"
		cellTemplate="cellTemplateCommissionBasis" [allowFiltering]="false" [allowHeaderFiltering]="true"
		[allowSorting]="true">
		<dxo-header-filter [dataSource]="listOfCommissionBasisHeaderFilter">
		</dxo-header-filter>
		<div *dxTemplate="let element of 'cellTemplateCommissionBasis'">
			{{element.data.commission_basis || '' | filterFromList:listOfCommissionBasis :
			'code' : 'label' | uppercase}}
		</div>
	</dxi-column>
	<dxi-column dataField="commissionRate" dataType="number" [allowFiltering]="false" [allowSorting]="true"
		[caption]="'Commission Rate (% | '+globalCurrencySymbol+')'" alignment="center" [allowHeaderFiltering]="false"
		[width]="'auto'" cellTemplate="cellTemplateCommitionsRate">
		<dxo-format type="currency" [precision]="decimalPointForCurrency">
		</dxo-format>
		<div *dxTemplate="let element of 'cellTemplateCommitionsRate'">
			<span *ngIf="element.data.commissionRateIn === '$'">
				{{element.data.commissionRate || 0 |
				customCurrencyPipe:customCurrencyOption }}
			</span>
			<span *ngIf="element.data.commissionRateIn === '%'">
				{{(element.data.commissionRate || 0 | customNumberNoRoundingPipe) + '%'}}
			</span>
		</div>
	</dxi-column>
	<dxi-column dataField="serviceArCreditMemoValue" [allowSorting]="true" caption="Credit Memos (Service Type)"
		dataType="number" [allowFiltering]="false" [allowSorting]="false" [allowHeaderFiltering]="false"
		[format]="currencyFormat">
	</dxi-column>
	<dxi-column dataField="condition" dataType="string" caption="Condition" [allowSorting]="true"
		[width]="'auto'"></dxi-column>
	<dxi-column dataField="criterion" dataType="string" caption="Criterion" [allowSorting]="true"
		[width]="'auto'"></dxi-column>
	<dxi-column dataField="linetotal" [caption]="'Line Total ('+globalCurrencySymbol+')'" dataType="number"
		[allowFiltering]="false" [allowSorting]="false" name="linetotal3" [width]="'auto'" [allowHeaderFiltering]="false"
		[format]="currencyFormat">

	</dxi-column>
	<dxi-column dataField="miscDedAmount" [allowFiltering]="false" [allowSorting]="true" caption="Misc. Deduction"
		dataType="number" [width]="'auto'" [allowHeaderFiltering]="false">
		<div *dxTemplate="let element of 'cellTemplateMiscDedAmount'">
			{{element.data.miscDedAmount || 0 | customCurrencyPipe:customCurrencyOption }}
			({{element.data.miscdeductioninventory || 0 |
			number:decimalPointPercentageFormat}}%)
		</div>
	</dxi-column>
	<dxi-column dataField="miscDedFcAmount" [allowFiltering]="false" [allowSorting]="true"
		caption="Misc. Deduction Fixed Cost" dataType="number" [width]="'auto'" [allowHeaderFiltering]="false"
		[format]="currencyFormat">
	</dxi-column>
	<dxi-column dataField="cashdiscount" [allowFiltering]="false" [allowSorting]="true"
		[caption]="'Cash Discount ('+globalCurrencySymbol+')'" dataType="number" [allowHeaderFiltering]="false"
		[width]="'auto'" [format]="currencyFormat">
	</dxi-column>
	<dxi-column dataField="finalAmount" [allowFiltering]="false" [allowSorting]="true"
		[caption]="'Final Line Total ('+globalCurrencySymbol+')'" dataType="number" [width]="'auto'"
		[allowHeaderFiltering]="false" [format]="currencyFormat">
	</dxi-column>
	<dxi-column dataField="costtotal" dataType="number" [allowFiltering]="false" [allowSorting]="true"
		[caption]="'Cost ('+globalCurrencySymbol+')'" [width]="'auto'" [format]="currencyFormat">

	</dxi-column>
	<dxi-column dataField="paymentParcent" dataType="number" [customizeText]="doCustomizeTextToPercent"
		[allowFiltering]="false" [allowSorting]="true" [width]="'auto'" caption="Payment (%)">
	</dxi-column>
	<dxi-column dataField="grossprofit" dataType="number" [allowFiltering]="false" [allowSorting]="true"
		[caption]="'Profit ('+globalCurrencySymbol+')'" F [width]="'auto'" [format]="currencyFormat">
	</dxi-column>
	<dxi-column dataField="doccurrency" dataType="string" [allowFiltering]="false" [allowSorting]="true"
		caption="Doc Currency" [width]="'auto'">
	</dxi-column>
	<dxi-column dataField="exchangerate" dataType="number" [allowFiltering]="false" [allowSorting]="true"
		caption="Exchange Rate" [width]="'auto'">
	</dxi-column>
	<dxi-column dataField="orgCommissionAmount" dataType="number" [allowFiltering]="false" [allowSorting]="true"
		caption="Original Commission" [width]="'auto'" [calculateCellValue]="doCalculateCellValueOrgCommissionAmount">
	</dxi-column>
	<dxi-column dataField="rebate_amount" dataType="number" [allowFiltering]="false" [allowSorting]="true"
		caption="Estimated Rebate Commission" [width]="'auto'">
		<div *dxTemplate="let element of 'cellTemplateExtemeatedCommission'">
			<span [ngClass]="{'highlight pointerMark': (element.data.rebate_amount) > 0}">
				{{element.data.rebate_amount || 0 | customCurrencyPipe:customCurrencyOption }}</span>
		</div>
	</dxi-column>
	<dxi-column dataField="commissionAmount" dataType="number" [allowFiltering]="false" [allowSorting]="true"
		caption="Commission Payable" [width]="'auto'" [format]="currencyFormat">
	</dxi-column>
	<dxi-column dataField="accrual_commissionAmount" dataType="number" [allowFiltering]="false" [allowSorting]="true"
		caption="Accrued Commission" [showInColumnChooser]="enable_incentive_accrual_journal_entry" [width]="'auto'"
		[format]="currencyFormat">
	</dxi-column>
	<dxo-summary>
		<dxi-total-item column="linetotal" summaryType="sum" displayFormat="{0}" [valueFormat]="currencyFormat">
		</dxi-total-item>
		<dxi-total-item column="serviceArCreditMemoValue" summaryType="sum" displayFormat="{0}"
			[valueFormat]="currencyFormat">
		</dxi-total-item>
		<dxi-total-item column="miscDedAmount" summaryType="sum" displayFormat="{0}" [valueFormat]="currencyFormat">
		</dxi-total-item>
		<dxi-total-item column="miscDedFcAmount" summaryType="sum" displayFormat="{0}" [valueFormat]="currencyFormat">
		</dxi-total-item>
		<dxi-total-item column="cashdiscount" summaryType="sum" displayFormat="{0}" [valueFormat]="currencyFormat">
		</dxi-total-item>
		<dxi-total-item column="finalAmount" summaryType="sum" displayFormat="{0}" [valueFormat]="currencyFormat">
		</dxi-total-item>
		<dxi-total-item column="costtotal" summaryType="sum" displayFormat="{0}" [valueFormat]="currencyFormat">
		</dxi-total-item>
		<dxi-total-item column="grossprofit" summaryType="sum" displayFormat="{0}" [valueFormat]="currencyFormat">
		</dxi-total-item>
		<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat="{0}" [valueFormat]="currencyFormat">
		</dxi-total-item>
		<dxi-total-item column="accrual_commissionAmount" summaryType="sum" displayFormat="{0}"
			[valueFormat]="currencyFormat">
		</dxi-total-item>
	</dxo-summary>
</dx-data-grid>

<dx-popup width="90%" height="90%" [showTitle]="true" [title]="estimatedRebateHeaderText" [dragEnabled]="true"
	[hideOnOutsideClick]="true" [showCloseButton]="true" [(visible)]="isShowEstRebPopup"
	[wrapperAttr]="{ class: 'popupToolbarPadding' }" (onHidden)="doHiddenEstRebatePopop($event)">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-sortable filter=".dx-tab" [data]="estimatedRebateTabData" itemOrientation="horizontal"
			dragDirection="horizontal">
			<dx-tab-panel [dataSource]="estimatedRebateTabData" [height]="'inherit'" itemTitleTemplate="tabTitle"
				itemTemplate="tabContent" [deferRendering]="true" [showNavButtons]="false" [repaintChangesOnly]="true"
				[animationEnabled]="true" [swipeEnabled]="false" [scrollingEnabled]="true" [showNavButtons]="false">
				<div *dxTemplate="let content of 'tabTitle'">
					<span>{{content.text}}</span>
				</div>
				<div *dxTemplate="let tab of 'tabContent'">
					<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'"
						[bounceEnabled]="false" width="100%" height="500">
						<app-rebates-tierbase-calculation-view *ngIf="isviewRebateCalView"
							[isCallFromCommissionContract]="true"
							[settlementPeriodStart]="commissionRptDetails.settlementPeriodStartDate"
							[settlementPeriodEnd]="commissionRptDetails.settlementPeriodEndDate">
						</app-rebates-tierbase-calculation-view>
					</dx-scroll-view>
				</div>
			</dx-tab-panel>
		</dx-sortable>
	</div>
</dx-popup>