import { Injectable } from '@angular/core';
import { IMG_ADD_FILE_SVG, IMG_ICON_COLUMN_CHOOSER, IMG_ICON_REFRESH, IMG_ICON_XLSXFILEPOPUPIX } from '@app/constants-enums/constants';
import { YearToDateCustomData, YearToYearCustomData, PeriodToDateCustomData } from '@app/models/report.model';

@Injectable({
	providedIn: 'root'
})
export class ReportsServiceService {
	private reportGuid: string;
	private isEditMode: boolean;
	private storedFilters: any;
	constructor() {

	}

	setIsEditMode(val: any) {
		this.isEditMode = val;
	}
	getIsEditMode() {
		return this.isEditMode;
	}

	public setSReportGuid(value: any) {
		this.reportGuid = value;
	}

	public getReportGuid() {
		return this.reportGuid || undefined;
	}

	public setStoredFilters(val: any) {
		this.storedFilters = val;
	}

	public getStoredFilters() {
		return this.storedFilters || undefined;
	}

	public getColumnYearToDate(fiscalYearStart) {
		const displayedColumnsYearToDate = [];
		displayedColumnsYearToDate.push('customername');
		for (let i = fiscalYearStart; i <= 12; i++) {
			switch (i) {
				case 1:
					displayedColumnsYearToDate.push('Jan');
					break;
				case 2:
					displayedColumnsYearToDate.push('Feb');
					break;
				case 3:
					displayedColumnsYearToDate.push('Mar');
					break;
				case 4:
					displayedColumnsYearToDate.push('Apr');
					break;
				case 5:
					displayedColumnsYearToDate.push('May');
					break;
				case 6:
					displayedColumnsYearToDate.push('Jun');
					break;
				case 7:
					displayedColumnsYearToDate.push('Jul');
					break;
				case 8:
					displayedColumnsYearToDate.push('Aug');
					break;
				case 9:
					displayedColumnsYearToDate.push('Sep');
					break;
				case 10:
					displayedColumnsYearToDate.push('Oct');
					break;
				case 11:
					displayedColumnsYearToDate.push('Nov');
					break;
				case 12:
					displayedColumnsYearToDate.push('Dec');
					break;
			}
		}

		for (let i = 1; i <= fiscalYearStart - 1; i++) {
			switch (i) {
				case 1:
					displayedColumnsYearToDate.push('Jan');
					break;
				case 2:
					displayedColumnsYearToDate.push('Feb');
					break;
				case 3:
					displayedColumnsYearToDate.push('Mar');
					break;
				case 4:
					displayedColumnsYearToDate.push('Apr');
					break;
				case 5:
					displayedColumnsYearToDate.push('May');
					break;
				case 6:
					displayedColumnsYearToDate.push('Jun');
					break;
				case 7:
					displayedColumnsYearToDate.push('Jul');
					break;
				case 8:
					displayedColumnsYearToDate.push('Aug');
					break;
				case 9:
					displayedColumnsYearToDate.push('Sep');
					break;
				case 10:
					displayedColumnsYearToDate.push('Oct');
					break;
				case 11:
					displayedColumnsYearToDate.push('Nov');
					break;
				case 12:
					displayedColumnsYearToDate.push('Dec');
					break;
			}
		}
		displayedColumnsYearToDate.push('total');
		return displayedColumnsYearToDate;
	}

	public getResponseYearToDate(response: any) {
		const columnObject = {
			customername: '',
			Jan: 0,
			Feb: 0,
			Mar: 0,
			Apr: 0,
			May: 0,
			Jun: 0,
			Jul: 0,
			Aug: 0,
			Sep: 0,
			Oct: 0,
			Nov: 0,
			Dec: 0,
			FinalTotal: 0
		};
		const finalArray: YearToDateCustomData[] = [];
		if (response && response.length > 0) {
			response.forEach(element => {
				const yearToDateCustomData = new YearToDateCustomData();
				yearToDateCustomData.customername = {};
				yearToDateCustomData.customername.code = element.code;
				yearToDateCustomData.customername.name = element.name;

				let rowtotal = 0;
				element.data.forEach(monthElement => {
					switch (monthElement.month) {
						case '01':
							yearToDateCustomData.Jan.month = monthElement.month;
							yearToDateCustomData.Jan.year = monthElement.year;
							yearToDateCustomData.Jan.total = monthElement.total;
							columnObject.Jan += parseFloat(monthElement.total);
							break;
						case '02':
							yearToDateCustomData.Feb.month = monthElement.month;
							yearToDateCustomData.Feb.year = monthElement.year;
							yearToDateCustomData.Feb.total = monthElement.total;
							columnObject.Feb += parseFloat(monthElement.total);
							break;
						case '03':
							yearToDateCustomData.Mar.month = monthElement.month;
							yearToDateCustomData.Mar.year = monthElement.year;
							yearToDateCustomData.Mar.total = monthElement.total;
							columnObject.Mar += parseFloat(monthElement.total);
							break;
						case '04':
							yearToDateCustomData.Apr.month = monthElement.month;
							yearToDateCustomData.Apr.year = monthElement.year;
							yearToDateCustomData.Apr.total = monthElement.total;
							columnObject.Apr += parseFloat(monthElement.total);
							break;
						case '05':
							yearToDateCustomData.May.month = monthElement.month;
							yearToDateCustomData.May.year = monthElement.year;
							yearToDateCustomData.May.total = monthElement.total;
							columnObject.May += parseFloat(monthElement.total);
							break;
						case '06':
							yearToDateCustomData.Jun.month = monthElement.month;
							yearToDateCustomData.Jun.year = monthElement.year;
							yearToDateCustomData.Jun.total = monthElement.total;
							columnObject.Jun += parseFloat(monthElement.total);
							break;
						case '07':
							yearToDateCustomData.Jul.month = monthElement.month;
							yearToDateCustomData.Jul.year = monthElement.year;
							yearToDateCustomData.Jul.total = monthElement.total;
							columnObject.Jul += parseFloat(monthElement.total);
							break;
						case '08':
							yearToDateCustomData.Aug.month = monthElement.month;
							yearToDateCustomData.Aug.year = monthElement.year;
							yearToDateCustomData.Aug.total = monthElement.total;
							columnObject.Aug += parseFloat(monthElement.total);
							break;
						case '09':
							yearToDateCustomData.Sep.month = monthElement.month;
							yearToDateCustomData.Sep.year = monthElement.year;
							yearToDateCustomData.Sep.total = monthElement.total;
							columnObject.Sep += parseFloat(monthElement.total);
							break;
						case '10':
							yearToDateCustomData.Oct.month = monthElement.month;
							yearToDateCustomData.Oct.year = monthElement.year;
							yearToDateCustomData.Oct.total = monthElement.total;
							columnObject.Oct = parseFloat(monthElement.total);
							break;
						case '11':
							yearToDateCustomData.Nov.month = monthElement.month;
							yearToDateCustomData.Nov.year = monthElement.year;
							yearToDateCustomData.Nov.total = monthElement.total;
							columnObject.Nov = parseFloat(monthElement.total);
							break;
						case '12':
							yearToDateCustomData.Dec.month = monthElement.month;
							yearToDateCustomData.Dec.year = monthElement.year;
							yearToDateCustomData.Dec.total = monthElement.total;
							columnObject.Dec = parseFloat(monthElement.total);
							break;
					}
					rowtotal += parseFloat(monthElement.total);
					columnObject.FinalTotal += parseFloat(monthElement.total);
				});
				yearToDateCustomData.total = rowtotal;
				finalArray.push(yearToDateCustomData);
			});
		}
		return {
			finalArray: finalArray,
			columnObject: columnObject
		}
	}

	public getColumnYearToYear(fiscalYearStart) {
		const displayedColumnsGroup = [];
		const displayedColumnsYearToYear = [];
		displayedColumnsGroup.push('customerdetails-group');
		displayedColumnsYearToYear.push('customername');
		for (let i = fiscalYearStart; i <= 12; i++) {
			switch (i) {
				case 1:
					displayedColumnsGroup.push('Jan-group');
					displayedColumnsYearToYear.push('Jan1');
					displayedColumnsYearToYear.push('Jan2');
					break;
				case 2:
					displayedColumnsGroup.push('Feb-group');
					displayedColumnsYearToYear.push('Feb1');
					displayedColumnsYearToYear.push('Feb2');
					break;
				case 3:
					displayedColumnsGroup.push('Mar-group');
					displayedColumnsYearToYear.push('Mar1');
					displayedColumnsYearToYear.push('Mar2');
					break;
				case 4:
					displayedColumnsGroup.push('Apr-group');
					displayedColumnsYearToYear.push('Apr1');
					displayedColumnsYearToYear.push('Apr2');
					break;
				case 5:
					displayedColumnsGroup.push('May-group');
					displayedColumnsYearToYear.push('May1');
					displayedColumnsYearToYear.push('May2');
					break;
				case 6:
					displayedColumnsGroup.push('Jun-group');
					displayedColumnsYearToYear.push('Jun1');
					displayedColumnsYearToYear.push('Jun2');
					break;
				case 7:
					displayedColumnsGroup.push('Jul-group');
					displayedColumnsYearToYear.push('Jul1');
					displayedColumnsYearToYear.push('Jul2');
					break;
				case 8:
					displayedColumnsGroup.push('Aug-group');
					displayedColumnsYearToYear.push('Aug1');
					displayedColumnsYearToYear.push('Aug2');
					break;
				case 9:
					displayedColumnsGroup.push('Sep-group');
					displayedColumnsYearToYear.push('Sep1');
					displayedColumnsYearToYear.push('Sep2');
					break;
				case 10:
					displayedColumnsGroup.push('Oct-group');
					displayedColumnsYearToYear.push('Oct1');
					displayedColumnsYearToYear.push('Oct2');
					break;
				case 11:
					displayedColumnsGroup.push('Nov-group');
					displayedColumnsYearToYear.push('Nov1');
					displayedColumnsYearToYear.push('Nov2');
					break;
				case 12:
					displayedColumnsGroup.push('Dec-group');
					displayedColumnsYearToYear.push('Dec1');
					displayedColumnsYearToYear.push('Dec2');
					break;
			}
		}

		for (let i = 1; i <= fiscalYearStart - 1; i++) {
			switch (i) {
				case 1:
					displayedColumnsGroup.push('Jan-group');
					displayedColumnsYearToYear.push('Jan1');
					displayedColumnsYearToYear.push('Jan2');
					break;
				case 2:
					displayedColumnsGroup.push('Feb-group');
					displayedColumnsYearToYear.push('Feb1');
					displayedColumnsYearToYear.push('Feb2');
					break;
				case 3:
					displayedColumnsGroup.push('Mar-group');
					displayedColumnsYearToYear.push('Mar1');
					displayedColumnsYearToYear.push('Mar2');
					break;
				case 4:
					displayedColumnsGroup.push('Apr-group');
					displayedColumnsYearToYear.push('Apr1');
					displayedColumnsYearToYear.push('Apr2');
					break;
				case 5:
					displayedColumnsGroup.push('May-group');
					displayedColumnsYearToYear.push('May1');
					displayedColumnsYearToYear.push('May2');
					break;
				case 6:
					displayedColumnsGroup.push('Jun-group');
					displayedColumnsYearToYear.push('Jun1');
					displayedColumnsYearToYear.push('Jun2');
					break;
				case 7:
					displayedColumnsGroup.push('Jul-group');
					displayedColumnsYearToYear.push('Jul1');
					displayedColumnsYearToYear.push('Jul2');
					break;
				case 8:
					displayedColumnsGroup.push('Aug-group');
					displayedColumnsYearToYear.push('Aug1');
					displayedColumnsYearToYear.push('Aug2');
					break;
				case 9:
					displayedColumnsGroup.push('Sep-group');
					displayedColumnsYearToYear.push('Sep1');
					displayedColumnsYearToYear.push('Sep2');
					break;
				case 10:
					displayedColumnsGroup.push('Oct-group');
					displayedColumnsYearToYear.push('Oct1');
					displayedColumnsYearToYear.push('Oct2');
					break;
				case 11:
					displayedColumnsGroup.push('Nov-group');
					displayedColumnsYearToYear.push('Nov1');
					displayedColumnsYearToYear.push('Nov2');
					break;
				case 12:
					displayedColumnsGroup.push('Dec-group');
					displayedColumnsYearToYear.push('Dec1');
					displayedColumnsYearToYear.push('Dec2');
					break;
			}
		}
		displayedColumnsGroup.push('total-group');
		displayedColumnsYearToYear.push('total-year1');
		displayedColumnsYearToYear.push('total-year2');
		// displayedColumnsYearToYear.push('total');

		return {
			columnsGroup: displayedColumnsGroup,
			columnsYearToYear: displayedColumnsYearToYear
		};
	}

	public getResponseYearToYear(response: any) {
		const yearToYearList: YearToYearCustomData[] = [];
		const columnObject = {
			customername: '',
			Jan1: 0,
			Jan2: 0,
			Feb1: 0,
			Feb2: 0,
			Mar1: 0,
			Mar2: 0,
			Apr1: 0,
			Apr2: 0,
			May1: 0,
			May2: 0,
			Jun1: 0,
			Jun2: 0,
			Jul1: 0,
			Jul2: 0,
			Aug1: 0,
			Aug2: 0,
			Sep1: 0,
			Sep2: 0,
			Oct1: 0,
			Oct2: 0,
			Nov1: 0,
			Nov2: 0,
			Dec1: 0,
			Dec2: 0,
			Total1: 0,
			Total2: 0,
			FinalTotal1: 0,
			FinalTotal2: 0,
			// FinalTotal: 0
		};
		response.forEach(element => {
			const yearToYearCustomData = new YearToYearCustomData();
			yearToYearCustomData.customername = {};
			yearToYearCustomData.customername.code = element.code;
			yearToYearCustomData.customername.name = element.name;
			let rowtotal1 = 0;
			let rowtotal2 = 0;
			let rowtotal = 0;
			element.year_over_year.forEach(monthElement => {
				switch (monthElement.month) {
					case '01':
						yearToYearCustomData.Jan1.month = monthElement.month;
						yearToYearCustomData.Jan1.year = monthElement.year1;
						yearToYearCustomData.Jan1.total = monthElement.total1;
						yearToYearCustomData.Jan2.month = monthElement.month;
						yearToYearCustomData.Jan2.year = monthElement.year2;
						yearToYearCustomData.Jan2.total = monthElement.total2;
						columnObject.Jan1 += parseFloat(monthElement.total1);
						columnObject.Jan2 += parseFloat(monthElement.total2);
						break;
					case '02':
						yearToYearCustomData.Feb1.month = monthElement.month;
						yearToYearCustomData.Feb1.year = monthElement.year1;
						yearToYearCustomData.Feb1.total = monthElement.total1;
						yearToYearCustomData.Feb2.month = monthElement.month;
						yearToYearCustomData.Feb2.year = monthElement.year2;
						yearToYearCustomData.Feb2.total = monthElement.total2;
						columnObject.Feb1 += parseFloat(monthElement.total1);
						columnObject.Feb2 += parseFloat(monthElement.total2);
						break;
					case '03':
						yearToYearCustomData.Mar1.month = monthElement.month;
						yearToYearCustomData.Mar1.year = monthElement.year1;
						yearToYearCustomData.Mar1.total = monthElement.total1;
						yearToYearCustomData.Mar2.month = monthElement.month;
						yearToYearCustomData.Mar2.year = monthElement.year2;
						yearToYearCustomData.Mar2.total = monthElement.total2;
						columnObject.Mar1 += parseFloat(monthElement.total1);
						columnObject.Mar2 += parseFloat(monthElement.total2);
						break;
					case '04':
						yearToYearCustomData.Apr1.month = monthElement.month;
						yearToYearCustomData.Apr1.year = monthElement.year1;
						yearToYearCustomData.Apr1.total = monthElement.total1;
						yearToYearCustomData.Apr2.month = monthElement.month;
						yearToYearCustomData.Apr2.year = monthElement.year2;
						yearToYearCustomData.Apr2.total = monthElement.total2;
						columnObject.Apr1 += parseFloat(monthElement.total1);
						columnObject.Apr2 += parseFloat(monthElement.total2);
						break;
					case '05':
						yearToYearCustomData.May1.month = monthElement.month;
						yearToYearCustomData.May1.year = monthElement.year1;
						yearToYearCustomData.May1.total = monthElement.total1;
						yearToYearCustomData.May2.month = monthElement.month;
						yearToYearCustomData.May2.year = monthElement.year2;
						yearToYearCustomData.May2.total = monthElement.total2;
						columnObject.May1 += parseFloat(monthElement.total1);
						columnObject.May2 += parseFloat(monthElement.total2);
						break;
					case '06':
						yearToYearCustomData.Jun1.month = monthElement.month;
						yearToYearCustomData.Jun1.year = monthElement.year1;
						yearToYearCustomData.Jun1.total = monthElement.total1;
						yearToYearCustomData.Jun2.month = monthElement.month;
						yearToYearCustomData.Jun2.year = monthElement.year2;
						yearToYearCustomData.Jun2.total = monthElement.total2;
						columnObject.Jun1 += parseFloat(monthElement.total1);
						columnObject.Jun2 += parseFloat(monthElement.total2);
						break;
					case '07':
						yearToYearCustomData.Jul1.month = monthElement.month;
						yearToYearCustomData.Jul1.year = monthElement.year1;
						yearToYearCustomData.Jul1.total = monthElement.total1;
						yearToYearCustomData.Jul2.month = monthElement.month;
						yearToYearCustomData.Jul2.year = monthElement.year2;
						yearToYearCustomData.Jul2.total = monthElement.total2;
						columnObject.Jul1 += parseFloat(monthElement.total1);
						columnObject.Jul2 += parseFloat(monthElement.total2);
						break;
					case '08':
						yearToYearCustomData.Aug1.month = monthElement.month;
						yearToYearCustomData.Aug1.year = monthElement.year1;
						yearToYearCustomData.Aug1.total = monthElement.total1;
						yearToYearCustomData.Aug2.month = monthElement.month;
						yearToYearCustomData.Aug2.year = monthElement.year2;
						yearToYearCustomData.Aug2.total = monthElement.total2;
						columnObject.Aug1 += parseFloat(monthElement.total1);
						columnObject.Aug2 += parseFloat(monthElement.total2);
						break;
					case '09':
						yearToYearCustomData.Sep1.month = monthElement.month;
						yearToYearCustomData.Sep1.year = monthElement.year1;
						yearToYearCustomData.Sep1.total = monthElement.total1;
						yearToYearCustomData.Sep2.month = monthElement.month;
						yearToYearCustomData.Sep2.year = monthElement.year2;
						yearToYearCustomData.Sep2.total = monthElement.total2;
						columnObject.Sep1 += parseFloat(monthElement.total1);
						columnObject.Sep2 += parseFloat(monthElement.total2);
						break;
					case '10':
						yearToYearCustomData.Oct1.month = monthElement.month;
						yearToYearCustomData.Oct1.year = monthElement.year1;
						yearToYearCustomData.Oct1.total = monthElement.total1;
						yearToYearCustomData.Oct2.month = monthElement.month;
						yearToYearCustomData.Oct2.year = monthElement.year2;
						yearToYearCustomData.Oct2.total = monthElement.total2;
						columnObject.Oct1 += parseFloat(monthElement.total1);
						columnObject.Oct2 += parseFloat(monthElement.total2);
						break;
					case '11':
						yearToYearCustomData.Nov1.month = monthElement.month;
						yearToYearCustomData.Nov1.year = monthElement.year1;
						yearToYearCustomData.Nov1.total = monthElement.total1;
						yearToYearCustomData.Nov2.month = monthElement.month;
						yearToYearCustomData.Nov2.year = monthElement.year2;
						yearToYearCustomData.Nov2.total = monthElement.total2;
						columnObject.Nov1 += parseFloat(monthElement.total1);
						columnObject.Nov2 += parseFloat(monthElement.total2);
						break;
					case '12':
						yearToYearCustomData.Dec1.month = monthElement.month;
						yearToYearCustomData.Dec1.year = monthElement.year1;
						yearToYearCustomData.Dec1.total = monthElement.total1;
						yearToYearCustomData.Dec2.month = monthElement.month;
						yearToYearCustomData.Dec2.year = monthElement.year2;
						yearToYearCustomData.Dec2.total = monthElement.total2;
						columnObject.Dec1 += parseFloat(monthElement.total1);
						columnObject.Dec2 += parseFloat(monthElement.total2);
						break;
				}
				rowtotal += (parseFloat(monthElement.total1) + parseFloat(monthElement.total2));
				rowtotal1 += parseFloat(monthElement.total1);
				rowtotal2 += parseFloat(monthElement.total2);
				columnObject.FinalTotal1 += parseFloat(monthElement.total1);
				columnObject.FinalTotal2 += parseFloat(monthElement.total2);
				// columnObject.FinalTotal += (parseFloat(monthElement.total1) + parseFloat(monthElement.total2));
			});
			yearToYearCustomData.total1 = rowtotal1;
			yearToYearCustomData.total2 = rowtotal2;
			// yearToYearCustomData.total = rowtotal;
			yearToYearList.push(yearToYearCustomData);
		});
		return {
			yearToYearList: yearToYearList,
			columnObject: columnObject
		};
	}

	public getResponsePeriodToDate(response: any) {
		const columnObject = {
			customername: '',
			Year1: 0,
			Year2: 0,
			TotalChange: 0,
			TotalChangePercentage: 0
		};
		const finalArray: PeriodToDateCustomData[] = [];
		if (response && response.length > 0) {
			response.forEach(element => {
				const periodToDateCustomData = new PeriodToDateCustomData();
				periodToDateCustomData.customername = {};
				periodToDateCustomData.customername.code = element.code;
				periodToDateCustomData.customername.name = element.name;

				element.period_to_date.forEach(monthElement => {
					let totalChangePercentage = 0;
					const total1 = parseFloat(monthElement.total1);
					const total2 = parseFloat(monthElement.total2);
					const totalChange = total2 - total1;
					columnObject.TotalChange += totalChange || 0;
					if (monthElement.total1 !== 0 || monthElement.total2 !== 0) {
						if (total1 >= totalChange) {
							totalChangePercentage = (totalChange / total1) * 100;
						} else {
							totalChangePercentage = (totalChange / total2) * 100;
						}
					}
					switch (monthElement.month) {
						case '01':
							periodToDateCustomData.Year1.month = monthElement.month;
							periodToDateCustomData.Year1.year = monthElement.year1;
							periodToDateCustomData.Year1.total = monthElement.total1;
							periodToDateCustomData.Year2.month = monthElement.month;
							periodToDateCustomData.Year2.year = monthElement.year2;
							periodToDateCustomData.Year2.total = monthElement.total2;
							periodToDateCustomData.totalChange = totalChange;
							periodToDateCustomData.totalChangePercentage = totalChangePercentage;
							columnObject.Year1 += total1;
							columnObject.Year2 += total2;
							break;
						case '02':
							periodToDateCustomData.Year1.month = monthElement.month;
							periodToDateCustomData.Year1.year = monthElement.year1;
							periodToDateCustomData.Year1.total = monthElement.total1;
							periodToDateCustomData.Year2.month = monthElement.month;
							periodToDateCustomData.Year2.year = monthElement.year2;
							periodToDateCustomData.Year2.total = monthElement.total2;
							periodToDateCustomData.totalChange = totalChange;
							periodToDateCustomData.totalChangePercentage = totalChangePercentage;
							columnObject.Year1 += total1;
							columnObject.Year2 += total2;
							break;
						case '03':
							periodToDateCustomData.Year1.month = monthElement.month;
							periodToDateCustomData.Year1.year = monthElement.year1;
							periodToDateCustomData.Year1.total = monthElement.total1;
							periodToDateCustomData.Year2.month = monthElement.month;
							periodToDateCustomData.Year2.year = monthElement.year2;
							periodToDateCustomData.Year2.total = monthElement.total2;
							periodToDateCustomData.totalChange = totalChange;
							periodToDateCustomData.totalChangePercentage = totalChangePercentage;
							columnObject.Year1 += total1;
							columnObject.Year2 += total2;
							break;
						case '04':
							periodToDateCustomData.Year1.month = monthElement.month;
							periodToDateCustomData.Year1.year = monthElement.year1;
							periodToDateCustomData.Year1.total = monthElement.total1;
							periodToDateCustomData.Year2.month = monthElement.month;
							periodToDateCustomData.Year2.year = monthElement.year2;
							periodToDateCustomData.Year2.total = monthElement.total2;
							periodToDateCustomData.totalChange = totalChange;
							periodToDateCustomData.totalChangePercentage = totalChangePercentage;
							columnObject.Year1 += total1;
							columnObject.Year2 += total2;
							break;
						case '05':
							periodToDateCustomData.Year1.month = monthElement.month;
							periodToDateCustomData.Year1.year = monthElement.year1;
							periodToDateCustomData.Year1.total = monthElement.total1;
							periodToDateCustomData.Year2.month = monthElement.month;
							periodToDateCustomData.Year2.year = monthElement.year2;
							periodToDateCustomData.Year2.total = monthElement.total2;
							periodToDateCustomData.totalChange = totalChange;
							periodToDateCustomData.totalChangePercentage = totalChangePercentage;
							columnObject.Year1 += total1;
							columnObject.Year2 += total2;
							columnObject.TotalChangePercentage += totalChangePercentage;
							break;
						case '06':
							periodToDateCustomData.Year1.month = monthElement.month;
							periodToDateCustomData.Year1.year = monthElement.year1;
							periodToDateCustomData.Year1.total = monthElement.total1;
							periodToDateCustomData.Year2.month = monthElement.month;
							periodToDateCustomData.Year2.year = monthElement.year2;
							periodToDateCustomData.Year2.total = monthElement.total2;
							periodToDateCustomData.totalChange = totalChange;
							periodToDateCustomData.totalChangePercentage = totalChangePercentage;
							columnObject.Year1 += total1;
							columnObject.Year2 += total2;
							break;
						case '07':
							periodToDateCustomData.Year1.month = monthElement.month;
							periodToDateCustomData.Year1.year = monthElement.year1;
							periodToDateCustomData.Year1.total = monthElement.total1;
							periodToDateCustomData.Year2.month = monthElement.month;
							periodToDateCustomData.Year2.year = monthElement.year2;
							periodToDateCustomData.Year2.total = monthElement.total2;
							periodToDateCustomData.totalChange = totalChange;
							periodToDateCustomData.totalChangePercentage = totalChangePercentage;
							columnObject.Year1 += total1;
							columnObject.Year2 += total2;
							break;
						case '08':
							periodToDateCustomData.Year1.month = monthElement.month;
							periodToDateCustomData.Year1.year = monthElement.year1;
							periodToDateCustomData.Year1.total = monthElement.total1;
							periodToDateCustomData.Year2.month = monthElement.month;
							periodToDateCustomData.Year2.year = monthElement.year2;
							periodToDateCustomData.Year2.total = monthElement.total2;
							periodToDateCustomData.totalChange = totalChange;
							periodToDateCustomData.totalChangePercentage = totalChangePercentage;
							columnObject.Year1 += total1;
							columnObject.Year2 += total2;
							break;
						case '09':
							periodToDateCustomData.Year1.month = monthElement.month;
							periodToDateCustomData.Year1.year = monthElement.year1;
							periodToDateCustomData.Year1.total = monthElement.total1;
							periodToDateCustomData.Year2.month = monthElement.month;
							periodToDateCustomData.Year2.year = monthElement.year2;
							periodToDateCustomData.Year2.total = monthElement.total2;
							periodToDateCustomData.totalChange = totalChange;
							periodToDateCustomData.totalChangePercentage = totalChangePercentage;
							columnObject.Year1 += total1;
							columnObject.Year2 += total2;
							break;
						case '10':
							periodToDateCustomData.Year1.month = monthElement.month;
							periodToDateCustomData.Year1.year = monthElement.year1;
							periodToDateCustomData.Year1.total = monthElement.total1;
							periodToDateCustomData.Year2.month = monthElement.month;
							periodToDateCustomData.Year2.year = monthElement.year2;
							periodToDateCustomData.Year2.total = monthElement.total2;
							periodToDateCustomData.totalChange = totalChange;
							periodToDateCustomData.totalChangePercentage = totalChangePercentage;
							columnObject.Year1 += total1;
							columnObject.Year2 += total2;
							break;
						case '11':
							periodToDateCustomData.Year1.month = monthElement.month;
							periodToDateCustomData.Year1.year = monthElement.year1;
							periodToDateCustomData.Year1.total = monthElement.total1;
							periodToDateCustomData.Year2.month = monthElement.month;
							periodToDateCustomData.Year2.year = monthElement.year2;
							periodToDateCustomData.Year2.total = monthElement.total2;
							periodToDateCustomData.totalChange = totalChange;
							periodToDateCustomData.totalChangePercentage = totalChangePercentage;
							columnObject.Year1 += total1;
							columnObject.Year2 += total2;
							break;
						case '12':
							periodToDateCustomData.Year1.month = monthElement.month;
							periodToDateCustomData.Year1.year = monthElement.year1;
							periodToDateCustomData.Year1.total = monthElement.total1;
							periodToDateCustomData.Year2.month = monthElement.month;
							periodToDateCustomData.Year2.year = monthElement.year2;
							periodToDateCustomData.Year2.total = monthElement.total2;
							periodToDateCustomData.totalChange = totalChange;
							periodToDateCustomData.totalChangePercentage = totalChangePercentage;
							columnObject.Year1 += total1;
							columnObject.Year2 += total2;
							break;
					}
				});
				finalArray.push(periodToDateCustomData);
			});
			columnObject.TotalChangePercentage = 0;
			if (columnObject.Year1 >= columnObject.TotalChange) {
				columnObject.TotalChangePercentage = (columnObject.TotalChange / columnObject.Year1) * 100;
			} else {
				columnObject.TotalChangePercentage = (columnObject.TotalChange / columnObject.Year2) * 100;
			}
		}
		return {
			periodToDate: finalArray,
			columnObject: columnObject
		};
	}

	public getListOfActionsForDatagrid() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
		];
	}


	get getListOfIncentivesTypes() {
		return [
			{ value: 'commission', text: 'Commission' },
			{ value: 'rebate', text: 'Rebate' },
			{ value: 'royalty', text: 'Royalty' },
			{ value: 'billbacks_chargebacks', text: 'Billbacks & Chargebacks' }
		];
	}

	get getListOfBillbacksChargebacksTypes() {
		return [
			{ text: "Billback", value: "billback" },
			{ text: "Chargeback", value: "chargeback" },
			{ text: "End-User Chargeback", value: "enduserschargeback" },
			{ text: "Distribution Chargeback", value: "distributionchargeback" },
			{ text: "Min-Max Chargeback", value: "minmaxenduserschargeback" },
			{ text: "Min-Max Chargeback Ignored Items", value: "minmaxenduserschargeback_ignored_items" }
		];
	}
	get getListOfCharegbackBasis() {
		return [
			{ text: 'End-Users', value: 'endusers' },
			{ text: 'Customers', value: 'customers' },
		];
	}

	get getListOfClaimedStatuses() {
		const listOfStatuses = [
			{
				value: 'draft',
				text: 'Draft',
				classname: 'defaultClr'
			},
			{
				value: 'pending_approval',
				text: 'Pending Approval',
				classname: 'orange'
			},
			{
				value: 'approved',
				text: 'Approved',
				classname: 'green'
			}
		];
		return listOfStatuses;
	}
	get getListOfActionsForIncentiveReportLising() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	get getListOfReportTypesForIncentiveReports() {
		return [
			{
				value: 'detailed_view',
				text: 'Detailed View',
				visible: true,
				disabled: false,
			},
			{
				value: 'summary_view',
				text: 'Summary View',
				visible: true,
				disabled: false,
			}
		];
	}

	get getListOfReportViewsForIncentiveReports() {
		return [
			{
				value: 'datagrid_view',
				text: 'Data Grid',
				visible: true,
				disabled: false,
			},
			{
				value: 'pivotgrid_view',
				text: 'Pivot Grid',
				visible: true,
				disabled: false,
			}
		];
	}

	get getListOfChargebackBasisForIncentiveReports() {
		return [
			{
				value: 'endusers',
				text: 'End-Users',
				visible: true,
				disabled: false,
			},
			{
				value: 'customers',
				text: 'Customers',
				visible: true,
				disabled: false,
			},
		];
	}
}
