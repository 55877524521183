<div class="borderBox padT20 padB20">
	<div class="row">
		<div class="cols8">
			<h4 class="textandlableix line-height-normal mrgB10"><span class="textlabelix">Royalty Report:</span>
				{{royaltiesRptDetails.contractName}}</h4>
			<p class="contTx mrgB0"><span class="lbl">Vendor Name:</span><span class="black">
					{{royaltiesRptDetails.selectVendor}}</span></p>
			<p class="contTx mrgB0"><span class="lbl">Settlement Period:</span><span class="black">
					{{royaltiesRptDetails.settlementPeriodStartDate | date: 'yyyy-MM-dd'}} to
					{{royaltiesRptDetails.settlementPeriodEndDate | date: 'yyyy-MM-dd'}}</span></p>
		</div>
		<div class="cols4 d-flex">
			<div class="flex-grow-1">
				<p class="contTx mrgB10 d-block txtRight mrgR0">
					<span class="lbl">Status</span><span class="inStatusLabel mrgL10"
						[ngClass]="royaltiesSatusUIClass">{{royaltiesRptDetails.apInvoiceCreatedInERP}}</span>
				</p>
				<p class="contTx mrgB0 d-block txtRight mrgR0">
					<span class="lbl">Generated:</span><span class="black"> {{royaltiesRptDetails.created_date | date:
						'yyyy-MM-dd'}}</span>
				</p>
			</div>
			<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false" [showArrowIcon]="false"
				text="Action" class="mrgL30 secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx btnHeightBig"
				[items]="listOfActions" [wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name"
				keyExpr="btn_code" icon="spindown" (onButtonClick)="doActionsBtnClick($event)"
				(onItemClick)="doActionsBtnItemClick($event)" [dropDownOptions]="{ width: 270 }">
			</dx-drop-down-button>
		</div>
	</div>
	<hr class="hrborderix mrgT10 mrgB15" />
	<div class="d-flex d-flex-center">
		<div class="flex-grow-1">
			<div class="padR30 alignLeft" *ngFor="let item of royaltiesRptTabs; let  i = index">
				<span class="labelTextIx d-block line-height-normal">{{item.title}}</span>
				<span class="textlabelix">
					<dx-load-indicator height="25" width="25" class="button-indicator"
						[elementAttr]="{ class: 'button-loader-indicator-blue' }"
						[visible]="isShowLoaderIndicator"></dx-load-indicator>
					<span class="black semiBold" *ngIf="!isShowLoaderIndicator">{{item.value|| 0
						|customCurrencyPipe:customCurrencyOption}}</span>
				</span>
			</div>
		</div>
		<div class="greenboxix">
			<div class="greenboxinnerix padR30">
				<span class="labelTextIx d-block line-height-normal">Total Royalty Payable</span>
				<span class="textlabelix black semiBold">{{royaltiesRptDetails.totalRoyaltiesPayable || 0 |
					customCurrencyPipe:customCurrencyOption }}</span>
			</div>
			<div class="greenboxinnerix">
				<span class="labelTextIx d-block line-height-normal">Total Royalty Generated</span>
				<span class="textlabelix black semiBold">{{royaltiesRptDetails.totalRoyaltiesGenerated || 0 |
					customCurrencyPipe:customCurrencyOption }}</span>
			</div>
		</div>
	</div>
</div>

<div class="borderBox padB20">
	<dx-tab-panel id="tabPanel" [deferRendering]="false" (onOptionChanged)="doOnTabOptionChanged($event)"
		(onSelectionChanged)="doSelectionChanged($event)" [dataSource]="royaltiesRptTabs">
		<div *dxTemplate="let tabPanelItem of 'item'">
			<div *ngIf="tabPanelItem.key === RoyaltiesSettlementReportSectionEnum.INVOICES" class="pad5">
				<app-rs-invoice-rpt-tab [viewPopUpModelProps]="viewPopUpModelProps"
					[royaltiesRptDetails]="royaltiesRptDetails"></app-rs-invoice-rpt-tab>
			</div>
			<div *ngIf="tabPanelItem.key === RoyaltiesSettlementReportSectionEnum.EXTRA_ROYALTIES_BASED_RULES"
				class="pad5">
				<app-rs-extra-ryl-based-rule-rpt-tab
					[royaltiesRptDetails]="royaltiesRptDetails"></app-rs-extra-ryl-based-rule-rpt-tab>
			</div>
			<div *ngIf="tabPanelItem.key === RoyaltiesSettlementReportSectionEnum.AMORTIZED_PAYMENTS" class="pad5">
				<app-rs-amortized-payments-rpt-tab [viewPopUpModelProps]="viewPopUpModelProps"
					[royaltiesRptDetails]="royaltiesRptDetails"></app-rs-amortized-payments-rpt-tab>
			</div>
			<!-- <div *ngIf="tabPanelItem.key === RoyaltiesSettlementReportSectionEnum.AR_CREDIT_MEMOS" class="pad5">
				<app-rs-ar-credit-memos-rpt-tab [royaltiesRptDetails]="royaltiesRptDetails"
					[viewPopUpModelProps]="viewPopUpModelProps"></app-rs-ar-credit-memos-rpt-tab>
			</div> -->
		</div>
	</dx-tab-panel>

</div>

<app-view-invoice-detail-popup
	*ngIf="viewPopUpModelProps.detailView === 'invoice' || viewPopUpModelProps.detailView === 'modification_invoice'"
	[viewInvoiceDetailProps]="viewPopUpModelProps">
</app-view-invoice-detail-popup>

<app-view-order-changeorder-detail-popup
	*ngIf="viewPopUpModelProps.detailView === 'order'|| viewPopUpModelProps.detailView === 'change_order'"
	[viewOrderChangeOrderDetailProps]="viewPopUpModelProps">
</app-view-order-changeorder-detail-popup>

<app-view-craditmemo-detail-popup *ngIf="viewPopUpModelProps.detailView === 'creditmemo'"
	[viewCreditMemoDetailProps]="viewPopUpModelProps"></app-view-craditmemo-detail-popup>