<dx-data-grid #amortizedPaymentDataGridRef id="gridContainer" [dataSource]="amortizedPaymentsDataSource" keyExpr="guid"
	[wordWrapEnabled]="true" [hoverStateEnabled]="true" [showBorders]="true" [showColumnLines]="true"
	[showRowLines]="false" [allowColumnResizing]="true" [filterSyncEnabled]="true" [filterValue]="filterValue"
	[remoteOperations]="false" [columnAutoWidth]="true" [allowColumnReordering]="true" [width]="'100%'"
	(onCellClick)="doOnAmortizedPaymentCellClick($event)"
	(onToolbarPreparing)="doAmortizedPaymentToolbarPreparing($event)" class="cellColor hoverClr hideFilterPanelInx">
	<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
	<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
	<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
		[showInfo]="true">
	</dxo-pager>
	<dxo-filter-row [visible]="true"></dxo-filter-row>
	<dxo-filter-panel [visible]="true"></dxo-filter-panel>
	<dxo-group-panel [visible]="true"></dxo-group-panel>
	<dxo-header-filter [visible]="false"></dxo-header-filter>
	<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
	<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadAmortizedPaymentState"
		[customSave]="doSaveAmortizedPaymentState" [storageKey]="stateStorageKey">
	</dxo-state-storing>
	<div *dxTemplate="let data of 'templateFilterText'">
		<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
			<span>{{filterText}}</span>
		</div>
	</div>

	<div *dxTemplate="let data of 'templateActionBtns'">
		<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false" [showArrowIcon]="false"
			text="Action" class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
			[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code" icon="spindown"
			(onButtonClick)="doActionsBtnClick($event)" (onItemClick)="doActionsBtnItemClick($event)"
			[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
	</div>
	<dxi-column dataField="settlement_close_date" dataType="date" caption="Payment Start" [width]="'auto'"
		format="yyyy-MM-dd">
	</dxi-column>

	<dxi-column dataField="condition" dataType="string" caption="Payment #" cellTemplate="cellTemplatePayment"
		[width]="'auto'">
		<div *dxTemplate="let element of 'cellTemplatePayment'">
			{{element.data.current_payment}} of {{element.data.number_of_payments}}
		</div>
	</dxi-column>
	<dxi-column dataField="docnum" dataType="string" caption="Doc Num" [width]="'auto'" cellTemplate="cellTemplateDocNo">
		<div *dxTemplate="let element of 'cellTemplateDocNo'">
			<span class="highlight pointerMark">{{element.data.docnum}}</span>
		</div>
	</dxi-column>

	<dxi-column dataField="billing_start_date" dataType="date" caption="Billing Start Date" [width]="'auto'"
		format="yyyy-MM-dd">
	</dxi-column>

	<dxi-column dataField="billing_end_date" dataType="date" caption="Billing End Date" [width]="'auto'"
		format="yyyy-MM-dd">
	</dxi-column>

	<dxi-column dataField="total_commission" dataType="number" caption="Total Commission Amount" [width]="'auto'"
		[format]="currencyFormat">
	</dxi-column>

	<dxi-column dataField="payment_amount" dataType="number" caption="Amortized Amount" [width]="'auto'"
		[format]="currencyFormat">
	</dxi-column>

	<dxo-summary>
		<dxi-total-item column="total_commission" summaryType="sum" displayFormat="{0}" [valueFormat]="currencyFormat">
		</dxi-total-item>
		<dxi-total-item column="payment_amount" summaryType="sum" displayFormat="{0}" [valueFormat]="currencyFormat">
		</dxi-total-item>
	</dxo-summary>
</dx-data-grid>