import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IMG_ICON_COLUMN_CHOOSER, IMG_ICON_REFRESH, MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { ContractTypeValEnum, ErpTypeValEnum, RebateApprovalStatusValEnum, RebateSettlementStatusValEnum } from '@app/constants-enums/enums';
import { CurrencyOptions, PageOptions } from '@app/models/common.model';
import { RebateService } from '@app/services/contract-service/rebate/rebate.service';
import { LoginService } from '@app/services/login.service';
import { environment } from '@environments/environment';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import saveAs from 'file-saver';
declare const ExcelJS: ExcelJS;
import { CellRange, exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridPdf } from 'devextreme/pdf_exporter';
import moment from 'moment';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
import { CustomNumberNoRoundingPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';
import { Observable, Subscription } from 'rxjs';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { ToastrService } from 'ngx-toastr';
import { DxDataGridComponent } from 'devextreme-angular';
import { ViewEndusersInvoiceDetailPopUpModel, ViewInvoiceDetailPopUpModel } from '@app/models/popup.model';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { Guid } from '@app/models/guid';
@Component({
	selector: 'app-rebates-tierbase-settlement-report',
	templateUrl: './rebates-tierbase-settlement-report.component.html',
	styleUrls: ['./rebates-tierbase-settlement-report.component.css']
})
export class RebatesTierbaseSettlementReportComponent implements OnInit, OnDestroy {
	public rebatesRptTabs: any[] = [];
	public rebateSatusUIClass: any;
	public rebateRptDetails: any;
	private guid = new Guid();
	public listOfActions: any[] = [];
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();
	public itemsInvoicesDataSource: any;
	public filterValue: any[];
	public enable_classification_for_rebate: boolean = false; // Use For NETSUTE Erp Manage in system config
	public isQBOnlineErpUser: boolean = false;
	public isQBErpUser: boolean = false;
	public listOfTierBasisHeaderFilter: any[] = [];
	public listOfTierBasis: any[] = [];
	public listOfRebateBasis: any[] = [];
	public listOfRebateBasisHeaderFilter: any[] = [];
	public decimalPointForCurrency: number;
	public globalCurrencySymbol: string;
	public listOfFeaturesCustomFields: any[] = [];
	public listOfDocTypeHeaderFilter: any[] = [];
	public listOfUoms: any[] = [];
	public listOfUomsHeaderFilter: any[] = [];
	public listOfInPricelist: any[] = [];
	private getUomLstSbsn: Subscription;
	private updateUserSettingSbsn: Subscription;
	public isShowEndUserInvoiceDetailPopUp: boolean;
	public viewEndUserInvoiceDetailProps: ViewEndusersInvoiceDetailPopUpModel = new ViewEndusersInvoiceDetailPopUpModel();
	public isShowInvoiceDetailPopUp: boolean;
	public viewInvoiceDetailProps: ViewInvoiceDetailPopUpModel = new ViewInvoiceDetailPopUpModel();
	@ViewChild('itemsInvoicesDataSourceRef') itemsInvoicesDataSourceRef: DxDataGridComponent;
	public listOfApprovalStatuses: any[] = [];
	public listOfSettlementStatuses: any[] = [];
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	public filterText: string = '';
	public mainSectionStorageKey = ServerSections.REBATE_SETTLEMENT_REPORT_SETTING
	public stateStorageKey = 'invoices-datagrid-state';
	public listOfActionsGrid: any[] = [
		// {
		// 	btn_code: 'ADD',
		// 	btn_name: 'Add New',
		// 	btn_image: IMG_ADD_FILE_SVG,
		// 	icon: ' ' + IMG_ADD_FILE_SVG,
		// 	visible: true,
		// 	disabled: false,
		// },
		{
			btn_code: 'REFRESH',
			btn_name: 'Refresh',
			btn_mage: '',
			icon: IMG_ICON_REFRESH,
			visible: true,
			disabled: false,
		},

		{
			btn_code: 'COLUMN_CHOOSER',
			btn_name: 'Column Chooser',
			btn_image: 'column-chooser',
			icon: IMG_ICON_COLUMN_CHOOSER,
			visible: true,
			disabled: false,
		}
	];
	private filterValueChangeSbsn: Observable<any>;
	public pageOptions: PageOptions = new PageOptions();
	public isShowCardcodeColumn: boolean;
	public isShowApprovalStatusColumn: boolean;
	public enable_rebate_settlement_approval_workflow: boolean;
	constructor(
		public _LoginService: LoginService,
		public _RebateService: RebateService,
		private _RestApiService: RestApiService,
		private _AppCommonSrvc: AppCommonSrvc,
		private _LoaderService: LoaderService,
		private _ToastrService: ToastrService,
		private _CustomNumberNoRoundingPipe: CustomNumberNoRoundingPipe,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
	) {
		this.doCustomizeTotalSummary = this.doCustomizeTotalSummary.bind(this);
		this.doCustomizeSubTotalSummary = this.doCustomizeSubTotalSummary.bind(this);
		this.doCustomizeTextTotalSummary = this.doCustomizeTextTotalSummary.bind(this);
		this.getCurrencyDisplayValue = this.getCurrencyDisplayValue.bind(this);
		this.doLoadInvoiceState = this.doLoadInvoiceState.bind(this);
		this.doSaveInvoiceState = this.doSaveInvoiceState.bind(this);
		this.rebateRptDetails = this._RebateService.getInvoiceDetails();
		if (this._LoginService.loginUser.account_detail.app_settings.erp_type) {
			if (this._LoginService.loginUser.account_detail.enable_classification_for_rebate && this.rebateRptDetails.rebateType === 'sales')
				this.enable_classification_for_rebate = this._LoginService.loginUser.account_detail.enable_classification_for_rebate;
			if (this._LoginService.loginUser.account_detail.app_settings.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
				this.isQBOnlineErpUser = true;
			}
			if (this._LoginService.loginUser.account_detail.app_settings.erp_type === ErpTypeValEnum.QUICKBOOKS) {
				this.isQBErpUser = true;
			}
		}
		this.listOfDocTypeHeaderFilter = [{ value: 'invoice', text: 'Invoice' }, { value: 'creditmemo', text: 'Credit Memo' }];
		this.listOfInPricelist = [{ value: true, text: 'Yes' }, { value: false, text: 'No' }];
		this.listOfTierBasis = this._RebateService.getTierBasis();
		this.listOfRebateBasis = this._RebateService.getRebateBasis(this.globalCurrencySymbol);
		this.listOfTierBasisHeaderFilter = this.getHeaderFilter(this.listOfTierBasis, 'code', 'label');
		this.listOfRebateBasisHeaderFilter = this.getHeaderFilter(this.listOfRebateBasis, 'code', 'label');

		this.listOfApprovalStatuses = this._RebateService.getListOfApprovalStatuses;
		this.listOfSettlementStatuses = this._RebateService.getListOfSettlementStatuses;
		this.rebatesRptTabs = [
			{ title: 'Invoice Total', value: this.rebateRptDetails.current_settlement_invoice_total },
			{ title: 'Credit Memo Total', value: this.rebateRptDetails.current_settlement_creditmemo_total },
			{ title: 'Sales Total', value: this.rebateRptDetails.current_settlement_sales_total },
			{ title: 'Rebate Total', value: this.rebateRptDetails.current_settlement_rebate_total },
		]
		this.rebateSatusUIClass = {
			'orangeInvoice': this.rebateRptDetails.creditMemoCreatedInERP === RebateSettlementStatusValEnum.PROCESSING,
			'purpleInvoice': this.rebateRptDetails.creditMemoCreatedInERP === RebateSettlementStatusValEnum.OPEN,
			'redInvoice': this.rebateRptDetails.creditMemoCreatedInERP === RebateSettlementStatusValEnum.ERROR,
			'greenInvoice': this.rebateRptDetails.creditMemoCreatedInERP === RebateSettlementStatusValEnum.SUCCESS
		};
		this.listOfActions = this._RebateService.getListOfActionsForRebatesReport();
	}
	ngOnInit(): void {
		this.fetchListOfUoms();
		this.doCheckUseCustomersFrom();
		this.doCheckEnabledRebateSettlementApprovalWorkflowFeature();
		this.doHideShowDatagridColumns();
		setTimeout(() => {
			this.customCurrencyOption = this._RebateService.getCustomCurrencyOption(this.rebateRptDetails);
			this.globalCurrencySymbol = this._RebateService.globalCurrencySymbol;
		}, 100);
		this.loadItemInvoicesDataSource();
		setTimeout(() => {
			if (this.itemsInvoicesDataSourceRef) {
				this.filterValueChangeSbsn = this.itemsInvoicesDataSourceRef.filterValueChange.asObservable();
				this.filterValueChangeSbsn.subscribe((result) => {
					this.getFilterTextTab();
				});
			}
		});

	}

	ngOnDestroy(): void {
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
	}

	private doCheckEnabledRebateSettlementApprovalWorkflowFeature() {
		let isEnabledRebateSettlementApprovalWorkflow = false;
		try {
			isEnabledRebateSettlementApprovalWorkflow = this._LoginService.loginUser.account_detail.enable_rebate_settlement_approval_workflow;
		} catch (e) {
			isEnabledRebateSettlementApprovalWorkflow = false;
		}
		this.enable_rebate_settlement_approval_workflow = isEnabledRebateSettlementApprovalWorkflow;
	}

	private doHideShowDatagridColumns() {
		this.isShowApprovalStatusColumn = false;
		if (this.enable_rebate_settlement_approval_workflow) {
			this.isShowApprovalStatusColumn = true;
		}
	}

	public doActionsBtnClick(event: any) {
	}

	public doActionsBtnItemClick(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'EXPORT':
					this.onExporting();
					break;
			}
		}
	}

	private loadItemInvoicesDataSource() {
		let queryString = environment.URL_DATAOUT;
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			contractguid: this.rebateRptDetails.guid,
			section: 'items',
			requireTotalCount: true,
			contracttype: ContractTypeValEnum.REBATESCONTRACTS,
		}
		if (this.rebateRptDetails.rebate_for === 'distribution' || this.rebateRptDetails.rebate_for === 'distribution_differential') {
			loadParams.is_dropdown = 'true';
			loadParams.section = 'invoices';
		}
		// if (this.isCallFromRebateContract) {
		// 	loadParams['settlementPeriodStart'] = this.rebateRptDetails.settlementPeriodStart;
		// 	loadParams['settlementPeriodEnd'] = this.rebateRptDetails.settlementPeriodEnd;
		// }
		if (this.rebateRptDetails.rebate_for === 'buyinggroup') {
			const seletedBuyinggroupOrMember = this._RebateService.getBuyingGroupDetails();
			if (seletedBuyinggroupOrMember && seletedBuyinggroupOrMember.cardcode) {
				//this.selectedBuyingGroupMember = seletedBuyinggroupOrMember.name;
				if (seletedBuyinggroupOrMember.type === 'buyinggroup') {
					loadParams.section = 'buyinggroupitems';
				}
				if (seletedBuyinggroupOrMember.type === 'buyinggroupmember') {
					loadParams.section = 'buyinggroupmemberitems';
					this.filterValue = [
						['clientCode', '=', seletedBuyinggroupOrMember.cardcode]
					]
				}
			}
		}
		this.itemsInvoicesDataSource = AspNetData.createStore({
			loadUrl: queryString,
			loadParams: loadParams,
			onLoaded: (result) => {
				this.updateStatesClearBtn();
			}
		});
	}

	onExporting() {
		const context = this;
		const workbook = new ExcelJS.Workbook();
		let companyName = '';
		let companyImageBase64 = '';
		let fileName = '';
		if (this._LoginService.loginUser.account_detail.company_name) {
			companyName = this._LoginService.loginUser.account_detail.company_name;
		}
		if (this._LoginService.loginUser.account_detail.companylogo) {
			companyImageBase64 = this._LoginService.loginUser.account_detail.companylogo_base64;
		}
		if (this.rebateRptDetails?.apInvoiceNumber) {
			fileName = this.rebateRptDetails.apInvoiceNumber + '_';
		}
		if (this.rebateRptDetails?.subusername) {
			fileName = this.rebateRptDetails.subusername + '_';
		}
		fileName = fileName + 'Rebate_Report.xlsx';

		const tabSheet = workbook.addWorksheet('Invoice');
		let tabSheetObj =
		{
			worksheet: tabSheet,
			component: context.itemsInvoicesDataSourceRef.instance,
			keepColumnWidths: true,
			autoFilterEnabled: false,
		}
		tabSheetObj['topLeftCell'] = { row: 12, column: 1 }; // skip first 12 row
		// add company logo
		tabSheet.mergeCells(1, 1, 3, 2);
		if (companyImageBase64) {
			const image = workbook.addImage({
				base64: companyImageBase64,
				//ext: { width: 500, height: 200 },
				extension: 'png',
				editAs: 'oneCell'
			});
			tabSheet.addImage(image, 'A1:B2');
		} else {
			tabSheet.getRow(1).getCell(1).font = { size: 20, bold: true };
			tabSheet.getRow(1).getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
			tabSheet.getRow(1).getCell(1).value = companyName;
		}
		// customize cell as per original value
		tabSheetObj['customizeCell'] = (options) => {
			const { gridCell, excelCell } = options;
			if (gridCell.rowType === "data") {
				if (gridCell.column.dataField === 'document_type') {
					const listOfDocTypeHeaderFilter = this.listOfDocTypeHeaderFilter;
					const indexDT = listOfDocTypeHeaderFilter.findIndex(i => i.value === gridCell.value);
					excelCell.value = '';
					if (indexDT !== -1) {
						excelCell.value = listOfDocTypeHeaderFilter[indexDT].text;
					}
				}
				if (gridCell.column.dataField === 'uomcode' || gridCell.column.dataField === 'contract_selectedUom') {
					const listOfUoms = this.listOfUoms;
					const indexDT = listOfUoms.findIndex(i => i.code === gridCell.value);
					excelCell.value = '';
					if (indexDT !== -1) {
						excelCell.value = listOfUoms[indexDT].name;
					}
				}
				if (gridCell.column.dataField === 'tier_basis') {
					const listOfTierBasis = this.listOfTierBasis;
					const indexDT = listOfTierBasis.findIndex(i => i.code === gridCell.value);
					excelCell.value = '';
					if (indexDT !== -1) {
						excelCell.value = listOfTierBasis[indexDT].label;
					}
				}

				if (gridCell.column.dataField === 'rebate_basis') {
					const listOfRebateBasis = this.listOfRebateBasis;
					const indexDT = listOfRebateBasis.findIndex(i => i.code === gridCell.value);
					excelCell.value = '';
					if (indexDT !== -1) {
						excelCell.value = listOfRebateBasis[indexDT].label;
					}
				}

			}
		}
		//End customize cell as per original value
		exportDataGrid(tabSheetObj)
			.then(() => {
				// Excel Summary View
				const firstRow = tabSheet.getRow(5);
				let txtStatus: string = '';
				let txtApprovalStatus: string = '';
				let approvalStatusColor: string = '';
				let statusColor: string = '';
				if (this.listOfApprovalStatuses && this.listOfApprovalStatuses.length > 0) {
					const statusIndex = this.listOfApprovalStatuses.findIndex(i => i.value === this.rebateRptDetails.approval_status);
					const approvalStatusIndex = this.listOfSettlementStatuses.findIndex(i => i.value === this.rebateRptDetails.creditMemoCreatedInERP);
					if (statusIndex !== -1) {
						txtStatus = this.listOfApprovalStatuses[statusIndex].text;
						statusColor = this.listOfApprovalStatuses[statusIndex].color_code;
					}
					if (approvalStatusIndex !== -1) {
						txtApprovalStatus = this.listOfSettlementStatuses[approvalStatusIndex].text;
						approvalStatusColor = this.listOfSettlementStatuses[approvalStatusIndex].color_code;
					}
				}
				firstRow.height = 20;
				tabSheet.mergeCells('A5:B5');
				tabSheet.mergeCells('C5:H5');
				firstRow.getCell(1).font = { size: 14 };
				firstRow.getCell(1).value = 'Rebate Report: ';
				firstRow.getCell(3).font = { size: 16, bold: true };
				firstRow.getCell(3).value = this.rebateRptDetails.contractName;
				firstRow.getCell(12).font = { size: 14 };
				firstRow.getCell(12).value = 'Status: ';
				firstRow.getCell(13).value = txtApprovalStatus;
				firstRow.getCell(13).font = { size: 14, color: { argb: approvalStatusColor }, bold: true };
				const secondRow = tabSheet.getRow(6);
				secondRow.height = 20;
				tabSheet.mergeCells('A6:B6');
				tabSheet.mergeCells('C6:H6');
				secondRow.getCell(1).font = { size: 14 };
				secondRow.getCell(1).value = 'Buying Group: ';
				secondRow.getCell(3).font = { size: 14 };
				secondRow.getCell(3).value = this.rebateRptDetails.name;
				secondRow.getCell(12).font = { size: 14 };
				secondRow.getCell(12).value = 'Approval Status: ';
				secondRow.getCell(13).font = { size: 14, color: { argb: statusColor }, bold: true };
				secondRow.getCell(13).value = txtStatus;
				tabSheet.mergeCells('A7:B7');
				tabSheet.mergeCells('C7:H7');
				const thirdRow = tabSheet.getRow(7);
				thirdRow.getCell(1).font = { size: 14 };
				thirdRow.getCell(1).value = 'Settlement Period: ';
				thirdRow.getCell(3).font = { size: 14 };
				thirdRow.getCell(3).value = this.rebateRptDetails.settlementPeriodStart + ' To ' + this.rebateRptDetails.settlementPeriodEnd;
				thirdRow.getCell(12).font = { size: 14 };
				thirdRow.getCell(12).value = 'Generated: ';
				thirdRow.getCell(13).font = { size: 14 };
				thirdRow.getCell(13).value = this.rebateRptDetails.created_date;
				const fifthRow = tabSheet.getRow(9);
				const sixthRow = tabSheet.getRow(10);
				if (this.rebatesRptTabs.length > 0) {
					for (let index = 0; index < this.rebatesRptTabs.length; index++) {
						const element = this.rebatesRptTabs[index];
						fifthRow.getCell(1 + index).value = element.title;
						fifthRow.getCell(1 + index).font = { size: 14 };
						sixthRow.getCell(1 + index).value = this._CustomCurrencyPipe.transform(element.value || 0, this.customCurrencyOption);
						sixthRow.getCell(1 + index).font = { size: 14, bold: true };
					}
				}
				tabSheet.mergeCells('H9:J9');
				tabSheet.mergeCells('H10:J10');
				tabSheet.mergeCells('K9:M9');
				tabSheet.mergeCells('K10:M10');
				// fifthRow.getCell(10).font = { size: 14 };
				// fifthRow.getCell(10).value = 'Total Rebate Payable';
				// fifthRow.getCell(12).font = { size: 14 };
				// fifthRow.getCell(12).value = 'Total Rebate Generated'
				// sixthRow.getCell(10).font = { size: 14, bold: true };
				// sixthRow.getCell(10).value = this._CustomCurrencyPipe.transform(this.rebateRptDetails.totalRebatePayable || 0, this.customCurrencyOption);
				// sixthRow.getCell(12).font = { size: 14, bold: true };
				// sixthRow.getCell(12).value = this._CustomCurrencyPipe.transform(this.rebateRptDetails.totalRebateGenerated || 0, this.customCurrencyOption);
				// tabSheet.getCell('H9').border = {
				// 	top: { style: 'thin', color: { argb: '05C1A0' } },
				// 	left: { style: 'thin', color: { argb: '05C1A0' } },
				// };
				// tabSheet.getCell('K9').border = {
				// 	top: { style: 'thin', color: { argb: '05C1A0' } },
				// 	right: { style: 'thin', color: { argb: '05C1A0' } }
				// };
				// tabSheet.getCell('H10').border = {
				// 	bottom: { style: 'thin', color: { argb: '05C1A0' } },
				// 	left: { style: 'thin', color: { argb: '05C1A0' } },
				// };
				// tabSheet.getCell('K10').border = {
				// 	bottom: { style: 'thin', color: { argb: '05C1A0' } },
				// 	right: { style: 'thin', color: { argb: '05C1A0' } }
				// };
				// tabSheet.getCell('H9').fill = {
				// 	type: 'pattern',
				// 	pattern: 'darkVertical',
				// 	fgColor: { argb: 'CEF2EC' },
				// 	bgColor: { argb: 'CEF2EC' }
				// }
				// tabSheet.getCell('K9').fill = {
				// 	type: 'pattern',
				// 	pattern: 'darkVertical',
				// 	fgColor: { argb: 'CEF2EC' },
				// 	bgColor: { argb: 'CEF2EC' }
				// }
				// tabSheet.getCell('H10').fill = {
				// 	type: 'pattern',
				// 	pattern: 'darkVertical',
				// 	fgColor: { argb: 'CEF2EC' },
				// 	bgColor: { argb: 'CEF2EC' }
				// }
				// tabSheet.getCell('K10').fill = {
				// 	type: 'pattern',
				// 	pattern: 'darkVertical',
				// 	fgColor: { argb: 'CEF2EC' },
				// 	bgColor: { argb: 'CEF2EC' }
				// }

				// End Excel Summary View
				return exportDataGrid(tabSheetObj);
			})
			//last index export file 
			.then(() => {
				workbook.xlsx.writeBuffer().then(function (buffer) {
					saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
				})
			}
			)

	}

	doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					icon: "cleariconix",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.itemsInvoicesDataSourceRef.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			// {
			// 	location: 'after',
			// 	template: 'templateActionBtns',
			// 	visible: true,
			// },
		);
		let columnChooserButton = toolbarItems.find(x => x.name === "columnChooserButton");
		if (columnChooserButton) {
			columnChooserButton.options.text = "Column Chooser";
			// columnChooserButton.visible = false;
			columnChooserButton.showText = "always";
		}
	}

	doInitializedItemInvoiceReport(event) {
		setTimeout(() => {
			this.doAddDynamicFeatureCustomColumns(event);
		}, 200);
	}

	doCellClickForrebateRptDetails(event) {
		if (event.row && event.row.rowType === 'data') {
			if (event.data.hasOwnProperty(event.column.dataField) && event.data[event.column.dataField] !== 0 && (event.column.dataField === 'invoiceCode' || event.column.dataField === 'docnum')) {
				if (this.rebateRptDetails.rebate_for === 'distribution' || this.rebateRptDetails.rebate_for === 'distribution_differential') {
					this.viewEndUserInvoiceDetailProps = new ViewEndusersInvoiceDetailPopUpModel();
					this.viewEndUserInvoiceDetailProps.docNo = event.data[event.column.dataField];
					this.viewEndUserInvoiceDetailProps.docType = 'enduserinvoice';
					this.doOpenEndUserInvoiceDetailsPopup();
				} else {
					this.viewInvoiceDetailProps = new ViewInvoiceDetailPopUpModel();
					this.viewInvoiceDetailProps.docNo = event.data[event.column.dataField];
					this.viewInvoiceDetailProps.docType = 'invoice';
					this.viewInvoiceDetailProps.docDate = event.data.hasOwnProperty('docdate') ? event.data['docdate'] : event.data.hasOwnProperty('invoiceDate') ? event.data['invoiceDate'] : '';
					//this.viewInvoiceDetailProps.docDate = event.data.hasOwnProperty('docdate') ? event.data['docdate'] : '';
					this.doOpenInvoiceDetailsPopup();
				}
			}
		}
	}

	private doOpenInvoiceDetailsPopup() {
		this.isShowInvoiceDetailPopUp = false;
		setTimeout(() => {
			this.isShowInvoiceDetailPopUp = true;
		}, 100);
	}

	private doOpenEndUserInvoiceDetailsPopup() {
		this.isShowEndUserInvoiceDetailPopUp = false;
		setTimeout(() => {
			this.isShowEndUserInvoiceDetailPopUp = true;
		}, 100);
	}

	private doAddDynamicFeatureCustomColumns(event) {
		if (this.rebateRptDetails.rebate_for === 'distribution' || this.rebateRptDetails.rebate_for === 'distribution_differential') {
			const featureCustomFieldsObj = this.getFeatureCustomFieldsForItems();
			const customFieldDataList = [];
			const listOfFeaturesCustomFields = featureCustomFieldsObj.listOfFeatureCustomFields;
			if (listOfFeaturesCustomFields && listOfFeaturesCustomFields.length > 0) {
				for (let i = 0; i < listOfFeaturesCustomFields.length; i++) {
					const customField = listOfFeaturesCustomFields[i];
					const customFieldDataObj = {
						dataField: customField.ix_custom_field,
						caption: customField.custom_field_title,
						dataType: customField.custom_field_output_format,
						visible: customField.display,
						showInColumnChooser: customField.display,
						allowHeaderFiltering: false,
						allowFiltering: false,
						allowEditing: true
						// visibleIndex: tableColLen + i,
					};

					if (customField.custom_field_output_format === 'text' || customField.custom_field_output_format === 'string') {
						customFieldDataObj.allowHeaderFiltering = false;
						customFieldDataObj.allowFiltering = true;
						customFieldDataObj.dataType = 'string';
						customFieldDataObj['encodeHtml'] = false;
						if (customField.custom_field_values && customField.custom_field_values.length > 0) {
							customFieldDataObj.allowHeaderFiltering = true;
							customFieldDataObj.allowFiltering = false;
							customFieldDataObj['lookup'] = { dataSource: customField.custom_field_values, displayExpr: "value", valueExpr: "key", allowClearing: true };
						}
					}

					if (customField.custom_field_type === 'date') {
						customFieldDataObj.allowHeaderFiltering = false;
						customFieldDataObj.allowFiltering = true;
						customFieldDataObj['format'] = 'yyyy-MM-dd';
					}
					//if (responseData[i].ix_custom_field === 'over_pricelist_amount' || responseData[i].ix_custom_field === 'custom_doctotal' || responseData[i].ix_custom_field === 'base_profit_amount') {
					if (customField.custom_field_output_format === 'amount') {
						customFieldDataObj.allowHeaderFiltering = false;
						customFieldDataObj.allowFiltering = false;
						// customFieldDataObj['format'] = { type: 'currency', precision: this.decimalPointForCurrency };
						customFieldDataObj['calculateDisplayValue'] = this.getCurrencyDisplayValue;
					}
					if (customField.custom_field_output_format === 'percentage') {
						customFieldDataObj.allowHeaderFiltering = false;
						customFieldDataObj.allowFiltering = false;
						customFieldDataObj['customizeText'] = this.doCustomizeTextToPercent;
					}
					//}
					if (customField.custom_field_type === 'object') {
						if (customField.custom_field_output_format === 'string') {
							customFieldDataObj.allowHeaderFiltering = customField.custom_field_values && customField.custom_field_values.length > 0 ? true : false;
							customFieldDataObj.allowFiltering = (customField.custom_field_values && customField.custom_field_values.length > 0) ? false : true;
							customFieldDataObj.dataType = 'string';
							customFieldDataObj['encodeHtml'] = false;
							customFieldDataObj['lookup'] = { dataSource: customField.custom_field_values, displayExpr: "value", valueExpr: "key", allowClearing: true };
						}
						if (customField.custom_field_output_format === 'object') {
							customFieldDataObj.allowHeaderFiltering = false;
							customFieldDataObj.allowFiltering = true;
							customFieldDataObj['lookup'] = { dataSource: customField.custom_field_values, displayExpr: "value", valueExpr: "key" };
							customFieldDataObj.dataField = customField.ix_custom_field + '.refName';
						}
						if (customField.custom_field_output_format === 'amount') {
							customFieldDataObj.dataType = 'number';
							customFieldDataObj.allowHeaderFiltering = false;
							customFieldDataObj.allowFiltering = false;
							// customFieldDataObj['format'] = { type: 'currency', precision: this.decimalPointForCurrency };
							customFieldDataObj['calculateDisplayValue'] = this.getCurrencyDisplayValue;
						}
						if (customField.custom_field_output_format === 'percentage') {
							customFieldDataObj.dataType = 'number';
							customFieldDataObj.allowHeaderFiltering = false;
							customFieldDataObj.allowFiltering = false;
							customFieldDataObj['customizeText'] = this.doCustomizeTextToPercent;
						}
					}
					customFieldDataList.push(customFieldDataObj);
				}
				const listOfColumns = this.itemsInvoicesDataSourceRef.instance.getVisibleColumns();
				customFieldDataList.forEach(element => {
					const columnIndex = listOfColumns.findIndex(col => col.dataField === element.dataField);
					if (columnIndex === -1) {
						this.itemsInvoicesDataSourceRef.instance.addColumn(element);
					}
				});
			}
		}
	}

	private doCheckUseCustomersFrom() {
		let use_customers_from: string;
		try {
			use_customers_from = this._LoginService.loginUser.account_detail.use_customers_from;
		} catch (e) {
			use_customers_from = '';
		}
		this.isShowCardcodeColumn = false;
		if (use_customers_from === 'itemlist') {
			this.isShowCardcodeColumn = true;
			// It is displays Customer Code column in document line items.
		}
	}

	// Method used to get Uom List
	private fetchListOfUoms() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.UOM);
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name']));
		formData.append('is_dropdown', 'true');

		// this._LoaderService.show();
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		this.getUomLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			// this._LoaderService.hide();
			if (response) {
				if (response.flag) {
					this.listOfUoms = response.data;
					this.listOfUomsHeaderFilter = this.getHeaderFilter(response.data, 'code', 'name');
				}
			}
		}, error => {
			// this._LoaderService.hide();

		});
	}

	private getHeaderFilter(listOfArray: any[], codeField: string, textField: string): any[] {
		const listOfHeaderFilters = [];
		listOfArray.forEach(ele => {
			listOfHeaderFilters.push({ value: ele[codeField], text: ele[textField].toString().toUpperCase() });
		});
		return listOfHeaderFilters;
	}

	public doCustomizeTextToPercent(cellInfo) {
		return this._CustomNumberNoRoundingPipe.transform((+cellInfo.value || 0)) + '%';
	}

	public getCurrencyDisplayValue(rowData: any) {
		if (rowData && rowData.hasOwnProperty('total')) {
			return this._CustomCurrencyPipe.transform(rowData.total || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('linetotal')) {
			return this._CustomCurrencyPipe.transform(rowData.linetotal || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('rebateAmount')) {
			return this._CustomCurrencyPipe.transform(rowData.rebateAmount || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('finalAmount')) {
			return this._CustomCurrencyPipe.transform(rowData.finalAmount || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('price')) {
			return this._CustomCurrencyPipe.transform(rowData.price || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('baseCommissionAmount')) {
			return this._CustomCurrencyPipe.transform(rowData.baseCommissionAmount || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('baseRebateAmount')) {
			return this._CustomCurrencyPipe.transform(rowData.baseRebateAmount || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('differential_price')) {
			return this._CustomCurrencyPipe.transform(rowData.differential_price || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('rebateAmountBeforeSplit')) {
			return this._CustomCurrencyPipe.transform(rowData.rebateAmountBeforeSplit || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('cashdiscount')) {
			return this._CustomCurrencyPipe.transform(rowData.cashdiscount || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('miscDedAmount')) {
			return this._CustomCurrencyPipe.transform(rowData.miscDedAmount || 0, this.customCurrencyOption);
		}

		if (this.rebateRptDetails.rebate_for === 'distribution' || this.rebateRptDetails.rebate_for === 'distribution_differential') {
			const featureCustomFieldsObj = this.getFeatureCustomFieldsForItems();
			const listOfFeaturesCustomFields = featureCustomFieldsObj.listOfFeatureCustomFields;
			if (listOfFeaturesCustomFields && listOfFeaturesCustomFields.length > 0) {
				listOfFeaturesCustomFields.forEach(cf => {
					if (rowData.hasOwnProperty(cf.dataField)) {
						return this._CustomCurrencyPipe.transform(rowData[cf.dataField] || 0, this.customCurrencyOption);
					}
				});
			}
		}
	}

	private getFeatureCustomFieldsForItems() {
		const listOfFeatureCustomFieldsName: string[] = [];
		const listOfFeatureCustomFields: any[] = [];
		const listOfFeaturesCustomFields = this.listOfFeaturesCustomFields.filter(field => field.external_entity_field === true && field.entity_type === ServerEntity.ITEMS && field.section === "HEADER" && field.is_contract_field_use_for_creation === true);
		listOfFeaturesCustomFields.forEach(field => {
			if (field.display) {
				listOfFeatureCustomFieldsName.push(field.ix_custom_field);
				listOfFeatureCustomFields.push(field);
			}
		});
		return {
			listOfFeatureCustomFieldsName: listOfFeatureCustomFieldsName,
			listOfFeatureCustomFields: listOfFeatureCustomFields
		};
	}

	public doCustomizeSubTotalSummary(e) {
		if (e.value < 0) {
			return 'Sub Total: ' + this._CustomCurrencyPipe.transform(0, this.customCurrencyOption);
		} else {
			return 'Sub Total: ' + this._CustomCurrencyPipe.transform(e.value, this.customCurrencyOption);
		}
	}

	public doCustomizeTextTotalSummary(e) {
		if (e.value < 0) {
			return 'Total: ' + this._CustomCurrencyPipe.transform(0, this.customCurrencyOption);
		} else {
			return 'Total: ' + this._CustomCurrencyPipe.transform(e.value, this.customCurrencyOption);
		}
	}

	// Method used to display zero if negative summary value
	public doCustomizeTotalSummary(e) {
		if (e.value < 0) {
			return 'Total: ' + this._CustomCurrencyPipe.transform(0, this.customCurrencyOption);
		} else {
			return 'Total: ' + this._CustomCurrencyPipe.transform(e.value || 0, this.customCurrencyOption);
		}
	}


	public doLoadInvoiceState = () => {
		return this.loadStateDataGrid(this.stateStorageKey);
	}
	public doSaveInvoiceState = (e) => {
		this.saveStateDataGrid(e, this.stateStorageKey);
	}

	// Load RPT tab state data
	public loadStateDataGrid(configCode: string) {
		let newSection: any;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(this.mainSectionStorageKey)));
		} catch (e) {
			newSection = null;
		}
		const commissionSettlementReportConfigClone = newSection;
		if (!commissionSettlementReportConfigClone.configs) {
			commissionSettlementReportConfigClone.configs = [];
		}
		const statePersistentIndex = commissionSettlementReportConfigClone.configs.findIndex(sp => sp.code === configCode);
		if (statePersistentIndex !== -1) {
			if (commissionSettlementReportConfigClone.configs[statePersistentIndex].state_persistent.hasOwnProperty('columns')) {
				if (commissionSettlementReportConfigClone.configs[statePersistentIndex].state_persistent.columns.length > 0) {
					commissionSettlementReportConfigClone.configs[statePersistentIndex].state_persistent.columns.forEach(elementCol => {
						if (elementCol.hasOwnProperty('added')) {
							if (elementCol.added && elementCol.added.hasOwnProperty('format')) {
								const currencyCode = this.rebateRptDetails.currency_code ? this.rebateRptDetails.currency_code : this._LoginService.globalCurrency;
								elementCol.added.format = this._AppCommonSrvc.currencyFormat(currencyCode);
							}
						}
					});
				}
			}
			if (commissionSettlementReportConfigClone.configs[statePersistentIndex].state_persistent) {
				return commissionSettlementReportConfigClone.configs[statePersistentIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	//Save State Data
	public saveStateDataGrid(statePersistent: any, configCode: string) {
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		let salesListingConfig: any = {};

		try {
			salesListingConfig = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(this.mainSectionStorageKey)));
		} catch (error: any) {
			salesListingConfig = {};
		}

		if (!salesListingConfig.configs) {
			salesListingConfig.configs = [];
		}
		if (statePersistent.hasOwnProperty('columns')) {
			if (statePersistent.columns.length > 0) {
				statePersistent.columns.forEach(elementCol => {
					if (elementCol.hasOwnProperty('added')) {
						if (elementCol.added && elementCol.added.hasOwnProperty('format')) {
							const currencyCode = this.rebateRptDetails.currency_code ? this.rebateRptDetails.currency_code : this._LoginService.globalCurrency;
							elementCol.added.format = this._AppCommonSrvc.currencyFormat(currencyCode);
						}
					}
				});
			}
		}
		const configIndex = salesListingConfig.configs.findIndex(sp => sp.code === configCode);
		if (configIndex !== -1) {
			salesListingConfig.configs[configIndex].state_persistent = statePersistent;
		} else {
			const newConfigObj = {
				guid: this.guid.newGuid(),
				code: configCode,
				state_persistent: statePersistent
			};
			salesListingConfig.configs.push(newConfigObj);
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', ServerSections.COMMISSION_SETTLEMENT_REPORT_SETTING);
		formData.append('user_settings', JSON.stringify(salesListingConfig));

		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: (response) => {
				if (response && response.flag) {
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.mainSectionStorageKey] = salesListingConfig;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.mainSectionStorageKey, salesListingConfig);
					}
				}
			}, error: (error) => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.itemsInvoicesDataSourceRef.instance.option("filterBuilderPopup.visible", true);
	}
	// Method used to Get Same Text from Filter Panel
	private getFilterTextTab() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		if (this.itemsInvoicesDataSourceRef) {
			this.itemsInvoicesDataSourceRef.filterPanel.customizeText = (e: any) => {
				this.filterText = e.text;
				if (this.filterText) {
					this.isDisabledClearBtn = false;
				} else {
					this.isDisabledClearBtn = true;
				}
			};
		}
		setTimeout(() => {
			this.updateStatesClearBtn();
		}, 600);
	}

	// Method used to Enabled/Disabled Clear Button
	public updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}
}
