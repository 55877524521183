<!--without trial expried view -->
<div class="sideBar sideBarClosed"
	*ngIf="called_from !== 'CALLED_AS_TIRAL_EXPIRED' && called_from !== 'CALLED_AS_OVERDUE_PAYMENT'">
	<ul class="navigation">
		<li (click)="sideMenuClick('dashboard')"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'dashboard'}">
			<a>
				<span class="navallIcon icon-icon-1"></span>
				<span class="menu-title">Dashboard</span>
			</a>
			<ul class="subMenu">
				<li>
					<a>
						Dashboard
					</a>
				</li>
			</ul>
		</li>
		<li *ngxPermissionsOnly="['CRM_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'crms' || _SidemenuService.getCurrentMenu() === 'leads' ||  _SidemenuService.getCurrentMenu() === 'prospects' || _SidemenuService.getCurrentMenu() === 'clients' || _SidemenuService.getCurrentMenu() === 'tasks'}">
			<a>
				<span class="navallIcon icon-icon-2"></span>
				<span class="menu-title">CRM</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li (click)="sideMenuClick('crms')">
					<a>
						CRM
					</a>
				</li>
				<li *ngxPermissionsOnly="['CRM_LEAD_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'leads' || _SidemenuService.getCurrentMenu() === 'crms'}">
					<a (click)="sideMenuClick('leads')">
						<span class="dashIcon"></span> Leads
					</a>
				</li>
				<li *ngxPermissionsOnly="['CRM_PROSPECT_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'prospects'}">
					<a (click)="sideMenuClick('prospects')">
						<span class="dashIcon"></span> Prospects
					</a>
				</li>
				<li *ngxPermissionsOnly="['CRM_CUSTOMER_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'clients'}">
					<a (click)="sideMenuClick('clients')">
						<span class="dashIcon"></span> Customers
					</a>
				</li>
				<li *ngxPermissionsOnly="['CRM_TASK_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'tasks'}">
					<a (click)="sideMenuClick('tasks')">
						<span class="dashIcon"></span> Tasks
					</a>
				</li>
			</ul>
		</li>
		<li *ngxPermissionsOnly="['PRODUCTS_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'products' || _SidemenuService.getCurrentMenu() === 'listOfProducts' || _SidemenuService.getCurrentMenu() === 'priceLists'}">
			<a>
				<span class="navallIcon icon-icon-3"></span>
				<span class="menu-title">Items</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li>
					<a (click)="sideMenuClick('products')">
						Items
					</a>
				</li>
				<li *ngxPermissionsOnly="['PRODUCTS_PRODUCT_LIST_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'listOfProducts' || _SidemenuService.getCurrentMenu() === 'products'}">
					<a (click)="sideMenuClick('listOfProducts')">
						<span class="dashIcon"></span> Item List
					</a>
				</li>

				<li *ngxPermissionsOnly="['PRODUCTS_PRICE_LIST_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'priceLists'}">
					<a (click)="sideMenuClick('priceLists')">
						<span class="dashIcon"></span> Price List
					</a>
				</li>
			</ul>
		</li>
		<li *ngxPermissionsOnly="['INCENTIVE_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'commission' || _SidemenuService.getCurrentMenu() === 'rebates' || _SidemenuService.getCurrentMenu() === 'royalties' || _SidemenuService.getCurrentMenu() === 'billbacks' || _SidemenuService.getCurrentMenu() === 'incentives' || _SidemenuService.getCurrentMenu() === 'trade-promotion'}">
			<a>
				<span class="navallIcon icon-icon-4"></span>
				<span class="menu-title">Incentives</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li>
					<a (click)="sideMenuClick('incentives')">
						Incentives
					</a>
				</li>
				<li *ngxPermissionsOnly="['INCENTIVE_COMMISSION_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'commission' || _SidemenuService.getCurrentMenu() === 'incentives'}">
					<a (click)="sideMenuClick('commission')">
						<span class="dashIcon"></span> Commission
					</a>
				</li>
				<li *ngxPermissionsOnly="['INCENTIVE_REBATES_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'rebates'}">
					<a (click)="sideMenuClick('rebates')">
						<span class="dashIcon"></span> Rebates
					</a>
				</li>
				<li *ngxPermissionsOnly="['INCENTIVE_ROYALTIES_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'royalties'}">
					<a (click)="sideMenuClick('royalties')">
						<span class="dashIcon"></span> Royalties
					</a>
				</li>
				<li *ngxPermissionsOnly="['INCENTIVE_BILLBACKCHARGEBACK_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'billbacks'}">
					<a (click)="sideMenuClick('billbacks')">
						<span class="dashIcon"></span> Billbacks & Chargebacks
					</a>
				</li>
				<!-- <li *ngxPermissionsOnly="['INCENTIVE_TRADE_PROMOTION_VIEW']" -->
				<li [ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'trade-promotion'}"
					*ngIf="isEnabledTradePromotion">
					<a (click)="sideMenuClick('trade-promotion')">
						<span class="dashIcon"></span> Trade Promotion
					</a>
				</li>
			</ul>
		</li>
		<li *ngxPermissionsOnly="['BUSINESSPARTNERS_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'businesspartners' || _SidemenuService.getCurrentMenu() === 'customers' || _SidemenuService.getCurrentMenu() === 'vendors' || _SidemenuService.getCurrentMenu() === 'buyinggroups' || _SidemenuService.getCurrentMenu() === 'endusers' }">
			<a>
				<span class="navallIcon icon-icon-5"></span>
				<span class="menu-title">Companies</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li>
					<a (click)="sideMenuClick('businesspartners')">
						Companies
					</a>
				</li>
				<li *ngxPermissionsOnly="['BUSINESSPARTNERS_CUSTOMER_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'customers' || _SidemenuService.getCurrentMenu() === 'businesspartners'}">
					<a (click)="sideMenuClick('customers')">
						<span class="dashIcon"></span> Customers
					</a>
				</li>
				<li *ngxPermissionsOnly="['BUSINESSPARTNERS_VENDORS_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'vendors'}">
					<a (click)="sideMenuClick('vendors')">
						<span class="dashIcon"></span> Vendors
					</a>
				</li>
				<li *ngxPermissionsOnly="['BUSINESSPARTNERS_BUYINGGROUPS_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'buyinggroups'}">
					<a (click)="sideMenuClick('buyinggroups')">
						<span class="dashIcon"></span> Buying Groups
					</a>
				</li>
				<li *ngxPermissionsOnly="['BUSINESSPARTNERS_ENDUSER_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'endusers'}">
					<a (click)="sideMenuClick('endusers')">
						<span class="dashIcon"></span> End Users
					</a>
				</li>
			</ul>
		</li>
		<li *ngxPermissionsOnly="['SALES_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'sales' || _SidemenuService.getCurrentMenu() === 'quotes' || _SidemenuService.getCurrentMenu() === 'salesorders' || _SidemenuService.getCurrentMenu() === 'salesinvoices' || _SidemenuService.getCurrentMenu() === 'ar-credit-memos-list'  || _SidemenuService.getCurrentMenu() === 'shipments' || _SidemenuService.getCurrentMenu() === 'payments' ||  _SidemenuService.getCurrentMenu() === 'chargebacklist' || _SidemenuService.getCurrentMenu() === 'end-user-invoices-list' }">
			<a>
				<span class="navallIcon icon-icon-6"></span>
				<span class="menu-title">Sales</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li>
					<a (click)="sideMenuClick('sales')">
						Sales
					</a>
				</li>
				<li *ngxPermissionsOnly="['SALES_QUOTATION_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'quotes' || _SidemenuService.getCurrentMenu() === 'sales'}">
					<a (click)="sideMenuClick('quotes')">
						<span class="dashIcon"></span> Quotes
					</a>
				</li>
				<li *ngxPermissionsOnly="['SALES_ORDER_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'salesorders'}">
					<a (click)="sideMenuClick('salesorders')">
						<span class="dashIcon"></span> Orders
					</a>
				</li>
				<li *ngIf="isEnabledShipmentMenu"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'shipments'}">
					<a (click)="sideMenuClick('shipments')">
						<span class="dashIcon"></span> Shipments
					</a>
				</li>

				<li *ngxPermissionsOnly="['SALES_ARINVOIECES_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'salesinvoices'}">
					<a (click)="sideMenuClick('salesinvoices')">
						<span class="dashIcon"></span> AR Invoices
					</a>
				</li>
				<li *ngIf="isEnabledPaymentMenu"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'payments'}">
					<a (click)="sideMenuClick('payments')">
						<span class="dashIcon"></span> Payments
					</a>
				</li>
				<li *ngxPermissionsOnly="['SALES_ARCREDITMEMO_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'ar-credit-memos-list'}">
					<a (click)="sideMenuClick('ar-credit-memos-list')">
						<span class="dashIcon"></span> AR Credit Memos
					</a>
				</li>
				<li *ngxPermissionsOnly="['SALES_DISTRIBUTION_CHARGEBACK_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'chargebacklist'}">
					<a (click)="sideMenuClick('chargebacklist')">
						<span class="dashIcon"></span> Chargebacks
					</a>
				</li>
				<li *ngxPermissionsOnly="['SALES_ENDUSER_INVOICES_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'end-user-invoices-list'}">
					<a (click)="sideMenuClick('end-user-invoices-list')">
						<span class="dashIcon"></span> End-User Invoices
					</a>
				</li>

			</ul>
		</li>
		<li *ngxPermissionsOnly="['PURCHASES_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'purchases' || _SidemenuService.getCurrentMenu() === 'ap-invoices-list' || _SidemenuService.getCurrentMenu() === 'ap-credit-memos-list' || _SidemenuService.getCurrentMenu() === 'ap-cost-accounting-list'}">
			<a>
				<span class="navallIcon icon-icon-15"></span>
				<span class="menu-title">Purchases</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li>
					<a (click)="sideMenuClick('purchases')">
						Purchases
					</a>
				</li>
				<li *ngxPermissionsOnly="['PURCHASES_APINVOICES_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'ap-invoices-list' || _SidemenuService.getCurrentMenu() === 'purchases'}">
					<a (click)="sideMenuClick('ap-invoices-list')">
						<span class="dashIcon"></span> AP Invoices
					</a>
				</li>
				<li *ngxPermissionsOnly="['PURCHASES_APCREDITMEMO_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'ap-credit-memos-list' || _SidemenuService.getCurrentMenu() === 'purchases'}">
					<a (click)="sideMenuClick('ap-credit-memos-list')">
						<span class="dashIcon"></span> AP Credit Memos
					</a>
				</li>
				<li *ngIf="isEnabledCostAccountingMenu"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'ap-cost-accounting-list' || _SidemenuService.getCurrentMenu() === 'purchases'}">
					<a (click)="sideMenuClick('ap-cost-accounting-list')">
						<span class="dashIcon"></span> Cost Accounting
					</a>
				</li>
			</ul>
		</li>
		<li *ngxPermissionsOnly="['FINANCIAL_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'financial' || _SidemenuService.getCurrentMenu() === 'journal-entries-list' || _SidemenuService.getCurrentMenu() === 'gl-accounts-list'}">
			<a>
				<span class="navallIcon icon-Financial"></span>
				<span class="menu-title">Finance</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li>
					<a (click)="sideMenuClick('financial')">
						Finance
					</a>
				</li>
				<li *ngxPermissionsOnly="['FINANCIAL_GL_ACCOUNTS_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'gl-accounts-list' || _SidemenuService.getCurrentMenu() === 'financial'}">
					<a (click)="sideMenuClick('gl-accounts-list')">
						<span class="dashIcon"></span> GL Accounts
					</a>
				</li>
				<li *ngxPermissionsOnly="['FINANCIAL_JOURNAL_ENTRIES_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'journal-entries-list' || _SidemenuService.getCurrentMenu() === 'financial'}">
					<a (click)="sideMenuClick('journal-entries-list')">
						<span class="dashIcon"></span> Journal Entries
					</a>
				</li>
			</ul>
		</li>
		<li *ngxPermissionsOnly="['REPORTS_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'reports' || _SidemenuService.getCurrentMenu() === 'yeartodate' || _SidemenuService.getCurrentMenu() === 'yearoveryear' || _SidemenuService.getCurrentMenu() === 'periodtodate' || _SidemenuService.getCurrentMenu() === 'relateditemsanalytics' || _SidemenuService.getCurrentMenu() === 'potentialris' || _SidemenuService.getCurrentMenu() === 'incentives_reports' || _SidemenuService.getCurrentMenu() === 'statementsreportlist'}">
			<a>
				<span class="navallIcon icon-icon-7"></span>
				<span class="menu-title">Reports</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li>
					<a (click)="sideMenuClick('reports')">
						Reports
					</a>
				</li>
				<li *ngxPermissionsOnly="['REPORTS_YEAR_TO_DATE_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'yeartodate' || _SidemenuService.getCurrentMenu() === 'reports'}">
					<a (click)="sideMenuClick('yeartodate')">
						<span class="dashIcon"></span> Data Xplorer
					</a>
				</li>
				<li *ngxPermissionsOnly="['REPORTS_INCENTIVES_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'incentives_reports' || _SidemenuService.getCurrentMenu() === 'incentives_reports'}">
					<a (click)="sideMenuClick('incentives_reports')">
						<span class="dashIcon"></span> Incentives
					</a>
				</li>
				<li *ngxPermissionsOnly="['REPORTS_YEAR_OVER_YEAR_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'yeartoyear'}">
					<a (click)="sideMenuClick('yeartoyear')">
						<span class="dashIcon"></span> Year Over Year
					</a>
				</li>
				<li *ngxPermissionsOnly="['REPORTS_PERIOD_TO_DATE_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'periodtodate'}">
					<a (click)="sideMenuClick('periodtodate')">
						<span class="dashIcon"></span> Period To Date
					</a>
				</li>
				<li *ngxPermissionsOnly="['REPORTS_STATEMENTS_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'statementsreportlist'}">
					<a (click)="sideMenuClick('statementsreportlist')">
						<span class="dashIcon"></span> Statements
					</a>
				</li>
				<!-- <li>
					<a (click)="sideMenuClick('relateditemsanalytics')">
						Related Items Analytics
					</a>
				</li>
				<li>
					<a (click)="sideMenuClick('potentialrisk')">
						Potential Risk
					</a>
				</li> -->
			</ul>
		</li>
		<li *ngxPermissionsOnly="['USERMANAGEMENT_USER_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'usermanagement' || _SidemenuService.getCurrentMenu() === 'rolesmanagement' || _SidemenuService.getCurrentMenu() === 'allusers'  || _SidemenuService.getCurrentMenu() === 'supportusers'}">
			<a>
				<span class="navallIcon icon-icon-8"></span>
				<span class="menu-title">User Management</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li>
					<a (click)="sideMenuClick('usermanagement')">
						User Management
					</a>
				</li>
				<li
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'usermanagement' || _SidemenuService.getCurrentMenu() === 'allusers'  || _SidemenuService.getCurrentMenu() === 'supportusers'}">
					<a (click)="sideMenuClick('usermanagement')">
						<span class="dashIcon"></span> Users
					</a>
				</li>
				<!-- <li [ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'usergroups'}">
               <a (click)="sideMenuClick('usergroups')">
                  <span class="dashIcon"></span> Groups
               </a>
            </li>-->

				<li *ngxPermissionsOnly="['USERMANAGEMENT_ROLE_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'rolesmanagement'}">
					<a (click)="sideMenuClick('rolesmanagement')">
						<span class="dashIcon"></span> Roles
					</a>
				</li>
			</ul>
		</li>
		<li *ngxPermissionsOnly="['APP_SETTING_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'apps-configs' || _SidemenuService.getCurrentMenu() === 'fiscalcalculationoptions' || _SidemenuService.getCurrentMenu() === 'systemconfigurations' || _SidemenuService.getCurrentMenu() === 'dynamicfields' || _SidemenuService.getCurrentMenu() === 'workflows' || _SidemenuService.getCurrentMenu() === 'audit_logs' || _SidemenuService.getCurrentMenu() === 'approval_trails_logs'}">
			<a>
				<span class="navallIcon icon-icon-9"></span>
				<span class="menu-title">Application Settings</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li>
					<a (click)="sideMenuClick('apps-configs')">
						<span class="dashIcon"></span> Application Settings
					</a>
				</li>
				<li *ngxPermissionsOnly="['SYSTEM_CONFIGURATIONS_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'systemconfigurations'}">
					<a (click)="sideMenuClick('systemconfigurations')">
						<span class="dashIcon"></span> System Configuration
					</a>
				</li>
				<li *ngxPermissionsOnly="['FISCAL_CALCULATION_OPTIONS_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'fiscalcalculationoptions'}">
					<a (click)="sideMenuClick('fiscalcalculationoptions')">
						<span class="dashIcon"></span> Fiscal & Calculation Options
					</a>
				</li>
				<li *ngxPermissionsOnly="['DYNAMIC_FIELDS_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'dynamicfields'}">
					<a (click)="sideMenuClick('dynamicfields')">
						<span class="dashIcon"></span> Custom Properties
					</a>
				</li>
				<li [ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'workflows'}" class="disabled">
					<a (click)="sideMenuClick('workflows')">
						<span class="dashIcon"></span> Workflows
					</a>
				</li>
				<li [ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'audit_logs'}" class="disabled">
					<a (click)="sideMenuClick('audit_logs')">
						<span class="dashIcon"></span> Audit Logs
					</a>
				</li>
				<li [ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'approval_trails_logs'}"
					class="disabled">
					<a (click)="sideMenuClick('approval_trails_logs')">
						<span class="dashIcon"></span> Approval Trail Logs
					</a>
				</li>
			</ul>
		</li>
	</ul>
</div>
<!--end without trial expried view -->

<!--trial expried view -->
<div class="sideBar sideBarClosed"
	*ngIf="called_from === 'CALLED_AS_TIRAL_EXPIRED' || called_from === 'CALLED_AS_OVERDUE_PAYMENT'">
	<ul class="navigation">
		<li [ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'dashboard'}" class="disabled">
			<a>
				<span class="navallIcon icon-icon-1"></span>
				<span class="menu-title">Dashboard</span>
			</a>
			<ul class="subMenu">
				<li class="disabled">
					<a>
						Dashboard
					</a>
				</li>
			</ul>
		</li>
		<li *ngxPermissionsOnly="['CRM_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'crms' || _SidemenuService.getCurrentMenu() === 'leads' ||  _SidemenuService.getCurrentMenu() === 'prospects' || _SidemenuService.getCurrentMenu() === 'clients' || _SidemenuService.getCurrentMenu() === 'tasks'}"
			class="disabled">
			<a>
				<span class="navallIcon icon-icon-2"></span>
				<span class="menu-title">CRM</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li class="disabled">
					<a>
						CRM
					</a>
				</li>
				<li *ngxPermissionsOnly="['CRM_LEAD_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'leads' || _SidemenuService.getCurrentMenu() === 'crms'}"
					class="disabled">
					<a>
						<span class="dashIcon"></span> Leads
					</a>
				</li>
				<li *ngxPermissionsOnly="['CRM_PROSPECT_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'prospects'}" class="disabled">
					<a>
						<span class="dashIcon"></span> Prospects
					</a>
				</li>
				<li *ngxPermissionsOnly="['CRM_CUSTOMER_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'clients'}" class="disabled">
					<a>
						<span class="dashIcon"></span> Customers
					</a>
				</li>
				<li *ngxPermissionsOnly="['CRM_TASK_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'tasks'}" class="disabled">
					<a>
						<span class="dashIcon"></span> Tasks
					</a>
				</li>
			</ul>
		</li>
		<li *ngxPermissionsOnly="['PRODUCTS_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'products' || _SidemenuService.getCurrentMenu() === 'listOfProducts' || _SidemenuService.getCurrentMenu() === 'priceLists'}"
			class="disabled">
			<a>
				<span class="navallIcon icon-icon-3"></span>
				<span class="menu-title">Items</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li class="disabled">
					<a>
						Items
					</a>
				</li>
				<li *ngxPermissionsOnly="['PRODUCTS_PRODUCT_LIST_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'listOfProducts' || _SidemenuService.getCurrentMenu() === 'products'}"
					class="disabled">
					<a>
						<span class="dashIcon"></span> Item List
					</a>
				</li>

				<li *ngxPermissionsOnly="['PRODUCTS_PRICE_LIST_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'priceLists'}" class="disabled">
					<a>
						<span class="dashIcon"></span> Price List
					</a>
				</li>

			</ul>
		</li>
		<li *ngxPermissionsOnly="['INCENTIVE_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'commission' || _SidemenuService.getCurrentMenu() === 'rebates' || _SidemenuService.getCurrentMenu() === 'royalties' || _SidemenuService.getCurrentMenu() === 'billbacks' || _SidemenuService.getCurrentMenu() === 'incentives' }"
			class="disabled">
			<a>
				<span class="navallIcon icon-icon-4"></span>
				<span class="menu-title">Incentives</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li class="disabled">
					<a>
						Incentives
					</a>
				</li>
				<li *ngxPermissionsOnly="['INCENTIVE_COMMISSION_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'commission' || _SidemenuService.getCurrentMenu() === 'incentives'}"
					class="disabled">
					<a>
						<span class="dashIcon"></span> Commission
					</a>
				</li>
				<li *ngxPermissionsOnly="['INCENTIVE_REBATES_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'rebates'}" class="disabled">
					<a>
						<span class="dashIcon"></span> Rebates
					</a>
				</li>
				<li *ngxPermissionsOnly="['INCENTIVE_ROYALTIES_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'royalties'}" class="disabled">
					<a>
						<span class="dashIcon"></span> Royalties
					</a>
				</li>
				<li *ngxPermissionsOnly="['INCENTIVE_BILLBACKCHARGEBACK_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'billbacks'}" class="disabled">
					<a>
						<span class="dashIcon"></span> Billbacks & Chargebacks
					</a>
				</li>
			</ul>
		</li>
		<li *ngxPermissionsOnly="['BUSINESSPARTNERS_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'businesspartners' || _SidemenuService.getCurrentMenu() === 'customers' || _SidemenuService.getCurrentMenu() === 'vendors' || _SidemenuService.getCurrentMenu() === 'buyinggroups' || _SidemenuService.getCurrentMenu() === 'endusers' }"
			class="disabled">
			<a>
				<span class="navallIcon icon-icon-5"></span>
				<span class="menu-title">Companies</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li class="disabled">
					<a>
						Companies
					</a>
				</li>
				<li *ngxPermissionsOnly="['BUSINESSPARTNERS_CUSTOMER_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'customers' || _SidemenuService.getCurrentMenu() === 'businesspartners'}"
					class="disabled">
					<a>
						<span class="dashIcon"></span> Customers
					</a>
				</li>
				<li *ngxPermissionsOnly="['BUSINESSPARTNERS_VENDORS_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'vendors'}" class="disabled">
					<a>
						<span class="dashIcon"></span> Vendors
					</a>
				</li>
				<li *ngxPermissionsOnly="['BUSINESSPARTNERS_BUYINGGROUPS_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'buyinggroups'}" class="disabled">
					<a>
						<span class="dashIcon"></span> Buying Groups
					</a>
				</li>
				<li *ngxPermissionsOnly="['BUSINESSPARTNERS_ENDUSER_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'endusers'}" class="disabled">
					<a>
						<span class="dashIcon"></span> End Users
					</a>
				</li>
			</ul>
		</li>
		<li *ngxPermissionsOnly="['SALES_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'sales' || _SidemenuService.getCurrentMenu() === 'quotes' || _SidemenuService.getCurrentMenu() === 'salesorders' || _SidemenuService.getCurrentMenu() === 'salesinvoices' || _SidemenuService.getCurrentMenu() === 'ar-credit-memos-list'  || _SidemenuService.getCurrentMenu() === 'shipments' || _SidemenuService.getCurrentMenu() === 'payments' ||  _SidemenuService.getCurrentMenu() === 'chargebacklist' || _SidemenuService.getCurrentMenu() === 'end-user-invoices-list'}"
			class="disabled">
			<a>
				<span class="navallIcon icon-icon-6"></span>
				<span class="menu-title">Sales</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li class="disabled">
					<a>
						Sales
					</a>
				</li>
				<li *ngxPermissionsOnly="['SALES_QUOTATION_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'quotes' || _SidemenuService.getCurrentMenu() === 'sales'}"
					class="disabled">
					<a>
						<span class="dashIcon"></span> Quotes
					</a>
				</li>
				<li *ngxPermissionsOnly="['SALES_ORDER_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'salesorders'}" class="disabled">
					<a>
						<span class="dashIcon"></span> Orders
					</a>
				</li>
				<li *ngif="isEnabledShipmentMenu"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'shipments'}" class="disabled">
					<a>
						<span class="dashIcon"></span> Shipments
					</a>
				</li>
				<li *ngxPermissionsOnly="['SALES_ARINVOIECES_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'salesinvoices'}" class="disabled">
					<a>
						<span class="dashIcon"></span> AR Invoices
					</a>
				</li>
				<li *ngIf="isEnabledPaymentMenu" [ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'payments'}"
					class="disabled">
					<a>
						<span class="dashIcon"></span> Payments
					</a>
				</li>
				<li *ngxPermissionsOnly="['SALES_ARCREDITMEMO_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'ar-credit-memos-list'}" class="disabled">
					<a>
						<span class="dashIcon"></span> AR Credit Memo
					</a>
				</li>
				<li *ngxPermissionsOnly="['SALES_DISTRIBUTION_CHARGEBACK_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'chargebacklist'}" class="disabled">
					<a>
						<span class="dashIcon"></span> Chargebacks
					</a>
				</li>
				<li *ngxPermissionsOnly="['SALES_ENDUSER_INVOICES_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'end-user-invoices-list'}"
					class="disabled">
					<a>
						<span class="dashIcon"></span> End-User Invoices
					</a>
				</li>
			</ul>
		</li>
		<li *ngxPermissionsOnly="['PURCHASES_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'purchases' || _SidemenuService.getCurrentMenu() === 'ap-invoices-list' || _SidemenuService.getCurrentMenu() === 'ap-credit-memos-list' }"
			class="disabled">
			<a>
				<span class="navallIcon icon-icon-15"></span>
				<span class="menu-title">Purchases</span>
			</a>
			<span class="toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li class="disabled">
					<a>
						Purchases
					</a>
				</li>
				<li *ngxPermissionsOnly="['PURCHASES_APINVOICES_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'ap-invoices-list' || _SidemenuService.getCurrentMenu() === 'purchases'}"
					class="disabled">
					<a>
						<span class="dashIcon"></span> AP Invoices
					</a>
				</li>
				<li *ngxPermissionsOnly="['PURCHASES_APCREDITMEMO_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'ap-credit-memos-list' || _SidemenuService.getCurrentMenu() === 'purchases'}"
					class="disabled">
					<a>
						<span class="dashIcon"></span> AP Credit Memos
					</a>
				</li>
				<li *ngIf="isEnabledCostAccountingMenu"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'ap-cost-accounting-list' || _SidemenuService.getCurrentMenu() === 'purchases'}"
					class="disabled">
					<a>
						<span class="dashIcon"></span> Cost Accounting
					</a>
				</li>
			</ul>
		</li>
		<li *ngxPermissionsOnly="['FINANCIAL_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'financial' || _SidemenuService.getCurrentMenu() === 'journal-entries-list' || _SidemenuService.getCurrentMenu() === 'gl-accounts-list'}"
			class="disabled">
			<a>
				<span class="navallIcon icon-Financial"></span>
				<span class="menu-title">Finance</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li class="disabled">
					<a>
						Finance
					</a>
				</li>
				<li *ngxPermissionsOnly="['FINANCIAL_JOURNAL_ENTRIES_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'journal-entries-list' || _SidemenuService.getCurrentMenu() === 'financial'}"
					class="disabled">
					<a>
						<span class="dashIcon"></span> Journal Entries
					</a>
				</li>
				<li *ngxPermissionsOnly="['FINANCIAL_GL_ACCOUNTS_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'gl-accounts-list' || _SidemenuService.getCurrentMenu() === 'financial'}"
					class="disabled">
					<a>
						<span class="dashIcon"></span> GL Accounts
					</a>
				</li>
			</ul>
		</li>
		<li *ngxPermissionsOnly="['REPORTS_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'reports' || _SidemenuService.getCurrentMenu() === 'yeartodate' || _SidemenuService.getCurrentMenu() === 'yearoveryear' || _SidemenuService.getCurrentMenu() === 'periodtodate' || _SidemenuService.getCurrentMenu() === 'relateditemsanalytics' || _SidemenuService.getCurrentMenu() === 'potentialris'}"
			class="disabled">
			<a>
				<span class="navallIcon icon-icon-7"></span>
				<span class="menu-title">Reports</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li class="disabled">
					<a>
						Reports
					</a>
				</li>
				<li *ngxPermissionsOnly="['REPORTS_YEAR_TO_DATE_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'yeartodate' || _SidemenuService.getCurrentMenu() === 'reports'}"
					class="disabled">
					<a>
						<span class="dashIcon"></span> Data Xplorer
					</a>
				</li>
				<li *ngxPermissionsOnly="['REPORTS_YEAR_OVER_YEAR_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'yeartoyear'}" class="disabled">
					<a>
						<span class="dashIcon"></span> Year Over Year
					</a>
				</li>
				<li *ngxPermissionsOnly="['REPORTS_PERIOD_TO_DATE_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'periodtodate'}" class="disabled">
					<a>
						<span class="dashIcon"></span> Period To Date
					</a>
				</li>
				<li *ngxPermissionsOnly="['REPORTS_STATEMENTS_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'statementsreportlist'}">
					<a (click)="sideMenuClick('statementsreportlist')">
						<span class="dashIcon"></span> Statements
					</a>
				</li>
			</ul>
		</li>
		<li *ngxPermissionsOnly="['USERMANAGEMENT_USER_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'usermanagement' || _SidemenuService.getCurrentMenu() === 'rolesmanagement' || _SidemenuService.getCurrentMenu() === 'allusers'  || _SidemenuService.getCurrentMenu() === 'supportusers'}"
			class="disabled">
			<a>
				<span class="navallIcon icon-icon-8"></span>
				<span class="menu-title">User Management</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li class="disabled">
					<a>
						User Management
					</a>
				</li>
				<li [ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'usermanagement' || _SidemenuService.getCurrentMenu() === 'allusers'  || _SidemenuService.getCurrentMenu() === 'supportusers'}"
					class="disabled">
					<a>
						<span class="dashIcon"></span> Users
					</a>
				</li>
				<li *ngxPermissionsOnly="['USERMANAGEMENT_ROLE_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'rolesmanagement'}" class="disabled">
					<a>
						<span class="dashIcon"></span> Roles
					</a>
				</li>
			</ul>
		</li>
		<li *ngxPermissionsOnly="['APP_SETTING_VIEW']"
			[ngClass]="{activate: _SidemenuService.getCurrentMenu() === 'apps-configs' || _SidemenuService.getCurrentMenu() === 'externalsystemconfiguration' || _SidemenuService.getCurrentMenu() === 'fiscalcalculationoptions' || _SidemenuService.getCurrentMenu() === 'systemconfigurations' || _SidemenuService.getCurrentMenu() === 'dynamicfields'}"
			class="disabled">
			<a>
				<span class="navallIcon icon-icon-9"></span>
				<span class="menu-title">Application Settings</span>
			</a>
			<span class="icon-toggleDown toggleSub"></span>
			<ul class="subMenu toggleIt">
				<li class="disabled">
					<a>
						<span class="dashIcon"></span> Application Settings
					</a>
				</li>
				<li *ngxPermissionsOnly="['SYSTEM_CONFIGURATIONS_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'systemconfigurations'}" class="disabled">
					<a>
						<span class="dashIcon"></span> System Configuration
					</a>
				</li>
				<li *ngxPermissionsOnly="['FISCAL_CALCULATION_OPTIONS_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'fiscalcalculationoptions'}"
					class="disabled">
					<a>
						<span class="dashIcon"></span> Fiscal & Calculation Options
					</a>
				</li>

				<li *ngxPermissionsOnly="['DYNAMIC_FIELDS_VIEW']"
					[ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'dynamicfields'}" class="disabled">
					<a>
						<span class="dashIcon"></span> Custom Properties
					</a>
				</li>
				<li [ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'workflows'}" class="disabled">
					<a (click)="sideMenuClick('workflows')">
						<span class="dashIcon"></span> Workflows
					</a>
				</li>
				<li [ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'audit_logs'}" class="disabled">
					<a (click)="sideMenuClick('audit_logs')">
						<span class="dashIcon"></span> Audit Logs
					</a>
				</li>
				<li [ngClass]="{currentPage: _SidemenuService.getCurrentMenu() === 'approval_trails_logs'}"
					class="disabled">
					<a (click)="sideMenuClick('approval_trails_logs')">
						<span class="dashIcon"></span> Approval Trail Logs
					</a>
				</li>
			</ul>
		</li>
	</ul>
</div>
<!--End trial expried view -->