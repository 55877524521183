import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rs-ar-credit-memos-rpt-tab',
  templateUrl: './rs-ar-credit-memos-rpt-tab.component.html',
  styleUrls: ['./rs-ar-credit-memos-rpt-tab.component.css']
})
export class RsArCreditMemosRptTabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
