import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { Router } from '@angular/router';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { ErpTypeValEnum, SettlementPeriodsValEnum } from '@app/constants-enums/enums';
import {
	CommissionContractTemplateModelRequest, CommissionContractTemplateModelUI, ExclusionTabModel,
	RuleTabModel
} from '@app/models/commission-contract.model';
import { Guid } from '@app/models/guid';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
import { DataService } from '@app/services/data.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { DxSelectBoxComponent } from 'devextreme-angular';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
import { CurrencyOptions } from '@app/models/common.model';

@Component({
	selector: 'app-commission-tierbase-contract-template',
	templateUrl: './commission-tierbase-contract-template.component.html',
	styleUrls: ['./commission-tierbase-contract-template.component.css'],
})
export class CommissionTierbaseContractTemplateComponent implements OnInit {

	//#region Local Variable
	@Input() exclusionTabList: any = [];
	@Input() commissionDetailsProps: any;
	// Comman public object
	public isDisablePermission: boolean = false;
	public isCurrentTabPermission: string;
	public isAddMode: boolean = true;
	// public editCommissionGuid: string;
	public editCommissionTemplateGuid: string;
	public currentAgreementTab: string;
	public searchTimeout: any;
	public exclusionsGroupRadioButton: string;
	public btnRulesAdd: string = 'Add';
	public rulesAddOrEditIndex: string = '-1';
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public loginUserRoleList: any = [];
	public erp_type: string;
	public salesPersonDataSource: any;
	public salesPersonGridBoxValue: any;
	public salespersonGridBoxColumns: any;
	public isSalesPersonDropDownBoxOpened: boolean = false;

	// Drop down list object
	public salesPplList: any = [];
	public GroupsList: any = [];
	public selectCustomerList: any = [];
	public selectCustomerSearchTabData: any = [];
	public selectProductSearchTabData: any = [];
	public closecontractsArray: any = [];
	public radioCommissionTypeDataList: any = [];
	public listOfContractCalendar: any[] = [];

	// Agreement tab table list object
	public growthIncentiveTabList: any = [];
	// public exclusionTabList: any = [];
	public activitiesTabList: any = [];
	public ruleTabList: any[] = [];

	// Agreement tab drop down list select all checkbox object
	public isCustomerSearchTabAllSelected: boolean = false;
	public isProductSearchTabAllSelected: boolean = false;
	public isEndUsersSearchTabAllSelected: boolean = false;
	public isAllCustomerSelectedView: boolean = false;
	public isAllProductSelectedView: boolean = false;
	public isAllEndUsersSelectedView: boolean = false;
	public isViewIERuleRuleRate: boolean = true;
	public isViewBounsAmountCriterion: boolean = true;
	public isViewRateRuleCriterion: boolean = true;
	public isViewBonusRateCriterion: boolean = true;

	// Api call request object
	private dataInReqSubscription: Subscription;
	private dataOutReqSubscription: Subscription;
	private dataOutSalesPplReqSubscription: Subscription;

	// Model object
	public guid = new Guid();
	public commissionDetails = new CommissionContractTemplateModelUI();
	public commissionRequest = new CommissionContractTemplateModelRequest();

	public exclusionTabModel = new ExclusionTabModel();
	public ruleTabModel = new RuleTabModel();
	public listOfYears: any[] = [];
	public listOfSettlementPeriods: any[] = [];
	public listOfCalculationOnBasis: any[] = [];

	@ViewChild('contractCalendarSelectBoxRef') contractCalendarSelectBoxRef: DxSelectBoxComponent;
	//#endregion
	public decimalNumberFormat: string = '1.0-0';
	public listOfComparisonYear: any[] = [];
	public radioSalesOrderOrInvoiceList: any = [];
	public listOfWeekDays: any = [
		{
			text: "Sunday",
			value: "sunday",
			number: 7,
		},
		{
			text: "Monday",
			value: "monday",
			number: 1,
		},
		{
			text: "Tuesday",
			value: "tuesday",
			number: 2,
		},
		{
			text: "Wednesday",
			value: "wednesday",
			number: 3
		},
		{
			text: "Thursday",
			value: "thursday",
			number: 4
		},
		{
			text: "Friday",
			value: "friday",
			number: 5
		},
		{
			text: "Saturday",
			value: "saturday",
			number: 6
		},

	];
	public is_use_territory_for_commission_contract: boolean;

	public commission_rate_on_invoice: boolean = false;
	public enable_invoice_qualification_feature: boolean = false;
	public enable_commission_on_sales_orders_feature: boolean = false;
	public enable_profit_on_document_header: boolean = false;

	public enable_classification_for_commision: boolean = false;
	public isDisabledCalculationOnBasisType: boolean = false;
	public isDisabledCustomerOrItemMatch: boolean = false;
	public isDisabledTierType: boolean = false;
	public listOfCustomerItemMatch: any[] = [];
	public listOfTiersType: any[] = [];
	public listOfTierModes: any[] = [];
	public listOfTierAccrualPeriods: any[] = [];
	public listOfTiersInclude: any[] = [];
	public isShowTierAccrualPeriodField: boolean;
	public isShowTierAccrualPeriods: boolean;
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();
	public listOfOtherCurrency: any[] = [];
	public listOfCurrency: any[] = [];
	public enable_other_currencies: boolean = false;
	//#region Constructor
	constructor(private _Router: Router,
		private _ToastrService: ToastrService,
		private _IxDxAlertService: IxDxAlertService,
		private _PermissionsService: NgxPermissionsService,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _DataService: DataService,
		private _CommissionService: CommissionService,
		private ref: ChangeDetectorRef) { }

	//#endregion

	//#region Angular Life Cycle Methods
	ngOnInit() {
		this.editCommissionTemplateGuid = this._CommissionService.getEditCommissionTemplateGuid();
		this.doInitForCommissionDetailsTab();
		this.getDynamicYearsOfErpDataStartDate();
		if (this.commissionDetailsProps && this.commissionDetailsProps.currency_code) {
			this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption(this.commissionDetailsProps);
		} else {
			this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption();
		}
	}

	// tslint:disable-next-line:use-life-cycle-interface
	ngOnDestroy(): void {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
	}

	//#endregion

	//#region Commission Contract Comman Methods

	private doEnabledDisabledFields() {
		this.isDisabledCustomerOrItemMatch = false;
		this.isDisabledTierType = false;
		if (this.isDisablePermission || this.commission_rate_on_invoice || this.enable_profit_on_document_header) {
			this.isDisabledCustomerOrItemMatch = true;
			this.isDisabledTierType = true;
		}
		// disabled condtion for calculation basis type field
		this.isDisabledCalculationOnBasisType = false;
		if (this.isDisablePermission || this.enable_profit_on_document_header) {
			this.isDisabledCalculationOnBasisType = true;
		}
	}

	private doGetAllPermissions() {
		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		const permsn = [];
		for (const per in permissions) {
			permsn.push(per);
		}

		this.isDisablePermission = false;
		if (permsn.indexOf('INCENTIVE_COMMISSION_MODIFY') === -1) {
			this.isDisablePermission = true;
			this.isCurrentTabPermission = 'INCENTIVE_COMMISSION_MODIFY';
		}
	}

	// Init data on commission tab
	doInitForCommissionDetailsTab() {
		this.listOfCustomerItemMatch = this._CommissionService.getListOfCustomerOrItemMatch;
		this.listOfSettlementPeriods = this._CommissionService.getListOfSettlementPeriods;
		this.listOfCalculationOnBasis = this._CommissionService.getListOfCalculationBasis;
		this.listOfTiersType = this._CommissionService.getListOfTiers;
		this.listOfTierModes = this._CommissionService.getListOfTierModes;
		this.listOfTierAccrualPeriods = this._CommissionService.getListOfTierAccrualPeriods;
		this.listOfTiersInclude = this._CommissionService.getListOfTierInclude;
		// Check Edit Mode
		const editCommissionTemplateGuid = this._CommissionService.getEditCommissionTemplateGuid();
		this.doGetAllPermissions();
		if (editCommissionTemplateGuid) {
			this.commissionRequest.guid = editCommissionTemplateGuid;
			this.editCommissionTemplateGuid = editCommissionTemplateGuid;
			this.editCommissionContractObject();
		} else {
			this.addCommissionContractObject();
		}

		// get current user Erp type
		if (this._LoginService.loginUser && this._LoginService.loginUser.account_detail && this._LoginService.loginUser.account_detail.app_settings) {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
			this.is_use_territory_for_commission_contract = this._LoginService.loginUser.account_detail.app_settings.is_use_territory_for_commission_contract || false;
			this.commission_rate_on_invoice = this._LoginService.loginUser.account_detail.commission_rate_on_invoice || false;
			this.enable_invoice_qualification_feature = this._LoginService.loginUser.account_detail.enable_invoice_qualification_feature || false;
			this.enable_commission_on_sales_orders_feature = this._LoginService.loginUser.account_detail.commission_on_sales_orders || false;
			this.enable_profit_on_document_header = this._LoginService.loginUser.account_detail.enable_profit_on_document_header || false;
			this.doEnabledDisabledFields();

			this.listOfYears = this._CommissionService.getListOfYears;
			this.commissionDetails.comparison_year = this.listOfYears[0];
		}
		this.getLoginUserRoleList();
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_other_currencies')) {
			this.enable_other_currencies = this._LoginService.loginUser.account_detail.enable_other_currencies;
		}
		if (this.enable_other_currencies) {
			if (this._LoginService.loginUser.account_detail.currency_lst.length > 0) {
				this.listOfCurrency = JSON.parse(JSON.stringify(this._LoginService.loginUser.account_detail.currency_lst));
			}
			if (this._LoginService.loginUser.account_detail.other_currencies_lst.length > 0) {
				this.listOfOtherCurrency = JSON.parse(JSON.stringify(this._LoginService.loginUser.account_detail.other_currencies_lst));
			}
			this.addPrimaryCurrency();
		}
		setTimeout(() => {
			this.getSalesPplList();
		}, 20);

		this.GroupsList = this._DataService.getGroups();

		this.salespersonGridBoxColumns = [
			{ dataField: 'first_name', caption: 'First Name' },
			{ dataField: 'last_name', caption: 'Last Name' },
			{ dataField: 'role_name', caption: 'Role Name' },
		];
		this.radioCommissionTypeDataList = [
			{ value: 'standard', text: 'Standard' },
			{ value: 'growth_incentive', text: 'Growth Incentive' }
		];
		this.radioSalesOrderOrInvoiceList = this._CommissionService.getListOfCalculateCommissionBy;
		this.listOfContractCalendar = this._CommissionService.listOfContractCalendar || [];
		// Disable commission inputbox
		// this.disableCommissionInputFields();

		// check Is QB User (no need to view code of QB user)
		if (this._LoginService.loginUser.account_detail.app_settings.erp_type) {
			if (this._LoginService.loginUser.account_detail.app_settings.erp_type === ErpTypeValEnum.QUICKBOOKS) {
				this.isQBErpUser = true;
			}
			if (this._LoginService.loginUser.account_detail.app_settings.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
				this.isQBOnlineErpUser = true;
			}
		}
		this.enable_classification_for_commision = this._LoginService.loginUser.account_detail.enable_classification_for_commision
	}



	public doCommissionTypeValueChanged(event) {
		const newContractType = event.value;
		const currentContractType = this.commissionDetails.contract_type;
		if (currentContractType === newContractType) {
			return false;
		}
		let confirmMessage = '';
		if (currentContractType === 'standard') {
			if (this.enable_classification_for_commision) {
				confirmMessage = 'Your existing Customer(s) / Class(s) list and Rules will replaced.';
			} else if (this.is_use_territory_for_commission_contract) {
				confirmMessage = 'Your existing Territories(s) list and Rules will replaced.';
			} else {
				confirmMessage = 'Your existing Customer(s) / Item(s) list and Rules will replaced.';
			}
		} else if (currentContractType === 'growth_incentive') {
			confirmMessage = 'Your existing Growth Incentive list and Rules will replaced.';
		}

		const isItemAndGroupTab = this.exclusionTabList && this.exclusionTabList.length > 0;
		const isGrowthIncentiveTab = this.growthIncentiveTabList && this.growthIncentiveTabList.length > 0;
		const isRulesTab = this.ruleTabList && this.ruleTabList.length > 0;
		const isActivityTab = this.activitiesTabList && this.activitiesTabList.length > 0;

		if (isItemAndGroupTab || isRulesTab || isGrowthIncentiveTab || isRulesTab || isActivityTab) {
			const ixCustomDialogOptions: any = {
				popupIconsHtml: '<div class="confirmationIcon"><div class="infoIcon mrgT0"></div></div>',
				showTitle: false,
				subtitle: 'Change Contract Type?',
				message: 'Are you sure you want to continue? ' + confirmMessage,
			};
			this._IxDxAlertService.doShowDefaultConfirmAlert(ixCustomDialogOptions).then(result => {
				if (result) {
					setTimeout(() => {
						this.commissionDetails.contract_type = newContractType;
						if (this.commissionDetails.contract_type === 'growth_incentive') {
							this.currentAgreementTab = 'growth-incentive';
						}
						if (this.commissionDetails.contract_type === 'standard') {
							this.currentAgreementTab = 'exclusions';
						}
						this.doClearChildTabsData();
					}, 20);
				} else {
					this.commissionDetails.contract_type = '';
					setTimeout(() => {
						this.commissionDetails.contract_type = currentContractType;
						if (this.commissionDetails.contract_type === 'growth_incentive') {
							this.currentAgreementTab = 'growth-incentive';
						}
						if (this.commissionDetails.contract_type === 'standard') {
							this.currentAgreementTab = 'exclusions';
						}
					}, 20);
				}
			});
		} else {
			setTimeout(() => {
				this.commissionDetails.contract_type = newContractType;
				if (this.commissionDetails.contract_type === 'growth_incentive') {
					this.currentAgreementTab = 'growth-incentive';
				}
				if (this.commissionDetails.contract_type === 'standard') {
					this.currentAgreementTab = 'exclusions';
				}
			}, 20);
		}
	}

	public doClickCommissionBreadcurumb(name) {
		this._CommissionService.setAgreementMainTab(name);
		this._Router.navigate(['/contracts/commissionlist/']);
	}

	// Set default value on add mode
	addCommissionContractObject() {
		this.isAddMode = true;
		this.editCommissionTemplateGuid = '';
		this.commissionRequest.guid = '';
		this.currentAgreementTab = 'exclusions';
		this.commissionDetails.settlementPeriod = SettlementPeriodsValEnum.MONTHLY;
		this.commissionDetails.calculationOnBasis = 'grossprofit';
		this.commissionDetails.contract_type = 'standard';
		this.commissionDetails.calculate_commission_by = 'invoices';

		this.commissionDetails.tiers_type = 'single_items';
		this.commissionDetails.customer_item_match = 'exclusive';
		this.commissionDetails.tier_mode = 'absolute';
		this.setDefaultTiersInclude();
	}

	// Set default value on edit mode
	editCommissionContractObject() {
		this.editCommissionContract();
	}

	// Click agreement tab
	clickAgreementTab(currentAgreementTab) {
		switch (currentAgreementTab) {
			case 'exclusions':
				this.currentAgreementTab = 'exclusions';
				break;
			case 'activities':
				this.currentAgreementTab = 'activities';
				break;
			case 'growth-incentive':
				this.currentAgreementTab = 'growth-incentive';
				break;
			case 'rules':
				this.currentAgreementTab = 'rules';
				break;
		}
	}

	// Get agreement tab list data
	getAgreementTabListData() {

	}

	// Set agreement tab list data
	setAgreementTabListData() {
		if (this.commissionDetails.contract_type == 'growth_incentive') {
			this.currentAgreementTab = 'growth-incentive';
		} else {
			this.commissionDetails.contract_type = 'standard';
			this.currentAgreementTab = 'exclusions';
		}
	}

	// Disable input text after save commission
	disableCommissionInputFields() {
		if (this.editCommissionTemplateGuid) {
			this.isDisablePermission = true;
		} else {
			this.isDisablePermission = false;
		}
	}

	// Method used to clear Child Tab table data.
	private doClearChildTabsData() {
		this.exclusionTabList = [];
		this.growthIncentiveTabList = [];
		this.ruleTabList = [];
		this.activitiesTabList = [];
	}

	// Get current login user usertype role list
	getLoginUserRoleList() {
		this.loginUserRoleList = [];
		const userTypeList = this._LoginService.loginUser.account_detail.userType || [];
		if (userTypeList && userTypeList.length > 0) {
			const canReceiveIncentives: any = { can_receive_incentives: true };
			this.loginUserRoleList = _.map(
				_.where(userTypeList, canReceiveIncentives), function (utl) {
					return utl.user_type;
				});
		}
	}

	// Get sales people drop-list as per role
	getSalesPplList() {
		const searchParams: any = [];
		const rolesArr = this.loginUserRoleList || [];
		searchParams.push({ role: rolesArr });
		searchParams.push({ status: 'active' });
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.SUBUSERS);
		formData.append('search', JSON.stringify(searchParams));
		formData.append('is_dropdown', 'true');
		if (this.dataOutSalesPplReqSubscription) {
			this.dataOutSalesPplReqSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.dataOutSalesPplReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response.flag) {
					this.salesPplList = response.data || [];
					this.salesPersonDataSource = this.salesPplList;
					if (!this.editCommissionTemplateGuid && this.salesPplList && this.salesPplList.length > 0) {
						// this.commissionDetails.subuserguid = this.salesPplList[0].sub_account_id;
					}
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to Display Tier basis Name in uppercase
	public displayExprSalesPersonName(item) {
		if (!item) {
			return "";
		}
		const name = item.first_name + ' ' + item.last_name;
		return name.toString();
	}

	public onSalesPersonSelectionChanged(e) {
		if (e.name === "value") {
			this.isSalesPersonDropDownBoxOpened = false;
			this.ref.detectChanges();
		}
	}

	//#endregion

	//#region Settlement StartDate And EndDate Methods

	// Set Settlement Date
	setSettlementStartDate() {
		setTimeout(() => {
			if (!this.commissionDetails.startSettlementPeriodOn) {
				this.commissionDetails.startSettlementPeriodOn = "1";
			}
		}, 50);

		// this.calculateSettlementPeriod(this.commissionDetails);
	}

	// Calculate Settlement Period as per start date
	calculateSettlementPeriod(c, isClose?: boolean) {
		this.callViewSettlementPeriod(this.commissionRequest.guid, (response, data) => {
			this.closecontractsArray = data;
			if (c.startDate) {
				c.startDate = moment(c.startDate).format('YYYY-MM-DD');
				c.endDate = moment(c.endDate).format('YYYY-MM-DD');
				c.settlementPeriodStart = moment(c.settlementPeriodStart).format('YYYY-MM-DD');
				c.settlementPeriodEnd = moment(c.settlementPeriodEnd).format('YYYY-MM-DD');
				let e, s, d;
				let ssPeriodDay: any = 1; //start settlement period on
				let cc = this.commissionDetails.contractCalendar; // contract calendar [Fiscal Year,Calendar Year,Contract Year]
				let f: any = 1;// Fiscal Year month as of application configuration
				ssPeriodDay = this.commissionDetails.startSettlementPeriodOn ? parseInt(this.commissionDetails.startSettlementPeriodOn.toString()) : 1;

				cc = this.commissionDetails.contractCalendar;
				if (cc === 'fiscal_year') {
					f = this._LoginService.loginUser.account_detail.fiscal_year_start || 1;
				}
				if (!this.closecontractsArray.length) {  // no previous closed settlement periods
					c.settlementPeriodStart = c.startDate;
					s = c.startDate.split('-');
				} else {
					s = moment(this.closecontractsArray[this.closecontractsArray.length - 1].settlementPeriodEnd).format('YYYY-MM-DD').split('-');
					d = new Date(s[0], s[1] - 1, s[2]);
					if (new Date(d) < new Date(c.startDate)) {
						c.settlementPeriodStart = c.startDate;
						s = c.settlementPeriodStart.split('-');
					} else {
						d.setDate(d.getDate() + 1);
						c.settlementPeriodStart = moment(d).format('YYYY-MM-DD');
						s = c.settlementPeriodStart.split('-');
					}
				}
				if (cc === 'contract_year') {
					f = parseInt(s[1].toString()) || 1;
				}
				//#region New Settlement period calculate IX-1392
				if (c.settlementPeriod === SettlementPeriodsValEnum.MONTHLY) {
					e = new Date(s[0], s[1], ssPeriodDay);
				} else if (c.settlementPeriod === SettlementPeriodsValEnum.QUARTERLY) {
					d = s[1] - ((f - 1) % 3); // adjust for fiscal year start
					if (d < 0) {
						d = d + 12;   // months before fiscal year start are as if at end of year
					}
					d = d - (d % 3) + (d % 3 ? 3 : 0);   // end of current quarter
					d = d + ((f - 1) % 3);    // re-adjust for fiscal year start
					// e = new Date(s[0], s[1] - (s[1]%3) + (s[1]%3?3:0), 1);  //end of current quarter, measured from fiscal year start
					e = new Date(s[0], d, ssPeriodDay);

				} else if (c.settlementPeriod === SettlementPeriodsValEnum.HALFYEARLY) {
					d = s[1] - ((f - 1) % 6); // adjust for fiscal year start
					if (d < 0) {
						d = d + 12;   // months before fiscal year start are as if at end of year
					}
					d = d - (d % 6) + (d % 6 ? 6 : 0);   // end of current quarter
					d = d + ((f - 1) % 6);    // re-adjust for fiscal year start
					// e = new Date(s[0], s[1] - (s[1]%3) + (s[1]%3?3:0), 1);  //end of current quarter, measured from fiscal year start
					e = new Date(s[0], d, ssPeriodDay);

				} else if (c.settlementPeriod === SettlementPeriodsValEnum.YEARLY) {
					// tslint:disable-next-line: radix
					if (cc === 'fiscal_year') {
						d = s[1] - ((f - 1) % 12); // adjust for fiscal year start
						if (d < 0) {
							d = d + 12;   // months before fiscal year start are as if at end of year
						}
						d = d - (d % 12) + (d % 12 ? 12 : 0);   // end of current quarter
						d = d + ((f - 1) % 12);    // re-adjust for fiscal year start
						// e = new Date(s[0], s[1] - (s[1]%3) + (s[1]%3?3:0), 1);  //end of current quarter, measured from fiscal year start
						e = new Date(s[0], d, ssPeriodDay);
					} else {
						e = new Date(parseInt(s[0]) + 1, f - 1, ssPeriodDay);
					}
				} else if (c.settlementPeriod === SettlementPeriodsValEnum.WEEKLY) {
					if (!this.commissionDetails.startSettlementPeriodWeekOfDayOn) {
						this.commissionDetails.startSettlementPeriodWeekOfDayOn = this.listOfWeekDays[0].value;
					}
					let selectDayOfMonthText: any = moment(this.commissionDetails.settlementPeriodStart).format('dddd');
					const indexCD = this.listOfWeekDays.findIndex(item => item.text == selectDayOfMonthText);
					const indexOfWeekDay = this.listOfWeekDays.findIndex(item => item.value == this.commissionDetails.startSettlementPeriodWeekOfDayOn);
					const diffDay = this.listOfWeekDays[indexOfWeekDay].number - this.listOfWeekDays[indexCD].number;
					let addDay = 0;
					if (diffDay <= 0) {
						addDay = 7 + diffDay;
					} else {
						addDay = diffDay;
					}
					const date = moment(this.commissionDetails.settlementPeriodStart, "YYYY-MM-DD").add((addDay - 1), 'days');
					e = new Date(date.year(), date.month(), date.date());
				} else if (c.settlementPeriod === SettlementPeriodsValEnum.BIWEEKLY) {
					if (!this.commissionDetails.startSettlementPeriodWeekOfDayOn) {
						this.commissionDetails.startSettlementPeriodWeekOfDayOn = this.listOfWeekDays[0].value;
					}
					let selectDayOfMonthText: any = moment(this.commissionDetails.settlementPeriodStart).format('dddd');
					const indexCD = this.listOfWeekDays.findIndex(item => item.text == selectDayOfMonthText);
					const indexOfWeekDay = this.listOfWeekDays.findIndex(item => item.value == this.commissionDetails.startSettlementPeriodWeekOfDayOn);
					const diffDay = this.listOfWeekDays[indexOfWeekDay].number - this.listOfWeekDays[indexCD].number;
					let addDay = 0;
					if (diffDay <= 0) {
						addDay = 14 + diffDay;
					} else {
						addDay = diffDay + 7;
					}
					const date = moment(this.commissionDetails.settlementPeriodStart, "YYYY-MM-DD").add((addDay - 1), 'days');
					e = new Date(date.year(), date.month(), date.date());
				}
				else if (c.settlementPeriod === SettlementPeriodsValEnum.CUSTOM) {
					c.settlementPeriodEnd = c.endDate;
				}
				//#endregion

				if (c.settlementPeriod !== SettlementPeriodsValEnum.WEEKLY && c.settlementPeriod !== SettlementPeriodsValEnum.BIWEEKLY) {
					e.setDate(e.getDate() - 1); // last day of the prev month
				}
				const endDate = moment(c.endDate, "YYYY-MM-DD");
				const settlementPeriodEnd = moment(e, "YYYY-MM-DD");
				const diffDays = settlementPeriodEnd.diff(endDate, 'days');
				if (diffDays >= 0) {
					c.settlementPeriodEnd = c.endDate;
				} else {
					c.settlementPeriodEnd = moment(e).format('YYYY-MM-DD');
				}

				if (isClose) {
					// c.contractCode = this.guid.newGuid();
					c.startDate = moment(c.startDate).format('YYYY-MM-DD');
					c.endDate = moment(c.endDate).format('YYYY-MM-DD');
					c.settlementPeriodStart = moment(c.settlementPeriodStart).format('YYYY-MM-DD');
					c.settlementPeriodEnd = moment(c.settlementPeriodEnd).format('YYYY-MM-DD');

					const o = new Object;
					// tslint:disable-next-line:no-shadowed-variable
					for (const e in c) {
						o[e] = c[e];
					}
					o['excludedItems'] = this.exclusionTabList;
					o['growth_incentive'] = this.growthIncentiveTabList;
					o['activities'] = this.activitiesTabList;
					o['rules'] = this.ruleTabList;
					o['isSettlementPeriodClosed'] = true;
					o['allproducts'] = this.isAllProductSelectedView;
					o['allclients'] = this.isAllCustomerSelectedView;
					o['settelmentCalculatedByBackend'] = '';
					o['apInvoiceCreatedInERP'] = '';
					o['errorMessage'] = '';
					o['settlementValue'] = 0;
					o['isAPInvoicePaid'] = '';
					o['apInvoiceNumber'] = '';
					o['startDate'] = moment(o['startDate']).format('YYYY-MM-DD');
					o['endDate'] = moment(o['endDate']).format('YYYY-MM-DD');
					o['settlementPeriodStart'] = moment(o['settlementPeriodStart']).format('YYYY-MM-DD');
					o['settlementPeriodEnd'] = moment(o['settlementPeriodEnd']).format('YYYY-MM-DD');
					o['baseContractCode'] = c.guid;
					o['guid'] = this.guid.newGuid();

					// this.closecontractsArray.push(o);
					const contractArr = [];
					contractArr.push(o);
					//this.callSaveContractCloseSettlement(contractArr);
				} else {
					// if (this.editCommissionTemplateGuid) {
					// 	this.UpdateSettlementPeriodClick();
					// }
				}
			}
		});
	}

	//#endregion

	saveCommissionContract(isOtherSave?: boolean) {
		if (!this.commissionDetails.template_name) {
			this._ToastrService.info('Template Name is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (this.enable_other_currencies) {
			if (!this.commissionDetails.currency_code) {
				this._ToastrService.info('Currency is Required.', 'Info', { closeButton: true, tapToDismiss: true });
				return false;
			}
		}
		// if (!this.commissionDetails.subuserguid) {
		// 	this._ToastrService.info('Sales person is Required.', 'Info', { closeButton: true, tapToDismiss: true });
		// 	return false;
		// }

		if (this.commissionRequest.guid === undefined || this.commissionRequest.guid === '' || this.commissionRequest.guid === null) {
			this.commissionRequest.guid = this.guid.newGuid();
		}
		// this.commissionDetails.subuserguid = this.salesPersonGridBoxValue[0];
		// Get sub user name as per id
		// if (this.commissionDetails.subuserguid) {
		// 	const emp = this.salesPplList.filter(item => (item.sub_account_id).toString() === (this.commissionDetails.subuserguid).toString())[0];
		// 	this.commissionDetails.subusername = emp !== undefined ? emp.first_name + ' ' + emp.last_name || '' : '';
		// }

		this.commissionRequest.template_name = this.commissionDetails.template_name;
		// this.commissionRequest.subuserguid = this.commissionDetails.subuserguid;
		// this.commissionRequest.subusername = this.commissionDetails.subusername;
		this.commissionRequest.contract_type = this.commissionDetails.contract_type || '';
		this.commissionRequest.comparison_year = this.commissionDetails.comparison_year || '';
		// this.commissionRequest.startDate = moment(this.commissionDetails.startDate).format('YYYY-MM-DD');
		// this.commissionRequest.endDate = moment(this.commissionDetails.endDate).format('YYYY-MM-DD');
		this.commissionRequest.settlementPeriodStart = moment(this.commissionDetails.settlementPeriodStart).format('YYYY-MM-DD');
		this.commissionRequest.settlementPeriodEnd = moment(this.commissionDetails.settlementPeriodEnd).format('YYYY-MM-DD');
		this.commissionRequest.settlementPeriod = this.commissionDetails.settlementPeriod;
		this.commissionRequest.startSettlementPeriodOn = this.commissionDetails.startSettlementPeriodOn;
		this.commissionRequest.startSettlementPeriodWeekOfDayOn = this.commissionDetails.startSettlementPeriodWeekOfDayOn;
		this.commissionRequest.contractCalendar = this.commissionDetails.contractCalendar;
		this.commissionRequest.calculationOnBasis = this.commissionDetails.calculationOnBasis;
		this.commissionRequest.agreementBy = this.commissionDetails.agreementBy;
		this.commissionRequest.customer_items_groups = this.exclusionTabList;
		this.commissionRequest.growth_incentive = this.growthIncentiveTabList;
		this.commissionRequest.activities = this.activitiesTabList;
		this.commissionRequest.rules = this.ruleTabList;
		this.commissionRequest.is_active = true;
		this.commissionRequest.calculate_commission_by = this.commissionDetails.calculate_commission_by;
		this.commissionRequest.tiers_type = this.commissionDetails.tiers_type || 'single_items';
		this.commissionRequest.customer_item_match = this.commissionDetails.customer_item_match || 'exclusive';
		this.commissionRequest.tier_mode = this.commissionDetails.tier_mode || 'absolute';
		this.commissionRequest.tier_accrual_period = this.commissionDetails.tier_accrual_period;
		this.commissionRequest.tiers_include = this.commissionDetails.tiers_include || 'only_items_in_tier';
		if (this.enable_other_currencies) {
			this.commissionRequest.currency_code = this.commissionDetails.currency_code || null;
		}
		this.doShowHideTierAccrualPeriodsDropDown();

		const arryCcTemplates = [];
		arryCcTemplates.push(this.commissionRequest);
		this.callSaveCommissionContract(arryCcTemplates, isOtherSave);
	}

	//#region Call API Request

	callSaveCommissionContract(arryCcTemplates, isOtherSave?: boolean) {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('cctemplates', JSON.stringify(arryCcTemplates));
		this._LoaderService.show();
		this.dataInReqSubscription = this._RestApiService.doDataInFormDataReq(formData).subscribe({
			next: (response) => {
				if (response.flag) {
					//this.UpdateSettlementPeriodClick();
					if (!isOtherSave) {
						this._ToastrService.success(((!this.editCommissionTemplateGuid) ? ' Added' : ' Updated') + ' Successfully', 'Saved', { closeButton: true, tapToDismiss: true });
					}
					this.commissionRequest.ixcode = response.data.cctemplates[0].ixcode;
					this.commissionRequest.guid = response.data.cctemplates[0].guid;
					this.commissionDetails.guid = response.data.cctemplates[0].guid;
					this.editCommissionTemplateGuid = this.commissionRequest.guid;
					this.isAddMode = false;
					this._CommissionService.setEditCommissionTemplateGuid(this.editCommissionTemplateGuid);
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			}, error: (error) => {
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			}
		});
	}

	async callSaveContractCloseSettlement(arryContracts) {
		// tslint:disable-next-line: max-line-length
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('cctemplates', JSON.stringify(arryContracts));
		formData.append('contracttype', 'cctemplates');
		this.dataInReqSubscription = this._RestApiService.doDataInFormDataReq(formData).subscribe({
			next: (response) => {
				if (response.flag) {
					// this.currentcontracts.push(arryContracts[0]);
					// this.calculateSettlementPeriod(this.commissionDetails, false);
					// this.commissionDetails.current_settlement_invoice_total = '0';
					// this.commissionDetails.current_settlement_commission_total = '0';
					// this.closecontractsArray = [];
					this._ToastrService.success('Close and save settlement successfully', 'Saved', { closeButton: true, tapToDismiss: true });
				} else {
					// this._loaderService.hide();
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				// this._loaderService.hide();
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});

	}

	editCommissionContract() {
		let searchParams: any = [];
		searchParams.push({ 'guid': this.editCommissionTemplateGuid });
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.CCTEMPLATES);
		formData.append('search', JSON.stringify(searchParams));
		this._LoaderService.show();
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response.flag) {
					const editCommissionContractDetails = response.data[0];

					this.isAddMode = false;
					this.editCommissionTemplateGuid = editCommissionContractDetails.guid;

					this.commissionDetails.guid = editCommissionContractDetails.guid;
					this.commissionDetails.template_name = editCommissionContractDetails.template_name;
					// this.commissionDetails.subuserguid = editCommissionContractDetails.subuserguid;
					// this.commissionDetails.subusername = editCommissionContractDetails.subusername;
					this.commissionDetails.contract_type = editCommissionContractDetails.contract_type || '';
					this.commissionDetails.comparison_year = editCommissionContractDetails.comparison_year || '';
					// this.commissionDetails.startDate = moment(editCommissionContractDetails.startDate).format('YYYY-MM-DD');
					// this.commissionDetails.endDate = moment(editCommissionContractDetails.endDate).format('YYYY-MM-DD');
					this.commissionDetails.settlementPeriodStart = moment(editCommissionContractDetails.settlementPeriodStart).format('YYYY-MM-DD');
					this.commissionDetails.settlementPeriodEnd = moment(editCommissionContractDetails.settlementPeriodEnd).format('YYYY-MM-DD');
					this.commissionDetails.settlementPeriod = editCommissionContractDetails.settlementPeriod;
					this.commissionDetails.startSettlementPeriodOn = editCommissionContractDetails.startSettlementPeriodOn;
					this.commissionDetails.startSettlementPeriodWeekOfDayOn = editCommissionContractDetails.startSettlementPeriodWeekOfDayOn;
					this.commissionDetails.contractCalendar = editCommissionContractDetails.contractCalendar;
					this.commissionDetails.calculationOnBasis = editCommissionContractDetails.calculationOnBasis;
					this.commissionDetails.tiers_type = editCommissionContractDetails.tiers_type || 'single_items';
					this.commissionDetails.customer_item_match = editCommissionContractDetails.customer_item_match || 'exclusive';
					this.commissionDetails.tier_mode = editCommissionContractDetails.tier_mode || 'absolute';
					this.commissionDetails.tier_accrual_period = editCommissionContractDetails.tier_accrual_period;
					this.commissionDetails.tiers_include = editCommissionContractDetails.tiers_include || 'only_items_in_tier';
					if (this.enable_other_currencies) {
						this.commissionDetails.currency_code = editCommissionContractDetails.currency_code || null;
					}
					this.doShowHideTierAccrualPeriodsDropDown();
					this.commissionDetails.current_settlement_invoice_total = editCommissionContractDetails.current_settlement_invoice_total;
					this.commissionDetails.current_settlement_commission_total = editCommissionContractDetails.current_settlement_commission_total;
					this.commissionDetails.is_active = editCommissionContractDetails.is_active;
					this.commissionDetails.customer_items_groups = [];
					this.commissionDetails.growth_incentive = [];
					this.commissionDetails.rules = [];
					if (editCommissionContractDetails.customer_items_groups && editCommissionContractDetails.customer_items_groups.length > 0) {
						editCommissionContractDetails.customer_items_groups.forEach((i, index) => {
							i['rownum'] = index + 1;
						});
					}
					if (editCommissionContractDetails.growth_incentive && editCommissionContractDetails.growth_incentive.length > 0) {
						editCommissionContractDetails.growth_incentive.forEach((i, index) => {
							i['rownum'] = index + 1;
						});
					}
					this.exclusionTabList = editCommissionContractDetails.customer_items_groups || [];
					this.growthIncentiveTabList = editCommissionContractDetails.growth_incentive || [];
					this.activitiesTabList = editCommissionContractDetails.activities || [];
					this.ruleTabList = editCommissionContractDetails.rules;
					// this.salesPersonGridBoxValue = [this.commissionDetails.subuserguid];
					this.commissionDetails.calculate_commission_by = editCommissionContractDetails.calculate_commission_by || 'invoices';

					if (editCommissionContractDetails.allclients) {
						this.isAllCustomerSelectedView = true;
					}
					if (editCommissionContractDetails.allproducts) {
						this.isAllProductSelectedView = true;
					}
					// if (this.commissionDetails.startDate !== '' && this.commissionDetails.endDate !== '') {
					// this.calculateSettlementPeriod(this.commissionDetails);
					// }
					this.setAgreementTabListData();
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			}
		});
	}

	callViewSettlementPeriod(contractCode, callbackMethod: any) {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		// tslint:disable-next-line: max-line-length
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('baseContractCode', contractCode);
		formData.append('method', ServerMethods.GET_CLOSE_SETTLEMENTS);
		formData.append('contracttype', 'cctemplates');
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response.flag) {
					callbackMethod(true, response.data);
				} else {
					callbackMethod(false, []);
				}
			}, error: (error) => {
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				callbackMethod(false, []);
			}
		});
	}

	UpdateSettlementPeriodClick() {
		this._LoaderService.show();
		const arryContracts = [];
		// Get sub user name as per id
		// if (this.commissionDetails.subuserguid && !this.commissionDetails.subusername) {
		// 	const emp = this.salesPplList.filter(item => (item.sub_account_id).toString() === (this.commissionDetails.subuserguid).toString())[0];
		// 	this.commissionDetails.subusername = emp !== undefined ? emp.first_name + ' ' + emp.last_name || '' : '';
		// }

		this.commissionRequest.template_name = this.commissionDetails.template_name || '';
		this.commissionRequest.contract_type = this.commissionDetails.contract_type || '';
		this.commissionRequest.comparison_year = this.commissionDetails.comparison_year || '';
		// this.commissionRequest.subuserguid = this.commissionDetails.subuserguid;
		// this.commissionRequest.subusername = this.commissionDetails.subusername;
		// this.commissionRequest.startDate = moment(this.commissionDetails.startDate).format('YYYY-MM-DD');
		// this.commissionRequest.endDate = moment(this.commissionDetails.endDate).format('YYYY-MM-DD');
		this.commissionRequest.settlementPeriodStart = moment(this.commissionDetails.settlementPeriodStart).format('YYYY-MM-DD');
		this.commissionRequest.settlementPeriodEnd = moment(this.commissionDetails.settlementPeriodEnd).format('YYYY-MM-DD');
		this.commissionRequest.settlementPeriod = this.commissionDetails.settlementPeriod;
		this.commissionRequest.startSettlementPeriodOn = this.commissionDetails.startSettlementPeriodOn;
		this.commissionRequest.startSettlementPeriodWeekOfDayOn = this.commissionDetails.startSettlementPeriodWeekOfDayOn;
		this.commissionRequest.contractCalendar = this.commissionDetails.contractCalendar;
		this.commissionRequest.calculationOnBasis = this.commissionDetails.calculationOnBasis;
		this.commissionRequest.agreementBy = this.commissionDetails.agreementBy;
		this.commissionRequest.customer_items_groups = this.exclusionTabList;
		this.commissionRequest.growth_incentive = this.growthIncentiveTabList;
		this.commissionRequest.activities = this.activitiesTabList;
		this.commissionRequest.rules = this.ruleTabList;
		this.commissionRequest.is_active = this.commissionDetails.is_active;
		this.commissionRequest.calculate_commission_by = this.commissionDetails.calculate_commission_by;

		if (this.commissionRequest) {
			arryContracts.push(this.commissionRequest);
			const formData = new FormData();
			formData.append('usr', this._LoginService.loginUser.user);
			formData.append('token', this._LoginService.loginUser.token);
			formData.append('cctemplates', JSON.stringify(arryContracts));
			formData.append('contracttype', 'cctemplates');
			formData.append('method', ServerMethods.UPDATE_CURRENT_SETTELEMENT_PERIOD);
			if (this.dataOutReqSubscription) {
				this.dataOutReqSubscription.unsubscribe();
			}
			this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
				next: (response) => {
					this._LoaderService.hide();
					if (response.flag) {
						this.commissionDetails.current_settlement_invoice_total = response.data.current_settlement_invoice_total;
						this.commissionDetails.current_settlement_commission_total = response.data.current_settlement_commission_total;
						// this._ToastrService.success(response.message, 'Saved', { closeButton: true, tapToDismiss: true });
					} else {
						this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
					}
				}, error: (error) => {
					this._LoaderService.hide();
					console.error('error', error);
					this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				}
			});
		}
	}

	//#endregion
	// call from child exlusion page
	saveCommissionExclusionData(event) {
		this.saveCommissionContract();
	}

	saveGrowthIncentiveData(event) {
		this.saveCommissionContract();
	}

	saveCommissionActivitiesData(event) {
		this.saveCommissionContract();
	}

	saveCommissionRulesData(event) {
		this.saveCommissionContract();
	}

	// For Fetched List of Years.
	public fetchedListOfYears() {
		const year = new Date().getFullYear();
		const range = [];
		range.push(year);
		for (let i = 1; i < 5; i++) {
			range.push(year - i);
		}
		this.listOfYears = range;
		this.commissionDetails.comparison_year = this.listOfYears[0];
	}

	// Method used to set dynamic years
	public getDynamicYearsOfErpDataStartDate() {
		const erpDataStartDate = this._LoginService.loginUser.account_detail.app_settings.erp_data_start_date;
		const fiscalYearStart = this._LoginService.loginUser.account_detail.fiscal_year_start;
		this.listOfComparisonYear = [];
		if (erpDataStartDate && fiscalYearStart) {
			let monthList = this._CommissionService.getMonth();
			let erpDataStartDateYear: any = parseInt(moment(erpDataStartDate).format('YYYY').toString());
			let erpDataStartDateMonth: any = parseInt(moment(erpDataStartDate).format('MM').toString());

			let fiscalYearStartMonthText, fiscalYearEndMonthText;
			const indexMonth = monthList.findIndex(i => i.value === fiscalYearStart.toString());
			if (indexMonth !== -1) {
				fiscalYearStartMonthText = monthList[indexMonth].text;
				fiscalYearEndMonthText = indexMonth === 0 ? monthList[11].text : monthList[indexMonth - 1].text;
			}
			let startYear: number = erpDataStartDateYear;
			let endYear = new Date().getFullYear() - 1;

			// if (this.commissionDetails.startDate) {
			// 	const startDateMonth = moment(this.commissionDetails.startDate).format('MM');
			// 	const startDateYear = moment(this.commissionDetails.startDate).format('YYYY');
			// 	endYear = parseInt(startDateYear.toString()) - 1;
			// }
			const years = [];
			for (let i = endYear; i >= startYear; i--) {
				const val: any = i.toString() + ' (' + fiscalYearStartMonthText + ' ' + i + ' - ' + fiscalYearEndMonthText + ' ' + (i + 1) + ')'
				years.push({ text: val, value: i.toString() });
			}
			this.listOfComparisonYear = years;
		}
	}

	// #region for Tier Mode

	public doChangeTierMode(event: any) {
		if (event.value) {
			this.doShowHideTierAccrualPeriodsDropDown();
		}
	}

	private doShowHideTierAccrualPeriodsDropDown() {
		this.isShowTierAccrualPeriodField = false;
		if (this.commissionDetails.tier_mode === 'marginal') {
			this.isShowTierAccrualPeriodField = true;
			if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.CUSTOM) {
				this.isShowTierAccrualPeriodField = false;
			}
		}
		this.doHideShowTierAccrualPeriodsDropDownOptions();
	}

	private doHideShowTierAccrualPeriodsDropDownOptions() {
		if (this.commissionDetails.tier_mode === 'marginal') {
			let removedOptions = [];
			if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.BIWEEKLY) {
				removedOptions = [];
			}
			if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.WEEKLY) {
				removedOptions = [];
			}
			if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.MONTHLY) {
				removedOptions = [SettlementPeriodsValEnum.WEEKLY, SettlementPeriodsValEnum.BIWEEKLY];
			}
			if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.QUARTERLY) {
				removedOptions = [SettlementPeriodsValEnum.WEEKLY, SettlementPeriodsValEnum.BIWEEKLY, SettlementPeriodsValEnum.MONTHLY];
			}
			if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.HALFYEARLY) {
				removedOptions = [SettlementPeriodsValEnum.WEEKLY, SettlementPeriodsValEnum.BIWEEKLY, SettlementPeriodsValEnum.MONTHLY, SettlementPeriodsValEnum.QUARTERLY];
			}
			if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.YEARLY) {
				removedOptions = [SettlementPeriodsValEnum.WEEKLY, SettlementPeriodsValEnum.BIWEEKLY, SettlementPeriodsValEnum.MONTHLY, SettlementPeriodsValEnum.QUARTERLY, SettlementPeriodsValEnum.HALFYEARLY];
			}

			this.listOfTierAccrualPeriods.forEach(period => {
				const optionIndex = removedOptions.findIndex(opt => opt === period.value);
				if (optionIndex !== -1) {
					period.visible = false;
				} else {
					period.visible = true;
				}
			});
			this.setDefaultTierAccrualPeriod();
		}
	}

	private setDefaultTiersInclude() {
		if (this.isAddMode) {
			if (this.enable_profit_on_document_header) {
				this.commissionDetails.tiers_include = 'all_items_in_contract';
			} else {
				this.commissionDetails.tiers_include = 'only_items_in_tier';
			}
		}
	}

	private setDefaultTierAccrualPeriod() {
		if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.WEEKLY) {
			this.commissionDetails.tier_accrual_period = SettlementPeriodsValEnum.WEEKLY;
		} else if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.BIWEEKLY) {
			this.commissionDetails.tier_accrual_period = SettlementPeriodsValEnum.BIWEEKLY;
		} else if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.MONTHLY) {
			this.commissionDetails.tier_accrual_period = SettlementPeriodsValEnum.MONTHLY;
		} else if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.QUARTERLY) {
			this.commissionDetails.tier_accrual_period = SettlementPeriodsValEnum.QUARTERLY;
		} else if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.HALFYEARLY) {
			this.commissionDetails.tier_accrual_period = SettlementPeriodsValEnum.HALFYEARLY;
		} else if (this.commissionDetails.settlementPeriod === SettlementPeriodsValEnum.YEARLY) {
			this.commissionDetails.tier_accrual_period = SettlementPeriodsValEnum.YEARLY;
		} else {
			this.commissionDetails.tier_accrual_period = '';
		}
	}

	public doTiersTypeChanged(event: any) {
		const newTiersType = event.value;
		const currentTiersType = this.commissionDetails.tiers_type;
		if (currentTiersType === newTiersType) {
			return false;
		}
		let confirmMessage = '';
		if (this.commissionDetails.contract_type === 'standard') {
			if (this.enable_classification_for_commision) {
				confirmMessage = 'Your existing Customer(s) / Class(s) list and Rules will replaced.';
			} else if (this.is_use_territory_for_commission_contract) {
				confirmMessage = 'Your existing Territories(s) list and Rules will replaced.';
			} else {
				confirmMessage = 'Your existing Customer(s) / Item(s) list and Rules will replaced.';
			}
		} else if (this.commissionDetails.contract_type === 'growth_incentive') {
			confirmMessage = 'Your existing Growth Incentive list and Rules will replaced.';
		}

		const isItemAndGroupTab = this.exclusionTabList && this.exclusionTabList.length > 0;
		const isGrowthIncentiveTab = this.growthIncentiveTabList && this.growthIncentiveTabList.length > 0;
		const isRulesTab = this.ruleTabList && this.ruleTabList.length > 0;
		const isActivityTab = this.activitiesTabList && this.activitiesTabList.length > 0;

		if (isItemAndGroupTab || isRulesTab || isGrowthIncentiveTab || isRulesTab || isActivityTab) {
			const ixCustomDialogOptions: any = {
				popupIconsHtml: '<div class="confirmationIcon"><div class="infoIcon mrgT0"></div></div>',
				showTitle: false,
				subtitle: 'Change Contract Type?',
				message: 'Are you sure you want to continue? ' + confirmMessage,
			};
			this._IxDxAlertService.doShowDefaultConfirmAlert(ixCustomDialogOptions).then(result => {
				if (result) {
					setTimeout(() => {
						this.commissionDetails.tiers_type = newTiersType;
						this.doClearChildTabsData();
					}, 20);
				} else {
					this.commissionDetails.tiers_type = '';
					setTimeout(() => {
						this.commissionDetails.tiers_type = currentTiersType;
					}, 20);
				}
			});
		} else {
			setTimeout(() => {
				this.commissionDetails.tiers_type = newTiersType;
			}, 20);
		}

	}

	//#endregion
	//#region Other Currency

	public addPrimaryCurrency() {
		if (this._LoginService.loginUser.account_detail.app_settings.hasOwnProperty('currency_code')) {
			let currency_code = this._LoginService.loginUser.account_detail.currency_code;
			if (currency_code) {
				if (this.listOfCurrency && this.listOfCurrency.length > 0) {
					const currencyIndex = this.listOfCurrency.findIndex(currency => currency.currency_code === currency_code);
					if (currencyIndex !== -1) {
						if (this._LoginService.loginUser.account_detail.hasOwnProperty('erp_currency_name')) {
							this.listOfCurrency[currencyIndex]['erp_currency_name'] = this._LoginService.loginUser.account_detail.erp_currency_name;
						}
						this.listOfOtherCurrency.push(this.listOfCurrency[currencyIndex]);
					}
				}
			}
		}
	}

	public currencyLookUpContentReady(e) {
		e.component.content().classList.add("ix-currencyContent");
	}
	public displayExprForCurrency(currency: any) {
		return currency ? currency.currency_name + ' - ' + currency.currency_code + ' - ' + currency.currency_symbol : '';
		// return currency ? currency : '';
	}

	public doSelectionChangedForCurrency(e) {
		if (e.previousValue === undefined) {
			this.commissionDetails.currency_code = e.value;
		}
		if (e.previousValue !== undefined) {
			this.commissionDetails.currency_code = e.value;
			this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption(this.commissionDetails);
			if (this.editCommissionTemplateGuid) {
				this.saveCommissionContract();
				this.currentAgreementTab = 'rules';
				setTimeout(() => {
					this.currentAgreementTab = 'exclusions';
				});
			}
		}
	}

	//#endregion
}

