<dx-popup [width]="470" [height]="'auto'" [showTitle]="false" title="Task" [dragEnabled]="false"
	[hideOnOutsideClick]="false" [showCloseButton]="false" [(visible)]="popupVisible" [animation]="null"
	[wrapperAttr]="{ class: 'notificationPopupWrapper notificationPopupZindex' }">
	<dxo-position at="right top" my="right top" offset="-15 70"> </dxo-position>

	<div *dxTemplate="let data of 'content'" class="notificationPopup">
		<div class="taskNotificationPopup d-flex" *ngIf="isViewTaskNotification">
			<div class="icon-notification-icon-new notificationPopupIcon">
				<span class="notiCount">
				</span>
			</div>
			<span class="icon-Triangle"></span>
			<div class="width50p mrgT15">
				<h2>You have a new task! </h2>
				<h3>Do you want to see it now?</h3>
			</div>
			<div class="width50p mrgT15">
				<!-- <dx-button class="secondaryBtn button" text="Close" type="normal" (click)="doOnClosePopUp()">
				</dx-button> -->
				<dx-button class="primaryBtn button" text="See Now" type="normal" (click)="doOnSeeNowPopUp()">
				</dx-button>
			</div>
		</div>
		<div class="" *ngIf="isViewOtherNotification">
			<div class="icon-notification-icon-new notificationPopupIcon">
				<span class="notiCount"> </span>
			</div>
			<span class="icon-Triangle colorWhite"></span>
			<div class="pendingApprovalsNotificationPopup">
				<div class="icon-close-popup" (click)="doOnClosePopUp()"></div>
				<h2>You have pending approvals.</h2>
				<h3>Do you want to see them?</h3>
				<ul *ngFor="let nt of notificationTypesLst">
					<li *ngIf="(nt.isview && (nt.count > 0))" (click)="doOnNotificationTypeClick(nt)"><a>{{nt.name}}
							<span>{{(nt.count>10) ? '10+':nt.count}}</span></a></li>
				</ul>
			</div>
		</div>

	</div>

</dx-popup>