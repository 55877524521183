import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DATE_FORMAT_BACKEND, ServerMethods } from '@app/constants-enums/constants';
import { ApprovalTabsValEnum } from '@app/constants-enums/enums';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-approvals-tabs',
	templateUrl: './approvals-tabs.component.html',
	styleUrls: ['./approvals-tabs.component.css']
})
export class ApprovalsTabsComponent implements OnInit, OnDestroy {
	public currentTab: string;
	public isEnabledPricelistApprovalWorkflow: boolean;
	public isEnabledMinMaxClaimFeature: boolean;
	public isEnabledRebateSettlementApprovalWorkflow: boolean;
	public ApprovalTabsValEnum = ApprovalTabsValEnum;
	private getNewPendingNotificationsSbsn: Subscription;
	public pendingApprovalCount = {
		rebate: 0,
		pricelist: 0,
		minMaxEndUsersChargeback: 0,
		endUsersChargeback: 0
	};
	constructor(private _LoginService: LoginService,
		private _Router: Router,
		private _RestApiService: RestApiService,
		private _AppCommonSrvc: AppCommonSrvc) { }

	ngOnInit(): void {
		this.doCheckHideShowTabs();
		this.defaultTabSelections();
		this.getPendingApprovalCounts();
	}

	ngOnDestroy(): void {
		if (this.getNewPendingNotificationsSbsn) {
			this.getNewPendingNotificationsSbsn.unsubscribe();
		}
	}

	public doClickOnTab(currentTab: string) {
		if (this.currentTab === currentTab) {
			return;
		}
		this.currentTab = currentTab;
		this._AppCommonSrvc.setNotificationSubTab(this.currentTab);
		//note : do not change 
		switch (currentTab) {
			case ApprovalTabsValEnum.APPROVALS_CHARGEBACKS:
				this._Router.navigate(['/notifications/approvals/chargebacks']);
				break;
			case ApprovalTabsValEnum.APPROVALS_PRICELISTS:
				this._Router.navigate(['/notifications/approvals/pricelist']);
				break;
			case ApprovalTabsValEnum.APPROVALS_REBATES_SETTLEMENTS:
				this._Router.navigate(['/notifications/approvals/rebates_settlements']);
				break;
			case ApprovalTabsValEnum.APPROVALS_MIN_MAX_CHARGEBACKS:
				this._Router.navigate(['/notifications/approvals/min_max_chargebacks']);
				break;
			default:
				this._Router.navigate(['/notifications/approvals']);
		}
	}

	private defaultTabSelections() {
		let notificationTab = this._AppCommonSrvc.getNotificationSubTab();
		if (!notificationTab) {
			notificationTab = this.ApprovalTabsValEnum.APPROVALS_CHARGEBACKS;
		}
		this.doClickOnTab(notificationTab);
	}

	private doCheckHideShowTabs() {
		let isEnabledMinMaxClaim = false;
		let isEnabledPricelistLifeCycle = false;
		let isEnabledRebateSettlementApprovalWorkflow = false;
		try {
			isEnabledMinMaxClaim = this._LoginService.loginUser.account_detail.enable_min_max_claim;
			isEnabledPricelistLifeCycle = this._LoginService.loginUser.account_detail.enable_pricelist_approval_workflow;
			isEnabledRebateSettlementApprovalWorkflow = this._LoginService.loginUser.account_detail.enable_rebate_settlement_approval_workflow;
		} catch (e) {
			isEnabledMinMaxClaim = false;
			isEnabledPricelistLifeCycle = false;
			isEnabledRebateSettlementApprovalWorkflow = false;
		}
		this.isEnabledMinMaxClaimFeature = isEnabledMinMaxClaim;
		this.isEnabledPricelistApprovalWorkflow = isEnabledPricelistLifeCycle;
		this.isEnabledRebateSettlementApprovalWorkflow = isEnabledRebateSettlementApprovalWorkflow;
	}

	private getPendingApprovalCounts() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_NEW_PENDING_APPROVALS);

		if (this.getNewPendingNotificationsSbsn) {
			this.getNewPendingNotificationsSbsn.unsubscribe();
		}
		this.getNewPendingNotificationsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response && response.flag) {
					{
						this.pendingApprovalCount.rebate = response.data && response.data.hasOwnProperty('rebatescontracts') ? response.data.rebatescontracts.length : 0;
						this.pendingApprovalCount.pricelist = response.data && response.data.hasOwnProperty('pricelists') ? response.data.pricelists.length : 0;
						this.pendingApprovalCount.minMaxEndUsersChargeback = response.data && response.data.hasOwnProperty('minmaxenduserschargebackcontracts') ? response.data.minmaxenduserschargebackcontracts.length : 0;
						this.pendingApprovalCount.endUsersChargeback = response.data && response.data.hasOwnProperty('enduserschargebackcontracts') ? response.data.enduserschargebackcontracts.length : 0;
					}
				}
			},
			error: (error) => {

			}
		});
	}
}
