import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { LoginService } from '@app/services/login.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { Guid } from '@app/models/guid';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { CommissionContractModelUI } from '@app/models/commission-contract.model';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
import { ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { Subscription } from 'rxjs';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { ErpTypeValEnum } from '@app/constants-enums/enums';
import { CustomNumberNoRoundingPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';
import { CurrencyOptions } from '@app/models/common.model';

@Component({
	selector: 'app-commission-tierbase-customer-age-tiers-tab',
	templateUrl: './commission-tierbase-customer-age-tiers-tab.component.html',
	styleUrls: ['./commission-tierbase-customer-age-tiers-tab.component.css'],
	providers: [
		CustomCurrencyPipe,
		CustomNumberNoRoundingPipe
	]
})
export class CommissionTierbaseCustomerAgeTiersTabComponent implements OnInit, OnChanges, OnDestroy {
	public guid = new Guid();
	@Input() exclusionTabList: any = [];
	@Input() commissionDetailsProps: any;
	public globalCurrencySymbol: string;

	@ViewChild("tiersItemDetailssDataGridRef", {}) tiersItemDetailssDataGridRef: DxDataGridComponent;
	@ViewChild("subTiersItemDetailssDataGridRef", {}) subTiersItemDetailssDataGridRef: DxDataGridComponent;
	@Output() saveCommissionCustomerTiersEvent = new EventEmitter<string>();
	@Input() tiersData: any;
	@Input() customer_age_tiers: any;
	public listOfUoms: any[] = [];
	public listOfUomGroups: any[] = [];
	private getUomLstSbsn: Subscription;
	private getUomGroupLstSbsn: Subscription;
	public isQBOnlineErpUser: boolean = false;
	public enable_classification_for_commision: boolean = false;
	public enable_profit_on_document_header: boolean = false;
	public listOfCommissionBasis: any[] = [];
	public listOfTierBasis: any[] = [];
	public decimalPointPercentageFormat: string = '1.0-0';
	public commissionDetails: any = new CommissionContractModelUI();
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();
	constructor(private _LoginService: LoginService,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		private _CurrencyPipe: CurrencyPipe,
		private _CommissionService: CommissionService,
		private _RestApiService: RestApiService,
		private _DecimalPipe: DecimalPipe) {
		this.isValidRateCallback = this.isValidRateCallback.bind(this);
	}

	ngOnInit() {
		//console.log('tiersData >>> ', this.tiersData);
		this.decimalPointPercentageFormat = this._LoginService.decimalPointPercentageFormat;
		this.globalCurrencySymbol = this._CommissionService.globalCurrencySymbol;
		// get current user Erp type
		if (this._LoginService.loginUser && this._LoginService.loginUser.account_detail && this._LoginService.loginUser.account_detail.app_settings) {
		}
		const erpType = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		this.isQBOnlineErpUser = false;
		if (erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			this.isQBOnlineErpUser = true;
		}
		this.enable_classification_for_commision = this._LoginService.loginUser.account_detail.enable_classification_for_commision || false;
		this.enable_profit_on_document_header = this._LoginService.loginUser.account_detail.enable_profit_on_document_header || false;

		if (this.enable_classification_for_commision) {
			this.listOfTierBasis = this._CommissionService.getTierBasisForClasses();
			this.listOfCommissionBasis = this._CommissionService.getCommissionBasisForClasses();
		} else if (this.enable_profit_on_document_header) {
			this.listOfTierBasis = this._CommissionService.getTierBasisForProfitOnHeader();
			this.listOfCommissionBasis = this._CommissionService.getCommissionBasisForProfitOnHeader();
		} else {
			this.listOfTierBasis = this._CommissionService.getTierBasis();
			this.listOfCommissionBasis = this._CommissionService.getCommissionBasis();
		}
		this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption(this.commissionDetails);
	}

	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges['commissionDetailsProps']) {
			const commissionDetailsProps = _SimpleChanges['commissionDetailsProps'].currentValue;
			this.commissionDetails = commissionDetailsProps;
		}

		// if (_SimpleChanges['exclusionTabList']) {
		// 	const exclusionTabListProps = _SimpleChanges['exclusionTabList'].currentValue;
		// 	this.dataSourceExclusionTab = exclusionTabListProps || [];
		// }
	}

	ngOnDestroy() {
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
	}

	public getTiersByItemDetails(element) {
		if (!element['customer_age_tiers']) {
			element['customer_age_tiers'] = [];
		}
		// if (!element['customer_age_tiers']['tiers']) {
		// 	element['customer_age_tiers']['tiers'] = [];
		// }
		return element.customer_age_tiers || [];
	}
	public getSubTiersByItemDetails(element) {
		if (!element['data']['tiers']) {
			element['data']['tiers'] = [];
		}
		return element.data.tiers || [];
	}

	public doOnInitNewRowAgeItmTab(event, element) {
		const itemObject = element;
		event.data = {
			guid: null,
			minage: null,
			maxage: null,
			tiers: []
		};
		if (this.commissionDetails.tiers_type === 'multiple_items') {
			event.data.items = itemObject.items.map(item => {
				return item;
			});
		}

		event.data.guid = this.guid.newGuid();
		if (itemObject.customer_age_tiers && itemObject.customer_age_tiers.length > 0) {
			// const mintier = itemObject.tiers[itemObject.tiers.length - 1].maxtier !== '-' ? parseFloat(itemObject.tiers[itemObject.tiers.length - 1].maxtier) + 1 : parseFloat(itemObject.tiers[itemObject.tiers.length - 1].mintier) + 1;
			if (itemObject.tier_basis === 'volume' || itemObject.tier_basis === 'revenue') {
				if (itemObject.commission_basis === 'percentage') {
					// event.data.mintier = mintier;
				}
				if (itemObject.commission_basis === 'dollar') {
					// event.data.mintier = mintier;
				}
			}
			// event.data.maxtier = '-';
		} else {
			if (itemObject.tier_basis === 'volume') {
				if (itemObject.commission_basis === 'percentage') {
					// event.data.mintier = 1;
				}
				if (itemObject.commission_basis === 'dollar') {
					// event.data.mintier = 0.01;
				}
			}
		}
	}

	public doOnRowInsertedAgeItmTab(event, element) {
		// console.log('doOnRowInsertedTiersItmTab', event);
		// console.log('doOnRowInsertedTiersItmTab element', element);
		// const itemObject = event.row.data;
		// console.log('itemObject', itemObject);
		this.saveCommissionCustomerTiersEvent.emit('save');
	}

	public doOnEditorPreparingAgeItmTab(event, element) {
		// console.log('doOnEditorPreparingTiersItmTab', event);
		// console.log('doOnEditorPreparingTiersItmTab element', element);
	}

	public doOnRowUpdatedAgeItmTab(event, element) {
		// console.log('doOnRowUpdatedTiersItmTab', event);
		// console.log('doOnRowUpdatedTiersItmTab element', element);
	}

	public doOnRowRemovedAgeItmTab(event, element) {
		this.saveCommissionCustomerTiersEvent.emit('save');
		// console.log('doOnRowRemovedTiersItmTab', event);
		// console.log('doOnRowRemovedTiersItmTab element', element);
	}

	public doOnSavedAgeItmTab(event, element) {
		this.doConvertDecimalPointByConfiguration();
		if (this.tiersItemDetailssDataGridRef) {
			this.tiersItemDetailssDataGridRef.instance.refresh();
		}
		this.saveCommissionCustomerTiersEvent.emit('save');
	}

	private doConvertDecimalPointByConfiguration() {
		if (this.exclusionTabList && this.exclusionTabList.length > 0) {
			for (let i = 0; i < this.exclusionTabList.length; i++) {
				const exList = this.exclusionTabList[i];
				if (exList.commission_basis === 'percentage') {
					if (exList.tiers && exList.tiers.length > 0) {
						for (let j = 0; j < exList.tiers.length; j++) {
							const t = exList.tiers[j];
							if (t.rate !== null) {
								const rate = +(this._DecimalPipe.transform(t.rate, this.decimalPointPercentageFormat));
								t.rate = rate;
							}
						}
					}
					if (exList.hasOwnProperty('rate')) {
						if (exList.rate !== null) {
							exList.rate = +(this._DecimalPipe.transform(exList.rate, this.decimalPointPercentageFormat))
						}
					}
				}
			}
		}
	}

	//#region  Sub Tiers 

	public doOnInitNewRowsubTiersItmTab(event, element) {
		const itemObject = element.data;
		event.data = {
			guid: null,
			mintier: null,
			maxtier: null,
			rate: null
		};
		if (itemObject.items && itemObject.items.length > 0) {
			event.data.items = itemObject.items.map((item) => {
				let listOfSpecificUoms = [];
				if (!item.allproducts) {
					const priceListNum = itemObject.pricelistnum || '';
					listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, item, priceListNum);
				}
				let defaultSelectedUom = '';
				let defaultListPrice = 0;
				if (listOfSpecificUoms.length > 0) {
					defaultSelectedUom = listOfSpecificUoms[0].code || '';
					defaultListPrice = listOfSpecificUoms[0].price || 0;
				}
				return {
					allproducts: item.allproducts,
					allcustomergroups: item.allcustomergroups,
					mycustomers: item.mycustomers,
					erp_primary_key: item.erp_primary_key,
					itemType: item.itemType,
					itemname: item.itemname,
					itemsku: item.itemsku,
					description: item.description,
					selectedUom: !item.allproducts && itemObject.commission_basis === 'dollarperuom' ? defaultSelectedUom : '',
					rate: null,
					listPrice: +defaultListPrice.toFixed(2),
					commissionfinalprice: defaultListPrice,
				}
			});
		}
		event.data.guid = this.guid.newGuid();
		// if (itemObject.tiers && itemObject.tiers.length > 0) {
		// 	// const mintier = itemObject.tiers[itemObject.tiers.length - 1].maxtier !== '-' ?
		// 	parseFloat(itemObject.tiers[itemObject.tiers.length - 1].maxtier) + 1;
		// 	parseFloat(itemObject.tiers[itemObject.tiers.length - 1].mintier) + 1;
		// 	if (itemObject.tier_basis === 'volume' || itemObject.tier_basis === 'revenue') {
		// 		if (itemObject.commission_basis === 'percentage') {
		// 			// event.data.mintier = mintier;
		// 		}
		// 		if (itemObject.commission_basis === 'dollar') {
		// 			// event.data.mintier = mintier;
		// 		}
		// 	}
		// 	// event.data.maxtier = '-';
		// } else {
		// 	if (itemObject.tier_basis === 'volume') {
		// 		if (itemObject.commission_basis === 'percentage') {
		// 			// event.data.mintier = 1;
		// 		}
		// 		if (itemObject.commission_basis === 'dollar') {
		// 			// event.data.mintier = 0.01;
		// 		}
		// 	}
		// }
	}

	public doOnRowInsertedsubTiersItmTab(event, element) {
		// console.log('doOnRowInsertedsubTiersItmTab', event);
		// console.log('doOnRowInsertedsubTiersItmTab element', element);
		// const itemObject = event.row.data;
		// console.log('itemObject', itemObject);
		this.saveCommissionCustomerTiersEvent.emit('save');
	}

	public doOnEditorPreparingsubTiersItmTab(event, element) {
		// console.log('doOnEditorPreparingsubTiersItmTab', event);
		// console.log('doOnEditorPreparingsubTiersItmTab element', element);
	}

	public doOnRowUpdatedsubTiersItmTab(event, element) {
		// console.log('doOnRowUpdatedsubTiersItmTab', event);
		// console.log('doOnRowUpdatedsubTiersItmTab element', element);
	}

	public doOnRowRemovedsubTiersItmTab(event, element) {
		this.saveCommissionCustomerTiersEvent.emit('save');
		// console.log('doOnRowRemovedsubTiersItmTab', event);
		// console.log('doOnRowRemovedsubTiersItmTab element', element);
	}

	public doOnSavedsubTiersItmTab(event, element) {
		this.doConvertDecimalPointByConfiguration();
		if (this.subTiersItemDetailssDataGridRef) {
			this.subTiersItemDetailssDataGridRef.instance.refresh();
		}
		this.saveCommissionCustomerTiersEvent.emit('save');
	}

	//#endregion

	public setRateValue(subTiersData, element) {
		if (subTiersData.commission_basis === 'dollar' || subTiersData.commission_basis === 'dollarperuom') {
			return this._CommissionService.convertCurrency(element.data.rate, this.customCurrencyOption);
		}
		if (subTiersData.commission_basis === 'percentage') {
			return (this._DecimalPipe.transform(element.data.rate, this.decimalPointPercentageFormat)) + '%';
		}
	}

	// #region tof Fetch List of Uom(s)

	// Method used to get Uom List
	private fetchListOfUoms() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.UOM);
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name']));
		formData.append('is_dropdown', 'true');
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		this.getUomLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						this.listOfUoms = response.data;
					}
				}
			}, error: (error) => {
			}
		});
	}

	// Method used to get Uo Group List
	private fetchListOfUomGroups() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.UOMGROUPS);
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name', 'baseuom', 'uomgroupdefinitioncollection']));
		formData.append('is_dropdown', 'true');

		//this._LoaderService.show();
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
		this.getUomGroupLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				//	this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUomGroups = response.data;
					}
				}
			}, error: (error) => {
			}
		});
	}

	// #endregion

	public isValidRateCallback(params: any): boolean {
		var isValid = true;
		if (this.commissionDetails.tiers_type === 'single_items') {
			if (!params.value || (params.value && !params.value.toString().trim())) {
				isValid = false;
			}
		}
		return isValid;
	}

	// #region for Items Datagrid under Tiers Datagrid
	public getItemsByTiersDetails(element) {
		return element.data.items || [];
	}

	public doEditorPreparingItemTierItmTab(event, subParentData: any, parentData: any) {
		if (event && event['row']) {
			const itemObject = event.row.data;
			if (event.dataField === 'selectedUom') {
				event.editorOptions.visible = false;
				event.editorOptions.disabled = true;
				let uomDataSource = [];
				event.editorOptions.dataSource = uomDataSource;
				if (!itemObject.allproducts) {
					if (parentData.data.commission_basis === 'dollarperuom') {
						event.editorOptions.visible = true;
						event.editorOptions.disabled = false;
						uomDataSource = [];
						const itemIndex = parentData.data.items.findIndex(item => item.erp_primary_key === itemObject.erp_primary_key);
						if (itemIndex !== -1) {
							const selectedItemObject = parentData.data.items[itemIndex];
							uomDataSource = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, selectedItemObject);
						}
						event.editorOptions.dataSource = uomDataSource;
					} else {
						event.editorOptions.visible = false;
						event.editorOptions.disabled = true;
						event.editorOptions.dataSource = [];
					}
				}
			}

			// if (event.dataField === 'tier_basis') {
			// 	let listOfTierBasis = this.listOfTierBasis;
			// 	event.editorOptions.dataSource = listOfTierBasis;
			// }

			if (event.dataField === 'commission_basis') {
				let listOfCommissionBasis = this.listOfCommissionBasis;
				if (itemObject.allproducts) {
					listOfCommissionBasis = this.listOfCommissionBasis.filter(rb => rb.code === 'dollar' || rb.code === 'percentage');
				}

				if (itemObject['itemType']) {
					if (itemObject['itemType'] === 'OtherCharge' || itemObject['itemType'] === 'Service') {
						listOfCommissionBasis = this.listOfCommissionBasis.filter(rb => rb.code === 'dollar' || rb.code === 'percentage');
					}
				}
				event.editorOptions.dataSource = listOfCommissionBasis;
			}

			if (event.dataField === 'rate') {
				event.editorOptions.visible = true;
				if (itemObject.allproducts) {
					if (parentData.data.tier_basis === 'notiers') {
						event.editorOptions.visible = false;
					}
				} else {
					if (parentData.data.tier_basis === 'notiers') {
						event.editorOptions.visible = false;
					}
				}
			}
		}
	}

	public doRowInsertedItemTierItmTab(event, subParentData: any, parentData: any) {
		this.saveCommissionCustomerTiersEvent.emit('save');
	}
	public doRowRemovedItemTierItmTab(event, subParentData: any, parentData: any) {
		this.saveCommissionCustomerTiersEvent.emit('save');
	}

	public doRowUpdatedItemTierItmTab(event, subParentData: any, parentData: any) {
	}

	public doOnSavedItemTierItmTab(event, subParentData: any, parentData: any) {
		this.doConvertDecimalPointByConfiguration();
		this.saveCommissionCustomerTiersEvent.emit('save');
	}

	public setCellValueUomColumn(rowData, value) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	// Method used to Display Uom Name in uppercase
	public getDisplayExprUomName(item) {
		if (!item) {
			return "";
		}
		return item.name.toString().toUpperCase();
	}

}
