<div class="borderBox padT20 padB20">
	<div class="row">
		<div class="cols8">
			<h4 class="textandlableix line-height-normal mrgB10"><span class="textlabelix">Rebate Report:</span>
				{{rebateRptDetails.contractName}}</h4>
			<!-- <p class="contTx mrgB0"><span class="lbl">Buying Group:</span><span class="black">
					{{rebateRptDetails.name}}</span></p> -->
			<p class="contTx mrgB0"><span class="lbl">Settlement Period:</span><span class="black">
					{{rebateRptDetails.settlementPeriodStart | date: 'yyyy-MM-dd'}} to
					{{rebateRptDetails.settlementPeriodEnd | date: 'yyyy-MM-dd'}}</span></p>
		</div>
		<div class="cols4 d-flex">
			<div class="flex-grow-1">
				<p class="contTx mrgB10 d-block txtRight mrgR0"
					*ngIf="rebateRptDetails && rebateRptDetails.creditMemoCreatedInERP">
					<span class="lbl">Status:</span><span class="inStatusLabel mrgL10" [ngClass]="rebateSatusUIClass">
						{{rebateRptDetails.creditMemoCreatedInERP || '' |
						filterFromList:listOfSettlementStatuses : 'value' : 'text'}}
					</span>
				</p>
				<p class="contTx mrgB10 d-block txtRight mrgR0" *ngIf="isShowApprovalStatusColumn">
					<span class="lbl">Approval Status:</span><span class="inStatusLabel mrgL10"
						[ngClass]="rebateRptDetails.approval_status || '' | filterFromList:listOfApprovalStatuses : 'value' : 'classname'">
						{{rebateRptDetails.approval_status || '' |
						filterFromList:listOfApprovalStatuses : 'value' : 'text'}}
					</span>
				</p>
				<p class="contTx mrgB0 d-block txtRight mrgR0">
					<span class="lbl">Generated:</span><span class="black"> {{rebateRptDetails.created_date | date:
						'yyyy-MM-dd'}}</span>
				</p>
			</div>
			<!-- <button class="mrgL30">Action</button> -->
			<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false" [showArrowIcon]="false"
				text="Action" class="mrgL30 secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx btnHeightBig"
				[items]="listOfActions" [wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name"
				keyExpr="btn_code" icon="spindown" (onButtonClick)="doActionsBtnClick($event)"
				(onItemClick)="doActionsBtnItemClick($event)" [dropDownOptions]="{ width: 270 }">
			</dx-drop-down-button>
		</div>
	</div>
	<hr class="hrborderix mrgT10 mrgB15" />
	<div class="d-flex d-flex-center">
		<div class="flex-grow-1">
			<div class="padR30 alignLeft" *ngFor="let item of rebatesRptTabs">
				<span class="labelTextIx d-block line-height-normal">{{item.title}}</span>
				<span class="textlabelix">
					<!-- <dx-load-indicator height="25" width="25" class="button-indicator"
						[elementAttr]="{ class: 'button-loader-indicator-blue' }"></dx-load-indicator> -->
					<span class="black semiBold">{{item.value|| 0
						|customCurrencyPipe:customCurrencyOption}}</span>
				</span>
			</div>
		</div>
		<!-- <div class="greenboxix">
			<div class="greenboxinnerix padR30">
				<span class="labelTextIx d-block line-height-normal">Total Rebate Payable</span>
				<span class="textlabelix black semiBold">{{rebateRptDetails.totalRebatePayable || 0 |
					customCurrencyPipe:customCurrencyOption }}</span>
			</div>
			<div class="greenboxinnerix">
				<span class="labelTextIx d-block line-height-normal">Total Rebate Generated</span>
				<span class="textlabelix black semiBold">{{rebateRptDetails.totalRebateGenerated || 0 |
					customCurrencyPipe:customCurrencyOption }}</span>
			</div>
		</div> -->
	</div>
</div>
<div class="borderBox padB20">
	<dx-data-grid #itemsInvoicesDataSourceRef id="gridContainer" [dataSource]="itemsInvoicesDataSource"
		[wordWrapEnabled]="true" [hoverStateEnabled]="true" [showBorders]="true"
		(onToolbarPreparing)="doToolbarPreparing($event)" (onInitialized)="doInitializedItemInvoiceReport($event)"
		(onCellClick)="doCellClickForrebateRptDetails($event)" [filterSyncEnabled]="true" [filterValue]="filterValue"
		[showColumnLines]="true" [showRowLines]="false" [allowColumnReordering]="true" [allowColumnResizing]="true"
		[columnAutoWidth]="true" [width]="'100%'" class="cellColor hoverClr hideFilterPanelInx">
		<dxo-scrolling columnRenderingMode="virtual" [scrollByThumb]="true" [showScrollbar]="'always'"
			[useNative]="false"></dxo-scrolling>
		<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
		<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
			[showInfo]="true">
		</dxo-pager>
		<dxo-filter-row [visible]="true"></dxo-filter-row>
		<dxo-filter-panel [visible]="true"></dxo-filter-panel>
		<dxo-group-panel [visible]="true"></dxo-group-panel>
		<dxo-header-filter [visible]="true" [showRelevantValues]="true"></dxo-header-filter>
		<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
		<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadInvoiceState"
			[customSave]="doSaveInvoiceState" [storageKey]="stateStorageKey">
		</dxo-state-storing>
		<div *dxTemplate="let data of 'templateFilterText'">
			<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
				<span>{{filterText}}</span>
			</div>
		</div>

		<div *dxTemplate="let data of 'templateSettlementPeriod'">
			<div class="mrgR10">
				<span class="highlight pointerMark">
					Settlement Period : {{rebateRptDetails.settlementPeriodStart | date: 'yyyy-MM-dd'}} To
					{{rebateRptDetails.settlementPeriodEnd | date: 'yyyy-MM-dd'}}
				</span>
			</div>
		</div>

		<div *dxTemplate="let data of 'templateActionBtns'">
			<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false" [showArrowIcon]="false"
				text="Action" class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActionsGrid"
				[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code" icon="spindown"
				[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
		</div>

		<dxi-column dataField="clientCode" dataType="string" caption="Customer Code" [allowHeaderFiltering]="false"
			cellTemplate="cellTemplateClientCode">
			<div *dxTemplate="let element of 'cellTemplateClientCode'">
				{{ element.data.clientCode || '' }}
			</div>
		</dxi-column>

		<dxi-column dataField="clientName" dataType="string" [allowSorting]="true" caption="Customer Name"
			[allowHeaderFiltering]="false">
		</dxi-column>

		<dxi-column dataField="itemsku" dataType="string" caption="SKU" [visible]="isQBOnlineErpUser"
			[allowHeaderFiltering]="false" cellTemplate="cellTemplateItemsku">
			<div *dxTemplate="let element of 'cellTemplateItemsku'">
				{{ element.data.itemsku || '' }}
			</div>
		</dxi-column>

		<dxi-column dataField="itemname" dataType="string" caption="Item" cellTemplate="cellTemplateItemname"
			[allowHeaderFiltering]="false">
			<div *dxTemplate="let element of 'cellTemplateItemname'">
				{{ element.data.itemname }}
			</div>
		</dxi-column>

		<dxi-column dataField="description" dataType="string" caption="Description" cellTemplate="cellTemplateDescription"
			[allowHeaderFiltering]="false" [width]="330">
			<div *dxTemplate="let element of 'cellTemplateDescription'">
				{{ element.data.description || ''}}
			</div>
		</dxi-column>

		<dxi-column dataField="tier_basis" dataType="string" caption="Rebate Type" cellTemplate="cellTemplateTierBasis"
			[allowFiltering]="false" [allowHeaderFiltering]="true" [allowSorting]="true">
			<dxo-header-filter [dataSource]="listOfTierBasisHeaderFilter">
			</dxo-header-filter>
			<div *dxTemplate="let element of 'cellTemplateTierBasis'">
				{{element.data.tier_basis || '' | filterFromList:listOfTierBasis : 'code' : 'label' | uppercase}}
			</div>
		</dxi-column>

		<dxi-column dataField="rebate_basis" dataType="string" caption="Rebate Basis"
			cellTemplate="cellTemplateRebateBasis" [allowFiltering]="false" [allowHeaderFiltering]="true"
			[allowSorting]="true">
			<dxo-header-filter [dataSource]="listOfRebateBasisHeaderFilter">
			</dxo-header-filter>
			<div *dxTemplate="let element of 'cellTemplateRebateBasis'">
				{{element.data.rebate_basis || '' | filterFromList:listOfRebateBasis : 'code' : 'label' |
				uppercase}}
			</div>
		</dxi-column>

		<dxi-column dataField="commissionRate" dataType="number" [caption]="'Rebate Rate (% | '+ globalCurrencySymbol+')'"
			alignment="center" [allowHeaderFiltering]="false" cellTemplate="cellTemplateCommitionsRate">
			<div *dxTemplate="let element of 'cellTemplateCommitionsRate'">
				<span *ngIf="element.data.commissionRate">
					<span *ngIf="element.data.commissionRateIn === '$'">
						{{element.data.commissionRate || 0 | customCurrencyPipe:customCurrencyOption}}
					</span>
					<span *ngIf="element.data.commissionRateIn === '%'">
						{{element.data.commissionRate + element.data.commissionRateIn}}
					</span>
				</span>
			</div>
		</dxi-column>

		<dxi-column dataField="appliedTier" dataType="string" caption="Tier" alignment="center"
			[allowHeaderFiltering]="false" cellTemplate="cellTemplateChoosenTier">
			<div *dxTemplate="let element of 'cellTemplateChoosenTier'">
				<span *ngIf="element.data.appliedTier">
					{{element.data.appliedTier}}
				</span>
			</div>
		</dxi-column>

		<dxi-column dataField="document_type" dataType="string" [allowSorting]="true" caption="Doc. Type"
			[allowFiltering]="false" [allowHeaderFiltering]="true" cellTemplate="cellTemplateDocType">
			<dxo-header-filter [dataSource]="listOfDocTypeHeaderFilter">
			</dxo-header-filter>
			<div *dxTemplate="let element of 'cellTemplateDocType'">
				{{element.data.document_type | filterFromList:listOfDocTypeHeaderFilter : 'value' : 'text' }}
			</div>
		</dxi-column>

		<dxi-column dataField="invoiceCode" dataType="string" [allowSorting]="true" caption="Doc. No"
			[allowHeaderFiltering]="false" cellTemplate="cellTemplateInvoiceCode">
			<div *dxTemplate="let element of 'cellTemplateInvoiceCode'">
				<span class="highlight pointerMark">{{element.data.invoiceCode}}</span>
			</div>
		</dxi-column>
		<dxi-column dataField="invoiceDate" [allowHeaderFiltering]="false" caption="Invoice Date"
			cellTemplate="cellTemplateInvoiceDate" dataType="date" format="yyyy-MM-dd">
			<div *dxTemplate="let element of 'cellTemplateInvoiceDate'">
				{{element.data.invoiceDate ||''}}
			</div>
		</dxi-column>
		<dxi-column dataField="cardcode" [visible]="isShowCardcodeColumn" dataType="string" [allowSorting]="true"
			caption="Line Item Customer Code" [showInColumnChooser]="isShowCardcodeColumn" [allowHeaderFiltering]="false">
		</dxi-column>

		<dxi-column dataField="uomcode" dataType="string" caption="Doc. UOM" cellTemplate="cellTemplateUom"
			[allowFiltering]="false" [allowHeaderFiltering]="true" [allowSorting]="true">
			<dxo-header-filter [dataSource]="listOfUomsHeaderFilter"> </dxo-header-filter>
			<div *dxTemplate="let element of 'cellTemplateUom'">
				{{element.data.uomcode || '' | filterFromList:listOfUoms : 'code' : 'name' | uppercase }}
			</div>
		</dxi-column>

		<dxi-column dataField="quantity" dataType="string" caption="Doc. Qty" dataType="number"
			cellTemplate="cellTemplateQuantity" [allowHeaderFiltering]="false">
			<div *dxTemplate="let element of 'cellTemplateQuantity'">
				{{element.data.quantity || 0}}
			</div>
		</dxi-column>

		<!-- <dxi-column dataField="settlementValue" caption="Rebate Amount" dataType="number"
			[calculateDisplayValue]="getCurrencyDisplayValue" [allowHeaderFiltering]="false">
		</dxi-column> -->

		<dxi-column dataField="linetotal" dataType="number" [caption]="'Line Total ('+globalCurrencySymbol+')'"
			[allowHeaderFiltering]="false" [calculateDisplayValue]="getCurrencyDisplayValue">
			<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency"> </dxo-format> -->
		</dxi-column>


		<dxi-column dataField="miscdeductioninventory" caption="Misc. Deduction (%)" dataType="number"
			[visible]="rebateRptDetails.rebate_for === 'individual' && (rebateRptDetails.rebateType == 'sales' || rebateRptDetails.rebateType == 'purchasing')"
			cellTemplate="cellTemplateMiscDeduction" [allowHeaderFiltering]="false">
			<div *dxTemplate="let element of 'cellTemplateMiscDeduction'">
				{{element.data.miscdeductioninventory || 0 | customNumberNoRoundingPipe }}%
			</div>
		</dxi-column>

		<dxi-column dataField="miscDedAmount" [caption]="'Misc. Deduction Amount ('+globalCurrencySymbol+')'"
			dataType="number" [calculateDisplayValue]="getCurrencyDisplayValue"
			[visible]="rebateRptDetails.rebate_for === 'individual' && (rebateRptDetails.rebateType == 'sales' || rebateRptDetails.rebateType == 'purchasing')"
			[allowHeaderFiltering]="false">
			<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency"> </dxo-format> -->
		</dxi-column>

		<dxi-column dataField="finalAmount" [caption]="'Final Line Total ('+globalCurrencySymbol+')'" dataType="number"
			[calculateDisplayValue]="getCurrencyDisplayValue"
			[visible]="rebateRptDetails.rebate_for === 'individual' && (rebateRptDetails.rebateType == 'sales' || rebateRptDetails.rebateType == 'purchasing')"
			[allowHeaderFiltering]="false">
			<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency"> </dxo-format> -->
		</dxi-column>
		<dxo-summary>
			<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat="{0}"
				[customizeText]="doCustomizeTotalSummary">
				<!-- <dxo-value-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-value-format> -->
			</dxi-total-item>
			<dxi-total-item column="quantity" summaryType="sum" displayFormat="{0}">
			</dxi-total-item>
			<dxi-total-item column="finalAmount" summaryType="sum" displayFormat="Total: {0}"
				[customizeText]="doCustomizeTextTotalSummary">
				<!-- <dxo-value-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-value-format> -->
			</dxi-total-item>
			<!-- <dxi-total-item column="settlementValue" summaryType="sum" displayFormat="Total: {0}"
				[customizeText]="doCustomizeSubTotalSummary">
			</dxi-total-item> -->
		</dxo-summary>
	</dx-data-grid>
</div>


<app-view-invoice-detail-popup *ngIf="isShowInvoiceDetailPopUp" [viewInvoiceDetailProps]="viewInvoiceDetailProps">
</app-view-invoice-detail-popup>

<app-view-end-users-invoice-detail-popup *ngIf="isShowEndUserInvoiceDetailPopUp"
	[viewEndusersInvoiceDetailProps]="viewEndUserInvoiceDetailProps">
</app-view-end-users-invoice-detail-popup>