import * as _ from 'underscore';
import { Injectable } from '@angular/core';
import { DataService } from '@app/services/data.service';
import { ErpTypeValEnum, RoyaltiesSettlementReportSectionEnum } from '@app/constants-enums/enums';
import { LoginService } from '@app/services/login.service';
import { IMG_ADD_FILE_SVG, IMG_ICON_CLOSE_MULTIPLE_SETTLEMENT, IMG_ICON_COLUMN_CHOOSER, IMG_ICON_EXPORT_PDF, IMG_ICON_REFRESH, IMG_ICON_UPDATE_CURRENT_SETTLEMENT, IMG_ICON_XLSXFILEPOPUPIX, ServerSections } from '@app/constants-enums/constants';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { RoyaltiesSettlemntRptModel } from '@app/models/royalties-contract.model';
import { CurrencyModel, CurrencyOptions } from '@app/models/common.model';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
@Injectable({
	providedIn: 'root'
})
export class RoyaltiesService {
	public editRoyaltiesGuid: string;
	public invoiceDetails: any;
	public itemGridBoxColumns: any;
	public customerGridBoxColumns: any;
	public erpType: string;
	public productSearchItemType: string;
	public globalCurrencySymbol: string;
	public SettlementPeriodRadio: any[] = [
		{ text: "Monthly", value: "monthly" },
		{ text: "Quarterly", value: "quarterly" },
		{ text: "Bi-annually", value: "halfYearly" },
		{ text: "Annually", value: "yearly" }
	];
	public percentOrDollarRadio: any[] = [
		{ text: "Dollar", value: "dollar" },
		{ text: "Percentage", value: "percentage" },
	];
	public listOfRuleBonusTypes: any[] = [
		{ text: 'Inclusion/Exclusion Rule', value: 'inclusionExclusionRule' },
		{ text: 'Bonus Rate', value: 'bonusRate' },
		{ text: 'Rate Rule', value: 'rateRule' },
	];
	public listOfInclusionExclusionRuleConditions: any[] = [
		{ text: 'Include in Calculation', value: 'include' },
		{ text: 'Exclude in Calculation', value: 'exclude' },
	];
	public listOfRateRuleConditions: any[] = [
		{ text: 'Miscellaneous Deduction - Inventory Item', value: 'miscellaneousDeductionInventory' },
	];
	public listOfBonusRateConditions: any[] = [
		{ text: 'New Account', value: 'newAccount' },
		{ text: 'Total Per Settlement Period Greater Than', value: 'totalPerSettlementPeriodGreaterThan' },
		{ text: 'Gross Profit Per Settlement Period Greater Than', value: 'grossProfitPerSettlementPeriod' },
		{ text: 'Number of Unique SKUs Greater Than', value: 'numberOfuniqueSKUsGreaterThan' },
		{ text: 'No. SKUs Shipped In Settlement Period Greater Than', value: 'SKUsShippedInSettlementPeriod' },
	];
	public listOfInclusionExclusionRuleCriterions: any[] = [
		{ text: 'Unpaid Invoices ', value: 'unpaidInvoices' },
		{ text: 'Billbacks / Chargebacks', value: 'billbacksChargebacks' },
		{ text: 'Cash Discounts', value: 'cashDiscounts' },
		{ text: 'Shipping Costs', value: 'shippingCosts' },
	];
	public listOfExclusionsForBuyingGroup: any[] = [
		{ text: 'Exclusion by Items', value: 'item' },
		{ text: 'Exclusion by Groups', value: 'group' },
	];
	public listOfContractCalendar: any[] = [
		{ text: 'Fiscal Year', value: 'fiscal_year' },
		{ text: 'Calender Year', value: 'calender_year' },
		{ text: 'Contract Year', value: 'contract_year' },
	];
	public totalCountOfSettlementReports: number;
	public decimalPointForCurrency: number;
	public royaltiesRptDetails: RoyaltiesSettlemntRptModel = new RoyaltiesSettlemntRptModel();
	constructor(
		private _DataService?: DataService,
		private _LoginService?: LoginService,
		public _AppCommonSrvc?: AppCommonSrvc,
		public _CustomCurrencyPipe?: CustomCurrencyPipe,
	) {
		this.globalCurrencySymbol = this._LoginService.globalCurrencySymbol;
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
	}

	getEditRoyaltiesGuid() {
		return this.editRoyaltiesGuid;
	}
	setEditRoyaltiesGuid(val: any) {
		this.editRoyaltiesGuid = val;
	}
	getInvoiceDetails() {
		return this.invoiceDetails;
	}
	setInvoiceDetails(val: any) {
		this.invoiceDetails = val;
	}

	public getlistOfUomByItemDetails(listOfUoms: any[], listOfUomGroups: any[], itemDetails: any, priceListNum?: string) {
		let listOfSpecificUOM = [];
		if (itemDetails) {
			if (priceListNum) {
				if (itemDetails.itemprices && itemDetails.itemprices.length > 0) {
					const itemPriceData = itemDetails.itemprices.filter(itemPrice => itemPrice.pricelist.toString().toLowerCase() === priceListNum.toString().toLowerCase());
					if (itemPriceData && itemPriceData.length > 0) {
						const itemPriceEle = itemPriceData[0];
						if (itemPriceEle.uomprices && itemPriceEle.uomprices.length > 0) {
							itemPriceEle.uomprices.forEach(itemUP => {
								if (itemUP.uomentry) {
									const uomIndex = listOfUoms.findIndex(itemUom => itemUom.absentry && itemUom.absentry.toString().toLowerCase() === itemUP.uomentry.toString().toLowerCase());
									if (uomIndex !== -1) {
										const newItemUomPriceClone = JSON.parse(JSON.stringify(itemUP));
										newItemUomPriceClone.absentry = listOfUoms[uomIndex].absentry;
										newItemUomPriceClone.code = listOfUoms[uomIndex].code;
										newItemUomPriceClone.name = listOfUoms[uomIndex].name;
										newItemUomPriceClone.price = itemUP.price || 0;
										listOfSpecificUOM.push(newItemUomPriceClone);
									}
								}
							});
						}
					}
				} else {
					listOfSpecificUOM = this.setDefaultUomByUomGroupEntry(listOfUoms, listOfUomGroups, itemDetails);
				}
			} else {
				listOfSpecificUOM = this.setDefaultUomByUomGroupEntry(listOfUoms, listOfUomGroups, itemDetails);
			}
		}
		return listOfSpecificUOM;
	}

	private setDefaultUomByUomGroupEntry(listOfUoms, listOfUomGroups, itemDetails) {
		const listOfSpecificUOM = [];
		const uomGroupLst = listOfUomGroups || [];
		const uomGroupIndex = uomGroupLst.findIndex(itemUomGroup => itemDetails.uomgroupentry && itemUomGroup.absentry && itemUomGroup.absentry.toString().toLowerCase() === itemDetails.uomgroupentry.toString().toLowerCase());
		if (uomGroupIndex !== -1) {
			const uomGroupDefinitionCollection = uomGroupLst[uomGroupIndex].uomgroupdefinitioncollection || [];
			let newItemUomPice: any = {};
			uomGroupDefinitionCollection.forEach(itemUomGroupDefinitionCollection => {
				newItemUomPice = {};
				newItemUomPice = JSON.parse(JSON.stringify(itemUomGroupDefinitionCollection));
				const uomIndex = listOfUoms.findIndex(itemUom => itemUom.absentry.toString().toLowerCase() === itemUomGroupDefinitionCollection.alternateuom.toString().toLowerCase());
				if (uomIndex !== -1) {
					newItemUomPice.absentry = listOfUoms[uomIndex].absentry;
					newItemUomPice.code = listOfUoms[uomIndex].code;
					newItemUomPice.name = listOfUoms[uomIndex].name;
					newItemUomPice.price = 0;
					listOfSpecificUOM.push(newItemUomPice);
				}
			});
		}
		return listOfSpecificUOM;
	}

	public getTierBasisForGrowth() {
		const tierBasis = [
			{
				code: 'volume',
				label: 'Volume'
			},
			{
				code: 'revenue',
				label: 'Revenue'
			}
		];
		return tierBasis;
	}
	public getTierBasis() {
		const tierBasis = [
			// {
			// 	code: 'volume',
			// 	label: 'Volume'
			// },
			// {
			// 	code: 'revenue',
			// 	label: 'Revenue'
			// },
			{
				code: 'notiers',
				label: 'Standard'
			}
		];
		return tierBasis;
	}

	public getRoyaltiesBasis() {
		const royaltiesBasis = [
			// {
			// 	code: 'dollar',
			// 	label: this._LoginService.globalCurrencySymbol
			// },
			{
				code: 'percentage',
				label: '%'
			},
			// {
			// 	code: 'dollarperuom',
			// 	label: this._LoginService.globalCurrencySymbol+' / UOM'
			// }
		];
		return royaltiesBasis;
	}

	getQbOnlineErpUser() {
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			return true;
		} else {
			return false;
		}
	}

	getQbErpUser() {
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS) {
			return true;
		} else {
			return false;
		}
	}

	getItemGridBoxColumn() {
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			return this.itemGridBoxColumns = [
				{ dataField: 'itemsku', caption: 'SKU', width: 100 },
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		} else if (this.erpType === ErpTypeValEnum.QUICKBOOKS) {
			return this.itemGridBoxColumns = [
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		} else {
			return this.itemGridBoxColumns = [
				{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
				{ dataField: 'itemname', caption: 'Item', width: 100 },
				{ dataField: 'description', caption: 'Description' }
			];
		}
	}

	getCustomerGridBoxColumn() {
		if (this.erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
			return this.customerGridBoxColumns = [
				{ dataField: 'code', caption: 'Code' },
				{ dataField: 'name', caption: 'Name' }
			];
		} else if (this.erpType === ErpTypeValEnum.QUICKBOOKS) {
			return this.customerGridBoxColumns = [
				{ dataField: 'code', caption: 'Code', visible: false },
				{ dataField: 'name', caption: 'Name' },
			];
		} else {
			return this.customerGridBoxColumns = [
				{ dataField: 'code', caption: 'Code' },
				{ dataField: 'name', caption: 'Name' }
			];
		}
	}

	getProductSearchType() {
		// product_search logic type (item/price)
		const productSearchList = this._LoginService.loginUser.account_detail.app_settings.product_search || [];
		if (productSearchList && productSearchList.length > 0) {
			const productSearchItem = productSearchList.filter(function (item) {
				return (item['key'] === 'chargebacks');
			});
			return this.productSearchItemType = (productSearchItem[0]) ? productSearchItem[0].type : '';
		}
	}

	public getRoyaltiesContractSettlementReportSection() {
		const ROYALTIES_SETTLEMENT_REPORT_SETTING = [
			{
				name: 'Invoices',
				key: RoyaltiesSettlementReportSectionEnum.INVOICES,
				section_level: 1,
				parent_section_level: 0
			},
			{
				name: 'Customers',
				key: RoyaltiesSettlementReportSectionEnum.CUSTOMERS,
				section_level: 2,
				parent_section_level: 1
			},
			{
				name: 'Items',
				key: RoyaltiesSettlementReportSectionEnum.ITEMS,
				section_level: 3,
				parent_section_level: 2
			},
			{
				name: 'Invoices',
				key: RoyaltiesSettlementReportSectionEnum.INVOICES,
				section_level: 4,
				parent_section_level: 3
			},
			{
				name: 'Additional Incentives',
				key: RoyaltiesSettlementReportSectionEnum.EXTRA_ROYALTIES_BASED_RULES,
				section_level: 5,
				parent_section_level: 0
			},
			{
				name: 'Amortized Payments',
				key: RoyaltiesSettlementReportSectionEnum.AMORTIZED_PAYMENTS,
				section_level: 6,
				parent_section_level: 0
			},
			// {
			// 	name: 'A/R Credit Memo',
			// 	key: RoyaltiesSettlementReportSectionEnum.AR_CREDIT_MEMOS,
			// 	section_level: 7,
			// 	parent_section_level: 0
			// },
			// {
			// 	name: 'Service Credit Memo',
			// 	key: RoyaltiesSettlementReportSectionEnum.SERVICE_CREDIT_MEMOS,
			// 	section_level: 7,
			// 	parent_section_level: 0
			// },
			// // Hide section as of IX-1615 story
			// {
			// 	name: 'Miscellaneous Deduction',
			// 	key: 'miscellaneous_deduction_rules',
			// 	section_level: 9,
			// 	parent_section_level: 0
			// },
		]
		return ROYALTIES_SETTLEMENT_REPORT_SETTING;
	}

	//Load State Data
	public loadStateData(sectionLevelKey, parentSectionLevelKey) {
		const sLeval = this.getSectionLevel(sectionLevelKey, parentSectionLevelKey);
		let newSection = null;
		try {
			newSection = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(ServerSections.ROYALTIES_SETTLEMENT_REPORT_SETTING)));
		} catch (e) {
			newSection = null;
		}
		const royaltiesSettlementReportConfigClone = newSection;
		if (!royaltiesSettlementReportConfigClone.configs) {
			royaltiesSettlementReportConfigClone.configs = [];
		}
		const statePersistentIndex = royaltiesSettlementReportConfigClone.configs.findIndex(sp => sp.section_level === sLeval.section_level && sp.parent_section_level === sLeval.parent_section_level);
		if (statePersistentIndex !== -1) {
			if (royaltiesSettlementReportConfigClone.configs[statePersistentIndex].state_persistent) {
				return royaltiesSettlementReportConfigClone.configs[statePersistentIndex].state_persistent;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	// Get grid section level
	public getSectionLevel(sectionLevel, parentSectionLevel?) {
		const lstSection = this.getRoyaltiesContractSettlementReportSection();
		let psLevel, sLeval;
		if (!parentSectionLevel) {
			const sectionLeval = lstSection.filter(i => i.key === sectionLevel && i.parent_section_level === 0);
			sLeval = sectionLeval[0];
		} else {
			const sLeval1 = lstSection.filter(i => i.key === sectionLevel && i.parent_section_level !== 0);
			sLeval1.forEach(element => {
				const psLevel1 = lstSection.filter(i => i.key === parentSectionLevel);
				if (psLevel1) {
					psLevel1.forEach(pElement => {
						if (element.parent_section_level === pElement.section_level) {
							sLeval = element;
						}
					});
				}
			});
		}
		// console.log('sLeval ::: ', sLeval);
		return sLeval;
	}

	public getListOfActionsForDatagrid() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	public getRoyaltiesSttlementReportTab() {
		const royalties_settlement_report_setting = [
			{
				name: 'Invoices',
				title: 'Invoices',
				key: RoyaltiesSettlementReportSectionEnum.INVOICES,
				section: ServerSections.INVOICES,
				value: 0
			},
			// {
			// 	name: 'AR Cradit Memo',
			// 	title: 'AR Cradit Memo',
			// 	key: RoyaltiesSettlementReportSectionEnum.AR_CREDIT_MEMOS,
			// 	section: ServerSections.AR_CREDIT_MEMOS,
			// 	value: 0
			// },
			{
				name: 'Additional Incentives',
				title: 'Additional Incentives',
				key: RoyaltiesSettlementReportSectionEnum.EXTRA_ROYALTIES_BASED_RULES,
				section: ServerSections.EXTRA_COMMISSION_BASED_RULES,
				value: 0
			},
			{
				name: 'Amortized Payments',
				title: 'Amortized Payments',
				key: RoyaltiesSettlementReportSectionEnum.AMORTIZED_PAYMENTS,
				section: ServerSections.AMORTIZED_PAYMENTS,
				value: 0
			},

		]
		return royalties_settlement_report_setting;
	}

	getRoyaltiesRptDetails() {
		return this.royaltiesRptDetails;
	}
	setRoyaltiesRptDetails(val: any) {
		this.royaltiesRptDetails = val;
	}

	public getListOfActionsForRoyaltiesReport() {
		return [
			// {
			// 	btn_code: 'CLOSE_CURRENT_SETTLEMENT_PERIOD',
			// 	btn_name: 'Close Current Settlement Period',
			// 	btn_image: 'close_current_settlement_period',
			// 	icon: IMG_ICON_CLOSE_MULTIPLE_SETTLEMENT,
			// 	visible: true,
			// 	disabled: false,
			// },
			// {
			// 	btn_code: 'UPDATE_CURRENT_SETTLEMENT',
			// 	btn_name: 'Update Current Settlement',
			// 	btn_image: 'update_current_settlement',
			// 	icon: IMG_ICON_UPDATE_CURRENT_SETTLEMENT,
			// 	visible: true,
			// 	disabled: false,
			// },
			{
				btn_code: 'EXPORT_PDF',
				btn_name: 'Export Pdf',
				btn_image: 'pdffile',
				icon: IMG_ICON_EXPORT_PDF,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export Data',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
		];
	}

	public convertCurrency(val: any, customCurrencyOption?: CurrencyOptions) {
		return this._CustomCurrencyPipe.transform(val || 0, customCurrencyOption);
	}

	//set default as selected Other currency 
	public getCustomCurrencyOption(commissionDetails?: any) {
		let customCurrencyOption: CurrencyOptions = new CurrencyOptions();
		//set default primary currency 
		if (this._LoginService.loginUser.account_detail.app_settings.hasOwnProperty('currency_code')) {
			let currency_code = this._LoginService.loginUser.account_detail.app_settings.currency_code;
			customCurrencyOption.currencyCode = currency_code;
		}
		if (commissionDetails) {
			if (commissionDetails.currency_code) {
				customCurrencyOption.currencyCode = commissionDetails.currency_code;
			}
		}
		this.globalCurrencySymbol = this.setGlobalCurrencySymbol(customCurrencyOption);
		return customCurrencyOption;
	}


	public setGlobalCurrencySymbol(customCurrencyOption: CurrencyOptions) {
		let currencyModel: CurrencyModel = this._AppCommonSrvc.getCurrencyDetail(customCurrencyOption);
		if (currencyModel.currency_code) {
			return new Intl.NumberFormat(currencyModel.currency_language, {
				style: 'currency',
				currency: currencyModel.currency_code,
				minimumIntegerDigits: 1,
				minimumFractionDigits: this.decimalPointForCurrency,
				maximumFractionDigits: this.decimalPointForCurrency,
			}).formatToParts(1).find(x => x.type === "currency").value || '$';
		} else {
			return this._LoginService.globalCurrencySymbol;
		}
	}
}
