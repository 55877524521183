import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
// import { UserIdleService } from 'angular-user-idle';
import { ToastrService } from "ngx-toastr";
import { environment } from "@environments/environment";
import { LoginService } from "@app/services/login.service";
import { LoaderService } from "@app/services/loaderservices/loader.service";
import { RestApiService } from "@app/services/rest-service/rest-api.service";
import { AppCommonSrvc } from "@app/services/app-common-srvc/app-common-srvc.service";
import { DataService } from "@app/services/data.service";
import { MSG_ERROR_MESSAGE, RGX_REPLACE_NUMBER_ONLY, ServerMethods } from "@app/constants-enums/constants";
import { ContractLifeCycleValEnum } from "@app/constants-enums/enums";
import { Login } from "@app/models/auth";
import { DxTextBoxComponent } from "devextreme-angular";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit, OnDestroy {
	public errorMessage: string;
	LoginData = new Login();
	private loginReqSubscription: Subscription;
	public signupPageUrl = environment.URL_WEBSITE_PRICING_PAGE;
	public listOfPages: any[] = [
		{
			guid: '8a3725531fc84c6cb1f33d4dd75a242e',
			code: 'LOGIN',
		},
		{
			guid: '8a3725531fc84c6cb1f33d4dd75a252e',
			code: '2FA_AUTHY',
		},
		{
			guid: '8a3725531fc84c6cb1f33d4dd75a262e',
			code: '2FA_SMS',
		},
		{
			guid: '8a3725531fc84c6cb1f33d4dd75a272e',
			code: 'LOGIN_WITH_SSO',
		}
	];
	public multiViewIndex: number = 0;
	private token: string;
	private twoFacReqSubscription: Subscription;
	private dataOutReqSubscription: Subscription;
	private sendSmsOtpReqSubscription: Subscription;
	public twoFactorAuthenticationCode: string;
	public authenticationType: string; // authycode,smscode
	public mobileNumber: string;
	public isCallVerifyOTP: boolean = false;
	public ContractLifeCycleValEnum = ContractLifeCycleValEnum;
	public maskRules = { X: /[02-9]/ };
	@ViewChild('twoFactorTextbox') twoFactorTextbox: DxTextBoxComponent;
	public isDisabledEmailId: boolean = false;
	public isDisabledPassword: boolean = false;
	public isDisabledTwoFactorCodeTxt: boolean = false;
	private queryParams: any;
	public isShowLoginContainer: boolean = true;
	public isShowForgotPasswordPopup: boolean;
	private rediectToHelpDesk: string;

	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _RestApiService: RestApiService,
		// private _UserIdleService: UserIdleService,
		private _Router: Router,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _ToastrService: ToastrService,
		private _DataService: DataService,
		private _ActivatedRoute: ActivatedRoute) {
		this.isShowLoginContainer = true;
		this._ActivatedRoute.queryParams.subscribe(params => {
			this.queryParams = params;
			//if (!this.queryParams['mode']) {
			//this.errorMessage = 'You have authenticated with an SSO account which does not match the incentX account email';
			//} 
			if (this.queryParams && this.queryParams.redirect === 'helpdesk') {
				this.rediectToHelpDesk = this.queryParams.redirect;
			} else if (this.queryParams && this.queryParams.error === 'IX404' && (this.queryParams['mode'] && this.queryParams.mode === 'azure')) {
				this.errorMessage = this.queryParams['authResponse'] ? this.queryParams['authResponse'] : 'You have authenticated with an Azure account which does not match the incentX account email';
			} else if (this.queryParams && this.queryParams.error === 'IX404' && (this.queryParams['mode'] && this.queryParams.mode === 'okta')) {
				this.errorMessage = this.queryParams['authResponse'] ? this.queryParams['authResponse'] : 'You have authenticated with an Okta account which does not match the incentX account email';
			} else {
				this.doDirectLoginWithSSO();
			}
		});
	}

	ngOnInit() {
		//Stop Idle Timer 
		// this._UserIdleService.stopTimer();
		// this._UserIdleService.stopWatching();

		this.LoginData.user = "";
		this.LoginData.pwd = "";
		if (!environment.production) {
			this.LoginData.user = "";
			this.LoginData.pwd = "";
			this.twoFactorAuthenticationCode = '';
		}
		this.initLoginView();
	}

	ngOnDestroy() {
		if (this.sendSmsOtpReqSubscription) {
			this.sendSmsOtpReqSubscription.unsubscribe();
		}
		if (this.loginReqSubscription) {
			this.loginReqSubscription.unsubscribe();
		}
		if (this.twoFacReqSubscription) {
			this.twoFacReqSubscription.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
	}

	// #region for Login Page

	public login() {
		this.errorMessage = "";
		this.LoginData.user = this.LoginData.user.trim();
		this.LoginData.pwd = this.LoginData.pwd.trim();
		if (this.LoginData.user && this.LoginData.pwd) {
			if (environment.production) {
				this.productionLogin();
			} else {
				this.developmentLogin();
			}
		} else {
			this.errorMessage = "Please enter a username and password";
		}
	}

	public signUpClick() {
		// this._Router.navigate(['/auth/signup?tier=3&numLicenses=16']);
		this._Router.navigate(["/auth/signup"]);
	}

	public doLoginInitialized(e) {
		setTimeout(() => {
			e.component.focus();
		}, 150);
	}

	public doForgotPassword() {
		this.doOpenForgotPasswordPopup();
		// this._MatDialog.open(ForgotPasswordComponent, this.dialogConfig).afterClosed().subscribe(result => {
		// 	if (result) {

		// 	}
		// });
	}

	private doOpenForgotPasswordPopup() {
		this.isShowForgotPasswordPopup = false;
		setTimeout(() => {
			this.isShowForgotPasswordPopup = true;
		}, 200);
	}

	public doCloseForgotPasswordPopup(event) {
		if (event.isClickOnCloseBtn) {
			this.isShowForgotPasswordPopup = false;
			return;
		}
		this.isShowForgotPasswordPopup = false;
	}

	private developmentLogin() {
		const formData = new FormData();
		formData.append('usr', this.LoginData.user);
		formData.append('pwd', this.LoginData.pwd);
		formData.append('logout', '1');

		this.isDisabledEmailId = true;
		this.isDisabledPassword = true;
		this._LoaderService.show();
		if (this.loginReqSubscription) {
			this.loginReqSubscription.unsubscribe();
		}
		this.loginReqSubscription = this._RestApiService.doLoginReqFormData(formData).subscribe({
			next: (result) => {
				this._LoaderService.hide();
				this.isDisabledEmailId = false;
				this.isDisabledPassword = false;
				this.productionLogin();
			}, error: (error) => {
				this._LoaderService.hide();
				this.isDisabledEmailId = false;
				this.isDisabledPassword = false;
				this.errorMessage = error.message || "Something went wrong!";
				console.error(error);
			}
		});
	}

	private productionLogin() {
		const formData = new FormData();
		formData.append('usr', this.LoginData.user);
		formData.append('pwd', this.LoginData.pwd);
		this.isDisabledEmailId = true;
		this.isDisabledPassword = true;
		if (this.loginReqSubscription) {
			this.loginReqSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.loginReqSubscription = this._RestApiService.doLoginReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response.flag === true) {
					if (response.message && (response.message.substr(0, 5) === "AUTHE" || response.message.substr(0, 5) === "SESSI")) {
						this.errorMessage = response.message;
					} else {
						if (this.LoginData.user) {
							this._LoginService.loginUser = JSON.parse(JSON.stringify(this.LoginData));
							// this._Router.navigate(["/auth/twofactorauthentication"], { replaceUrl: true });
							setTimeout(() => {
								this.multiViewIndex = 1;
								if (this.twoFactorTextbox) {
									setTimeout(() => {
										this.twoFactorTextbox.instance.focus();
									}, 300);
								}
							}, 650);
							this.twoFactorAuthenticationCode = '';
						}
					}
				} else {
					this.errorMessage = response.message;
					this.isDisabledEmailId = false;
					this.isDisabledPassword = false;
				}
			}, error: (error) => {
				this._LoaderService.hide();
				console.error("error", error);
				this.isDisabledEmailId = false;
				this.isDisabledPassword = false;
				this.errorMessage = error.message || "Something went wrong!";
			}
		});
	}

	// #endregion

	// #region for Two Factor Authentication

	private initLoginView() {
		setTimeout(() => {
			this.multiViewIndex = 0;
		}, 650);
		this.isDisabledEmailId = false;
		this.isDisabledPassword = false;
	}

	doLoginView() {
		setTimeout(() => {
			this.multiViewIndex = 0;
		}, 650);
		this.errorMessage = '';
		this.isDisabledEmailId = false;
		this.isDisabledPassword = false;
	}

	doClickMethodIcon(methodName: string) {
		this.isCallVerifyOTP = false;
		this.errorMessage = '';
		if (methodName === 'smscode') {
			this.mobileNumber = '';
			this.doClickResendPIN();
		} else if (methodName === 'authycode') {
			this.authenticationType = methodName;
			this.twoFactorAuthenticationCode = '';
			setTimeout(() => {
				this.multiViewIndex = 1;
				if (this.twoFactorTextbox) {
					setTimeout(() => {
						this.twoFactorTextbox.instance.focus();
					}, 300);
				}
			}, 650);
		}
		this.twoFactorAuthenticationCode = '';
	}

	public doClickResendPIN() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('pwd', this._LoginService.loginUser.pwd);
		formData.append('method', ServerMethods.SMS_OTP);

		if (this.sendSmsOtpReqSubscription) {
			this.sendSmsOtpReqSubscription.unsubscribe();
		}

		this._LoaderService.show();
		this.sendSmsOtpReqSubscription = this._RestApiService.doSendSmsOtpReq(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response.flag) {
					this.multiViewIndex = 2;
					if (this.twoFactorTextbox) {
						setTimeout(() => {
							this.twoFactorTextbox.instance.focus();
						}, 300);
					}
					this.twoFactorAuthenticationCode = '';
					this.authenticationType = 'smscode';
					this.mobileNumber = response.data.phone;
					this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
				} else {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public checkTwoFactorAuthenticationClick() {
		if (!this.twoFactorAuthenticationCode) {
			this._ToastrService.info('Verification code is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		if (this.twoFactorAuthenticationCode.toString().trim().length !== 6) {
			// this._ToastrService.info('Verification code needs to be 6 digit.', 'Info', { closeButton: true, tapToDismiss: true });
			this.errorMessage = 'Verification code needs to be 6 digit';
			return;
		}

		this.callVerificationCode();
	}

	public doChange2FAToken(e) {
		if (e.event) {
			const keyCode = e.event.keyCode;
			let twoFactorCode = e.value || '';
			if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)) && keyCode !== 86) {
				if (twoFactorCode) {
					twoFactorCode = twoFactorCode.toString().replace(RGX_REPLACE_NUMBER_ONLY, '');
					setTimeout(() => {
						this.twoFactorAuthenticationCode = twoFactorCode;
					});
					return;
				}
			}
			if (keyCode === 86) {
				twoFactorCode = twoFactorCode.toString().replace(RGX_REPLACE_NUMBER_ONLY, '');
				setTimeout(() => {
					this.twoFactorAuthenticationCode = twoFactorCode;
				});
			}
			// if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || keyCode === 86) { // Only Allow Number keycode
			if (twoFactorCode && twoFactorCode.toString().trim().length === 6) {
				this.twoFactorAuthenticationCode = twoFactorCode;
				this.errorMessage = '';
				this.callVerificationCode();
			}
		}
	}

	public doKeyUpVerificationCode(e) {
		const isCheckNumber: boolean = (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || false;
		this.isDisabledTwoFactorCodeTxt = false;
		if (this.twoFactorAuthenticationCode.toString().trim().length === 6 && isCheckNumber) {
			this.isDisabledTwoFactorCodeTxt = true;
			this.errorMessage = '';
			this.callVerificationCode();
		}
	}

	public doOnPasteVerificationCode(event: ClipboardEvent) {
		let clipboardData: any = event.clipboardData || '';
		if (clipboardData) {
			let vCode = clipboardData.getData('text');
			//const isNumber = Number(vCode) || false;
			if (vCode.toString().trim().length > 6) {
				vCode = vCode.substring(0, 6);
			}
			if (vCode.toString().trim().length === 6) {
				this.isDisabledTwoFactorCodeTxt = true;
				this.twoFactorAuthenticationCode = vCode;
				this.callVerificationCode();
			}
		}
	}

	public do2FAInitialized(e) {
		setTimeout(() => {
			e.component.focus();
		}, 150);
	}

	private callVerificationCode() {
		if (this.twoFactorAuthenticationCode) {
			this.isCallVerifyOTP = true;
			this.isDisabledTwoFactorCodeTxt = true;

			const mainFormdata = new FormData();
			mainFormdata.append('usr', this.LoginData.user);
			mainFormdata.append('method', ServerMethods.VERIFY_OTP);
			mainFormdata.append('twofactorcode', this.twoFactorAuthenticationCode);

			if (this.twoFacReqSubscription) {
				this.twoFacReqSubscription.unsubscribe();
			}
			this._LoaderService.show();
			this.twoFacReqSubscription = this._RestApiService.doLoginReqFormData(mainFormdata).subscribe({
				next: (outerResponse) => {
					this._LoaderService.hide();
					if (!outerResponse.flag) {
						this.isDisabledTwoFactorCodeTxt = false;
						this.isCallVerifyOTP = false;
						this.errorMessage = outerResponse.message;
					} else {
						this._LoginService.doResponseForTwoFactor(outerResponse);
						this.getCurrencyMasterLst();
						const formData = new FormData();
						formData.append('usr', this.LoginData.user);
						formData.append('token', outerResponse.data.token.trim());
						if (this.dataOutReqSubscription) {
							this.dataOutReqSubscription.unsubscribe();
						}
						this._LoaderService.show();
						this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
							next: (response) => {
								this._LoaderService.hide();
								if (response.flag === true) {
									if (response.flag && response.data && Object.keys(response.data).length > 0) {
										const data = response.data;
										this._DataService.setGroups(data.groups || []);
										this._DataService.setSalesPpl(data.salesPpl || []);
										this._DataService.setUOMs(data.uom || []);
										this._DataService.setVendors(data.vendors || []);
										this._DataService.setUomGroups(data.uomgroups || []);
										this._DataService.setSpecialPrices(data.specialprices || []);
										this._DataService.setWareHouses(data.warehouses || []);

										this._LoginService.doFinalResponseForLogin(response, this.rediectToHelpDesk);

									} else {
										this.errorMessage = response.message;
									}
								} else {
									this.errorMessage = response.message || 'Something went wrong!';
								}
							},
							error: (error) => {
								this.errorMessage = error.message || 'Something went wrong!';
								this._LoaderService.hide();
								console.error(error);
							}
						});
					}
				},
				error: (error) => {
					this.isCallVerifyOTP = false;
					this.isDisabledTwoFactorCodeTxt = false;
					this.errorMessage = error.message || 'Something went wrong!';
					this._LoaderService.hide();
					console.error(error);
				}
			});
		}
	}

	public getCurrencyMasterLst() {
		this._AppCommonSrvc.fetchedListOfCurrrency().subscribe({
			next: (response: any) => {
				this._LoginService.loginUser.account_detail['currency_lst'] = [];
				if (response && response.data && response.data.length > 0) {
					this._LoginService.loginUser.account_detail.currency_lst = response.data || [];
				}
			}, error: (error) => {
				this._LoginService.loginUser.account_detail['currency_lst'] = [];
			}
		});
		this._AppCommonSrvc.fetchedListOfOtherCurrrency().subscribe({
			next: (response: any) => {
				this._LoginService.loginUser.account_detail['other_currencies_lst'] = [];
				if (response && response.data && response.data.length > 0) {
					this._LoginService.loginUser.account_detail.other_currencies_lst = response.data || [];
				}
			}, error: (error) => {
				this._LoginService.loginUser.account_detail['other_currencies_lst'] = [];
			}
		});
	}
	//#endregion


	//#region for SSO Logins

	public doContinueWithSSO() {
		this.errorMessage = "";
		this.LoginData.user = this.LoginData.user.trim();
		if (this.LoginData.user) {
			this.doCallLoginWithSSO();
		} else {
			this.multiViewIndex = 3;
		}
	}

	public doLoginWithSSO() {
		this.LoginData.user = this.LoginData.user.trim();
		if (this.LoginData.user) {
			this.errorMessage = '';
			this.doCallLoginWithSSO();
		} else {
			this.errorMessage = "Please enter a username";
		}
	}

	public doCancelLoginWithSSO() {
		this.doLoginView();
	}

	private doCallLoginWithSSO() {
		const formData = new FormData();
		formData.append('email', this.LoginData.user);
		this.errorMessage = '';
		this._LoaderService.show();
		this._RestApiService.doLoginWithSSOReq(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response.flag) {
					window.location.href = response.data;
				} else {
					this.errorMessage = response.message || MSG_ERROR_MESSAGE;
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this.errorMessage = error.message || MSG_ERROR_MESSAGE;
			}
		});

	}

	private doDirectLoginWithSSO() {
		if (this.queryParams && this.queryParams.authResponse && this.queryParams.mode) {
			const formData = new FormData();
			formData.append('method', ServerMethods.VERIFY_USER_TOKEN);
			formData.append('authResponse', this.queryParams.authResponse);
			formData.append('mode', this.queryParams.mode);
			if (this.twoFacReqSubscription) {
				this.twoFacReqSubscription.unsubscribe();
			}

			this.isShowLoginContainer = false;
			this.twoFacReqSubscription = this._RestApiService.doLoginReqFormData(formData).subscribe({
				next: (response) => {
					if (response && response.flag) {
						const responseData: any = response.data;
						const formData = new FormData();
						formData.append('usr', responseData.email);
						formData.append('token', responseData.token);
						if (this.dataOutReqSubscription) {
							this.dataOutReqSubscription.unsubscribe();
						}
						this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
							next: (innerResponse) => {
								if (innerResponse.flag === true) {
									const data = innerResponse.data;
									this._DataService.setGroups(data.groups || []);
									this._DataService.setSalesPpl(data.salesPpl || []);
									this._DataService.setUOMs(data.uom || []);
									this._DataService.setVendors(data.vendors || []);
									this._DataService.setUomGroups(data.uomgroups || []);
									this._DataService.setWareHouses(data.warehouses || []);
									this._DataService.setSpecialPrices(data.specialprices || []);
									this._LoginService.doResponseForTwoFactor(response);
									this._LoginService.doFinalResponseForLogin(response, this.rediectToHelpDesk);
								} else {
									this.errorMessage = response.message || MSG_ERROR_MESSAGE;
									this.isShowLoginContainer = true;
								}
							}, error: error => {
								this.errorMessage = error.message || MSG_ERROR_MESSAGE;
								this.isShowLoginContainer = true;
							}
						});
					} else {
						this.errorMessage = response.message || MSG_ERROR_MESSAGE;
						this.isShowLoginContainer = true;
					}
				},
				error: (error) => {
					this.errorMessage = error.message || MSG_ERROR_MESSAGE;
					this.isShowLoginContainer = true;
				}
			});
		}
	}
	//#endregion
}