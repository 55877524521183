<div class="contentRow cf">
	<!-- <div class="titleRow">
		<h1>Settlement Report List</h1>
	</div> -->
	<div class="borderBox cf padB0">
		<div class="row">
			<div class="cols9">
				<ul class="listText">
					<li>Contract Name:<strong class="purple">{{commissionDetails.contractName || ''}}</strong></li>
					<li> Salesperson Name:<strong class="purple">{{commissionDetails.subusername || ''}}</strong></li>
				</ul>
			</div>
			<div class="cols3 mrgB20 ">
				<div class="multiBtnsRow">
					<div class="btnsGroup txtRight">
						<dx-button *ngxPermissionsOnly="isCurrentTabPermission" class="secondaryBtn"
							text="Update Current Settlement" (onClick)="UpdateSettlementPeriodClick()"
							[useSubmitBehavior]="false">
							<div *dxTemplate="let data of 'content'">
								<dx-load-indicator height="25" width="25" class="button-indicator"
									[elementAttr]="{ class: 'button-loader-indicator-blue' }"
									[visible]="isShowLoaderIndicator"></dx-load-indicator>
								<span class="dx-button-text"
									[class]="{'mrgL10':isShowLoaderIndicator}">{{updateSettlementPeriodBtnText}}</span>
							</div>
						</dx-button>
					</div>
				</div>
			</div>
		</div>
		<div class="cf">
			<div class="tableOut">
				<dx-data-grid #dataGridContainer id="gridContainer" class="cellColor hoverClr hideFilterPanelInx"
					[dataSource]="closeSettlementDataSource" [showBorders]="true" [showColumnLines]="true"
					[allowColumnResizing]="true" [allowColumnReordering]="true" [showRowLines]="false"
					[dateSerializationFormat]="'yyyy-MM-dd'" [allowColumnResizing]="true" [filterSyncEnabled]="true"
					[remoteOperations]="true" [columnAutoWidth]="true" [width]="'100%'"
					(onToolbarPreparing)="doToolbarPreparing($event)" (onRowClick)="viewCommissionCalculation($event.data)">
					<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
					<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true"
						[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
					</dxo-pager>
					<dxo-filter-row [visible]="true"></dxo-filter-row>
					<dxo-filter-panel [visible]="true"></dxo-filter-panel>
					<dxo-header-filter [visible]="false"></dxo-header-filter>
					<dxo-sorting mode="single"></dxo-sorting>

					<div *dxTemplate="let data of 'templateFilterText'">
						<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
							<span>{{filterText}}</span>
						</div>
					</div>
					<dxi-column dataField="ixcode" caption="Settlement No." dataType="string">
					</dxi-column>

					<dxi-column dataField="settlementPeriodStart" alignment="center" caption="Start Date" dataType="date"
						format="yyyy-MM-dd" cellTemplate="cellTemplateSettlementPeriodStart" sortOrder="desc">
						<div *dxTemplate="let element of 'cellTemplateSettlementPeriodStart'" class="documentStatusColor"
							[ngClass]="{'purpleClrBefore':!element.data.isSettlementPeriodClosed}">
							{{ (element.data.settlementPeriodStart !== null && element.data.settlementPeriodStart !== '' &&
							element.data.settlementPeriodStart !== undefined && element.data.settlementPeriodStart !== 'Invalid
							date') ? (element.data.settlementPeriodStart | date: 'yyyy-MM-dd') : "" }}
						</div>
					</dxi-column>

					<dxi-column dataField="settlementPeriodEnd" alignment="center" caption="End Date" dataType="date"
						format="yyyy-MM-dd" cellTemplate="cellTemplateSettlementPeriodEnd">
						<div *dxTemplate="let element of 'cellTemplateSettlementPeriodEnd'">
							{{ (element.data.settlementPeriodEnd !== null && element.data.settlementPeriodEnd !== '' &&
							element.data.settlementPeriodEnd !== undefined && element.data.settlementPeriodEnd !== 'Invalid
							date') ? (element.data.settlementPeriodEnd | date: 'yyyy-MM-dd') : "" }}
						</div>
					</dxi-column>

					<dxi-column dataField="accrual_settlementValue" alignment="right"
						[customizeText]="doCustomizeTotalSummary" caption="Accrued Amount" dataType="number" [width]="220"
						[visible]="enable_incentive_accrual_journal_entry"
						[showInColumnChooser]="enable_incentive_accrual_journal_entry">
					</dxi-column>
					<dxi-column dataField="settlementValue" alignment="right" [customizeText]="doCustomizeTotalSummary"
						[caption]="dataFieldCaption('settlementValue')" dataType="number" [width]="220">
					</dxi-column>

					<dxi-column dataField="accrual_apInvoiceCreatedInERP" caption="Accural JE Status" alignment="center"
						cellTemplate="cellTemplateStatus" dataType="string" [visible]="enable_incentive_accrual_journal_entry"
						[showInColumnChooser]="enable_incentive_accrual_journal_entry">
						<div *dxTemplate="let element of 'cellTemplateStatus'">
							<span
								[ngClass]="{'orange':element.data.accrual_apInvoiceCreatedInERP === 'Processing','purple':element.data.apInvoiceCreatedInERP === 'Open','red':element.data.apInvoiceCreatedInERP === 'Error','green':element.data.apInvoiceCreatedInERP === 'Success'}">{{element.data.apInvoiceCreatedInERP}}</span>
						</div>
					</dxi-column>
					<dxi-column dataField="apInvoiceCreatedInERP" [caption]="dataFieldCaption('apInvoiceCreatedInERP')"
						alignment="center" cellTemplate="cellTemplateStatus" dataType="string">
						<div *dxTemplate="let element of 'cellTemplateStatus'">
							<span
								[ngClass]="{'orange':element.data.apInvoiceCreatedInERP === 'Processing','purple':element.data.apInvoiceCreatedInERP === 'Open','red':element.data.apInvoiceCreatedInERP === 'Error','green':element.data.apInvoiceCreatedInERP === 'Success'}">{{element.data.apInvoiceCreatedInERP}}</span>
						</div>
					</dxi-column>

					<dxi-column dataField="accrual_errorMessage" caption="Accural JE Remark"
						[showInColumnChooser]="enable_incentive_accrual_journal_entry"
						[visible]="enable_incentive_accrual_journal_entry" dataType="string">
					</dxi-column>
					<dxi-column dataField="errorMessage" [caption]="dataFieldCaption('errorMessage')" dataType="string">
					</dxi-column>
					<!-- <dxi-column caption="Action" alignment="center" dataType="string" cellTemplate="cellTemplateAction"
					[visible]="isColumneVisible" [width]="85" [minWidth]="85"  [allowFiltering]="false" [allowExporting]="false" [allowSorting]="false" [allowResizing]="false">
					<div *dxTemplate="let element of 'cellTemplateAction'" class="center actBtn">
						<a  matTooltip="View Settlement Report"
							(click)="viewCommissionCalculation(element.data)"><span class="viewIcon sprite"></span></a>
					</div>
					<dxo-filter-row [visible]="false"></dxo-filter-row>
					<dxo-header-filter [visible]="false"></dxo-header-filter>
				</dxi-column> -->

					<dxo-summary>
						<!-- <dxi-total-item column="settlementValue" summaryType="sum" displayFormat="{0}">
							<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-value-format>
						</dxi-total-item> -->
						<dxi-total-item column="settlementValue" summaryType="sum" displayFormat="{0}"
							[customizeText]="doCustomizeTotalSummary">
						</dxi-total-item>
					</dxo-summary>
				</dx-data-grid>
			</div>
		</div>
	</div>
</div>