<header id="header">
	<div class="cf">
		<!-- <div class="navigation-trigger">
			<i class="menu-icon"></i>
		</div> -->
		<div class="menuExpandTrigger">
			<span class="menu-icon"></span>
		</div>
		<div id="logo" class="alignLeft">
			<a class="xlogo">
				<img [src]="_AppCommonSrvc.cdnImgPath +'logo-x.png'" alt="incentX" title="incentX">
			</a>
			<div class="headercustomerlogo">
				<img *ngIf="companyLogoPath" alt="Customer Logo" [src]="companyLogoPath" />
				<span *ngIf="!companyLogoPath" [innerHtml]="companyName" class="bold mrgT5 alignLeft black"> </span>
			</div>
			<div class="sendboxCnt" *ngIf="is_staging_environment"> </div>
			<!-- <div class="trialCnt" *ngIf="is_trial && !is_staging_environment">
				<img [src]="_AppCommonSrvc.cdnImgPath +'trial_stamp.png'" alt="Trial" title="Trial" class="trialStampImg">
			</div> -->
		</div>

		<!-- <div class="emailStatus" *ngIf="!email_verified">
			<span class="verificationcontIcon icon-verification icon-set1"></span> Email Verification Pending
			<a (click)="resendVerificationEmail()"> Resend
				Email
			</a>
		</div> -->

		<div class="rightTop">
			<div class="usrCntNav">
				<!-- <div class="rightDrop">
					<span class="rightDropBtn"></span>
					<div class="rightDropIn">
						<div class="syncERP">
							<a>
								<span class="headerIcon syncIcon"></span>
								Sync with ERP
								<span *ngIf="syncedServiceMessage" class="syncHistory" [ngClass]="{'red': isSyncedError}">
									{{syncedServiceMessage || ''}}
								</span>
							</a>
						</div>
						<div class="usrSettings">
							<a>
								<span class="headerIcon stngIcon"></span>
								Settings <span class="downArrow"></span>
							</a>
						</div>
					</div>
				</div> -->
				<div *ngIf="is_sync_status" class="synceProcessIsRanning">
					<dx-load-indicator id="medium-indicator" height="30" width="30"></dx-load-indicator>
					<span>Sync Process is Running</span>
				</div>

				<div class="userProfile">
					<a>
						<div class="userImage">
							<!-- <img src="images/usr-img.png" alt="User Name" title="User Name" /> -->
							<span class="">{{first_name || '' | slice:0:1 |uppercase}}{{last_name || '' | slice:0:1
								|uppercase}}</span>
						</div>
						<div class="userDetail">
							<h3>{{first_name || ''}} {{last_name || ''}}, <span>{{role_name || ''}}</span>
								<span class="roleDetails" *ngIf="isAdminUser"> (Admin)</span>
							</h3>
							<h4>Customer Id.: <span>{{customer_no || ''}}</span></h4>
						</div>
					</a>
					<div class="userDtlToggle toggleIt">
						<ul class="usrContact">
							<li>
								<a (click)="doRedirectUser('userprofile')">
									<span class="contIcon icon-email_id icon-set1"></span>Profile
								</a>
							</li>
							<li *ngIf="isShowSubscriptionMenu">
								<a (click)="doRedirectUser('currentplan')">
									<span class="contIcon icon-current-plan-new icon-set1"></span>Subscriptions
								</a>
							</li>
							<li *ngxPermissionsOnly="['USERPROFILE_COMPANY_INFO_VIEW']">
								<a (click)="doRedirectUser('companyinfo')">
									<span class="contIcon icon-companyinfo-icon-new icon-set1"></span>Company Info
								</a>
							</li>
							<li *ngIf="isShowBillingProfileMenu">
								<a (click)="doRedirectUser('billinghistory')">
									<span class="contIcon icon-billing-history-new icon-set1"></span>Billing Profile
								</a>
							</li>
						</ul>
					</div>
				</div>

				<div *ngIf="enable_helpdesk_feature" class="alignLeft mrgR15">
					<a (click)="doRedirectUser('supporttickets')">
						<span class="contIcon icon-support-ticket-header"></span>
					</a>
				</div>

				<div *ngxPermissionsOnly="['NOTIFICATIONS_VIEW']" class="notificationBar">
					<a class="" (click)="notificationBarclick()">
						<div class="headerIcon icon-notification-icon-new">
							<span *ngIf="unreadNotificationCount > 0" class="notiCount">
								<!-- {{unreadNotificationCount > 99 ?  '99+' : unreadNotificationCount}} -->
							</span>
						</div>
					</a>
					<!-- 
						<div class="dropPopup notifiaction">
						<ul>
							<li>
								Under Construction !
							</li>
							<li>
								<a>
									<span class="dropPopupTitle">Lorem Ipsum is simply dummy.</span>
									<span class="dropPopupText">Lorem Ipsum is simply dummy text of the printing
										and type...</span>
									<span class="dropPopupDate">03-06-2019</span>
								</a>
							</li>
						</ul>
						<a class="button viewAll">View All</a>
						</div> 
					-->
				</div>

				<div class="signOut">
					<a (click)="doLogout()"><span class="icon-logout-new icon-set1"></span> </a>
				</div>
			</div>


			<!-- <div class="searchBar">
				<div class="searchControl">
					<input type="text" class="searchField" placeholder="Search..." />
					<input type="button" class="searchBtn" />
				</div>
			</div> -->

		</div>
		<div class="cols4 distributionChannelHeader" *ngIf="isEnableDistributionChannels">
			<app-distribution-channels-dx-drop-down-box
				[distributionChannelsModel]="distributionChannelsModel"></app-distribution-channels-dx-drop-down-box>
		</div>
		<div class="email-varified-toast d-flex d-flex-center" *ngIf="!email_verified">
			<span class="icon-verification"></span>
			<p class="flex-grow-1">Email Verification Pending: <span class="bold cursorPointer"
					(click)="resendVerificationEmail()">Resend
					Email</span></p>
			<a (click)="closeEmailVerification()"><i class="icon-close"></i></a>
		</div>
		<!-- </div>  -->
	</div>

	<app-notifications-tasks-popup [popupVisible]="isVisibleNotificationPopup"
		(getUnreadNotificationEvent)="getUnreadNotificationMessage($event)">
	</app-notifications-tasks-popup>
	<app-card-view-close-multiple-settlement *ngIf="isShowSettlementCardView"
		[cardViewMultiSettlementProps]="cardViewMultiSettlementProps"
		(closeCardViewMultipleSettlementPopup)="doCloseCardViewMultipleSettlementPopup($event)">
	</app-card-view-close-multiple-settlement>

</header>

<app-logout *ngIf="isShowLogoutPopup" [portalType]="portalType"
	(closeLogoutPopup)="doCancelLogoutPopup($event)"></app-logout>