import { OverduePaymentDialogComponent } from '@app/custom-components/overdue-payment-dialog/overdue-payment-dialog.component';
import { environment } from '@environments/environment';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { UserProfileService } from '@app/services/userprofile-service/userprofile-service.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { TwoFactorAuthentication } from '@app/models/auth';
import { LoginService } from '@app/services/login.service';
import { DataService } from '@app/services/data.service';
import { ADMIN_PORTAL, SUPER_ADMIN, MSG_ERROR_MESSAGE } from '@app/constants-enums/constants';
import { Subscription } from 'rxjs';
import { SubscriptionUpgradeDialogComponent } from '@app/custom-components/subscription-upgrade-dialog/subscription-upgrade-dialog.component';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import moment from 'moment';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { CalledFromEnum, ContractLifeCycleValEnum } from '@app/constants-enums/enums';
import { ToastrService } from 'ngx-toastr';


@Component({
	selector: 'app-two-factor-authentication',
	templateUrl: './two-factor-authentication.component.html',
	styleUrls: ['./two-factor-authentication.component.css']
})
export class TwoFactorAuthenticationComponent implements OnInit, OnDestroy {
	public token: string;
	public LoginData: any = [];
	public errorMessage: string;
	private twoFacReqSubscription: Subscription;
	private dataOutReqSubscription: Subscription;
	private sendSmsOtpReqSubscription: Subscription;
	public twoFactorAuthenticationCode: string;
	public authenticationType: string; // authycode,smscode
	public mobileNumber: string;
	private dialogConfig: MatDialogConfig = new MatDialogConfig();
	private dialogConfig1: MatDialogConfig = new MatDialogConfig();
	private dialogRef: MatDialogRef<any>;
	private dialogRef1: MatDialogRef<any>;
	public isCallVerifyOTP: boolean = false;
	public ContractLifeCycleValEnum = ContractLifeCycleValEnum;
	constructor(private _DataService: DataService,
		private router: Router,
		private route: ActivatedRoute,
		private _LoginService: LoginService,
		private _GlobalStateService: GlobalStateService,
		private _RestApiService: RestApiService,
		private _LoaderService: LoaderService,
		public dialog: MatDialog,
		public _AppCommonSrvc: AppCommonSrvc,
		public _UserProfileService: UserProfileService,
		private _ToastrService: ToastrService,
	) {
		this.LoginData = this._LoginService.loginUser;
		this.authenticationType = 'authycode';
	}

	ngOnInit() {
		if (!environment.production) {
			this.twoFactorAuthenticationCode = '';
		}
		this.errorMessage = '';
	}
	ngOnDestroy() {
		if (this.sendSmsOtpReqSubscription) {
			this.sendSmsOtpReqSubscription.unsubscribe();
		}
	}

	doClickMethodIcon(methodName: string) {
		this.isCallVerifyOTP = false;
		this.errorMessage = '';

		if (methodName && methodName === 'smscode') {
			this.mobileNumber = '';
			this.doClickResendPIN();
		} else {
			this.authenticationType = methodName;
			this.twoFactorAuthenticationCode = '';
		}
	}

	doClickResendPIN() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('pwd', this._LoginService.loginUser.pwd);
		formData.append('method', 'smsOTP');

		if (this.sendSmsOtpReqSubscription) {
			this.sendSmsOtpReqSubscription.unsubscribe();
		}

		this._LoaderService.show();
		this.sendSmsOtpReqSubscription = this._RestApiService.doSendSmsOtpReq(formData).subscribe(response => {
			this._LoaderService.hide();
			if (response.flag) {
				this.twoFactorAuthenticationCode = '';
				this.authenticationType = 'smscode';
				this.mobileNumber = response.data.phone;
				this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
			} else {
				this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
			}
		}, error => {
			this._LoaderService.hide();
			this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
		});
	}

	checkTwoFactorAuthenticationClick() {
		// tslint:disable-next-line: max-line-length
		if (!this.twoFactorAuthenticationCode) {
			this._ToastrService.info('Verification code is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		if (this.twoFactorAuthenticationCode.toString().trim().length !== 6) {
			// this._ToastrService.info('Verification code needs to be 6 digit.', 'Info', { closeButton: true, tapToDismiss: true });
			this.errorMessage = 'Verification code needs to be 6 digit';
			return;
		}
		if (this.twoFacReqSubscription) {
			this.twoFacReqSubscription.unsubscribe();
		}

		this.callVerificationCode();

	}

	doKeyUpVerificationCode(e) {
		const isCheckNumber: boolean = (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || false;

		if (this.twoFactorAuthenticationCode.toString().trim().length === 6 && isCheckNumber) {
			this.errorMessage = '';
			if (this.twoFacReqSubscription) {
				this.twoFacReqSubscription.unsubscribe();
			}
			this.callVerificationCode();
		}
	}

	doOnPasteVerificationCode(event: ClipboardEvent) {
		let clipboardData: any = event.clipboardData || '';
		if (clipboardData) {
			let vCode = clipboardData.getData('text');
			//const isNumber = Number(vCode) || false;
			if (vCode.toString().trim().length > 6) {
				vCode = vCode.substring(0, 6);
			}
			if (vCode.toString().trim().length === 6) {
				this.twoFactorAuthenticationCode = vCode;
				if (this.twoFacReqSubscription) {
					this.twoFacReqSubscription.unsubscribe();
				}
				this.callVerificationCode();
			}
		}
	}

	private callVerificationCode() {
		if (this.twoFactorAuthenticationCode) {
			if (this.twoFacReqSubscription) {
				this.twoFacReqSubscription.unsubscribe();
			}
			this.isCallVerifyOTP = true;
			const formdata = 'method=verifyOTP&twofactorcode=' + this.twoFactorAuthenticationCode + '&usr=' + this.LoginData.user;
			this._LoaderService.show();
			this.twoFacReqSubscription = this._RestApiService.doLoginReq(formdata).subscribe(
				outerResponse => {
					this._LoaderService.hide();
					if (!outerResponse.flag) {
						this.isCallVerifyOTP = false;
						this.errorMessage = outerResponse.message;
					} else {
						this._LoginService.loginUser.account_detail = outerResponse.data.account_detail;
						// var a = moment([2007, 0, 29]);
						// var b = moment([2007, 0, 28]);
						if (this._LoginService.loginUser.account_detail.expirydate !== null && this._LoginService.loginUser.account_detail.expirydate !== undefined && this._LoginService.loginUser.account_detail.expirydate !== '' && outerResponse.data.server_date !== null && outerResponse.data.server_date !== undefined && outerResponse.data.server_date !== '') {
							const a = moment(this._LoginService.loginUser.account_detail.expirydate, "YYYY-MM-DD");
							const b = moment(outerResponse.data.server_date, "YYYY-MM-DD");
							const remDays = a.diff(b, 'days');
							this._LoginService.loginUser.account_detail.trial_remaining_days = remDays;
						}

						//As per discussed with Prasanna for now not show rebate status changes Date-17112021
						//this._LoginService.loginUser.account_detail.contract_lifecycle_management = ContractLifeCycleValEnum.ALWAYS_OFF;
						//End

						// tslint:disable-next-line: max-line-length tslint:disable-next-line: no-unused-expression
						this._LoginService.loginUser.account_detail.sub_accounts = this._LoginService.loginUser.account_detail.sub_accounts === null || undefined || '' ? [] : this._LoginService.loginUser.account_detail.sub_accounts;
						this.token = outerResponse.data.token.trim();
						this._LoginService.loginUser.server_date = outerResponse.data.server_date;
						this._LoginService.loginUser.server_timezone = outerResponse.data.server_timezone;
						this._LoginService.loginUser.token = this.token;
						const userFormData = 'usr=' + this.LoginData.user + '&token=' + this.token;
						if (this.dataOutReqSubscription) {
							this.dataOutReqSubscription.unsubscribe();
						}
						this._LoaderService.show();
						this.dataOutReqSubscription = this._RestApiService.doDataOutReq(userFormData).subscribe(
							response => {
								if (response.flag === true) {
									if (response.flag && response.data && Object.keys(response.data).length > 0) {
										const data = response.data;
										// this._DataService.setContracts(data.contracts);
										// this._DataService.setContacts(data.contacts);
										// this._DataService.setMaincontracts(data.contracts.contracts);
										// this._DataService.setCurrentBillbackContracts(data.contracts.billbackcontracts);
										// this._DataService.setCurrentRebatesContracts(data.contracts.rebatescontracts);
										// this._DataService.setCurrentRoyaltiesContracts(data.contracts.royaltiescontracts);
										// this._DataService.setEmptyContract(data.emptyContract);
										this._DataService.setEmptyClient(data.emptyClient);
										this._DataService.setTerritories(data.items);
										this._DataService.setItems(data.items);
										this._DataService.setGroups(data.groups);
										this._DataService.setSalesPpl(data.salesPpl);
										this._DataService.setClients(data.clients);
										this._DataService.setUOMs(data.uom);
										this._DataService.setVendors(data.vendors);
										this._DataService.setQuotations(data.quotations);
										this._DataService.setOrders(data.orders);
										this._DataService.setInvoices(data.invoices);
										this._DataService.setUomGroups(data.uomgroups);
										this._DataService.setPriceLists(data.pricelists);
										this._DataService.setSpecialPrices(data.specialprices);
										this._DataService.setBuyingGroups(data.buyinggroups);
										this._DataService.setLeads(data.leads);
										this._DataService.setProspects(data.prospects);
										this._DataService.setWareHouses(data.warehouses);
										this._UserProfileService.setCalledFrom(CalledFromEnum.CALLED_AS_UPDATE_PACKAGE);
										// Default currency set use for all
										this._LoginService.globalCurrency = 'USD';
										if (ADMIN_PORTAL === this._LoginService.loginUser.account_detail.portal_type) {
											this.router.navigate(['/adminportal/dashboard'], { replaceUrl: true });
										} else {
											if (this._LoginService.loginUser.account_detail.role === SUPER_ADMIN || this._LoginService.loginUser.account_detail.is_trial) {
												const subscription = this._LoginService.loginUser.account_detail.subscription;

												if (subscription || this._LoginService.loginUser.account_detail.is_trial) {
													if (!subscription.tier_requirement_match || this._LoginService.loginUser.account_detail.is_trial) {
														const trial_remaining_days = this._LoginService.loginUser.account_detail.trial_remaining_days;
														if (trial_remaining_days < 0) {
															this._UserProfileService.setCalledFrom(CalledFromEnum.CALLED_AS_TIRAL_EXPIRED);
														} else {
															this._UserProfileService.setCalledFrom(CalledFromEnum.CALLED_AS_TIRAL);
														}


														this.dialogConfig.data = {
															subscription: subscription,
															is_trial: this._LoginService.loginUser.account_detail.is_trial,
															message: '',
															buttonText: {
																cancel: 'Ok'
															}
														};
														this.dialogRef = this.dialog.open(SubscriptionUpgradeDialogComponent, this.dialogConfig);
														this.dialogRef.afterClosed().subscribe(result => {
															if (result) {

															}
														});
													}
												}

											}
											this.router.navigate(['/dashboard'], { replaceUrl: true });
										}

										//check overdue invoice
										if (this._LoginService.loginUser.account_detail.is_invoice_overdue) {
											this._UserProfileService.setCalledFrom(CalledFromEnum.CALLED_AS_OVERDUE_PAYMENT);
											this.dialogRef = this.dialog.open(OverduePaymentDialogComponent, this.dialogConfig);
											this.dialogRef.afterClosed().subscribe(result => {
												if (result) {

												}
											});
										}
									} else {
										this.errorMessage = response.message;
									}
								} else {
									this.errorMessage = response.message || 'Something went wrong!';
								}
								this._LoaderService.hide();
							}, error => {
								this.errorMessage = error.message || 'Something went wrong!';
								this._LoaderService.hide();
								console.error(error);
							}
						);
					}
				}, error => {
					this.isCallVerifyOTP = false;
					this.errorMessage = error.message || 'Something went wrong!';
					this._LoaderService.hide();
					console.error(error);
				}
			);
		}
	}

	async logout() {
		const formdata = "usr=" + this.LoginData.user + "&logout=1&token=" + this._LoginService.loginUser.token || '';
		this._LoaderService.show();
		await this._RestApiService.doLoginReq(formdata).subscribe(response => {
			this._LoaderService.hide();
			this.dialog.closeAll();
			this._LoginService.loginUser = undefined;
			this._GlobalStateService.notifyDataChangedDuplicate('TOKEN_REFRESHER_INTERVAL_EVENT', new Date().getTime());
			this.router.navigate(['/auth/login']);
		}, error => {
			console.error(error);
			this._LoaderService.hide();
			this.dialog.closeAll();
			this._LoginService.loginUser = undefined;
			this._GlobalStateService.notifyDataChangedDuplicate('TOKEN_REFRESHER_INTERVAL_EVENT', new Date().getTime());
			this.router.navigate(['/auth/login']);
		});
	}
}
