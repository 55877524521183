import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
declare function callGeneralJs(): any;
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { LoginService } from '@app/services/login.service';
import { Subscription } from 'rxjs';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { PREFERRED_COUNTRIES } from '@app/constants-enums/constants';
import { AppStateService } from '@app/services/app-state/app-state.service';
@Component({
	selector: 'app-adminportal',
	templateUrl: './adminportal.component.html',
	styleUrls: ['./adminportal.component.css']
})
export class AdminportalComponent implements OnInit, AfterViewInit, OnDestroy {
	public setTimeOutRequestCall: any;
	private dialCodeSubscription: Subscription;
	private countrySubscription: Subscription;
	private stateSubscription: Subscription;
	private nameDictionarySubscription: Subscription;
	private preferredDialcodes = PREFERRED_COUNTRIES;
	constructor(
		private _GlobalStateService: GlobalStateService,
		private _AppStateService: AppStateService,
		private _LoginService: LoginService,
		// private userIdle: UserIdleService,
		private _RestApiService: RestApiService,
		private _LoaderService: LoaderService
	) { }

	ngOnInit() {
		this.setTimeOutRequestCall = setInterval(() => {
			this.resetBackendExpireTime();
		}, 60000);
		this._GlobalStateService.subscribe('TOKEN_REFRESHER_INTERVAL_EVENT', response => {
			if (response) {
				clearInterval(this.setTimeOutRequestCall);
			}
		});
		this.fetchedListOfDialCode();
		this.fetchedListOfCountry();
		this.fetchedListOfStates();
		this.fetchedListOfNameDictionary();
	}

	resetBackendExpireTime(): void {

		if (this._LoginService.loginUser && this._LoginService.loginUser.user) {
			const formdata = 'usr=' + this._LoginService.loginUser.user + '&token=' + this._LoginService.loginUser.token;
			// this.loaderService.show();
			this._RestApiService.doLoginReq(formdata).subscribe(response => {
				// console.log('ResetBackendExpireTime');
				// this.userIdle.stopTimer();
				// this.userIdle.stopWatching();
				// this.userIdle.startWatching();
			}, error => {
				// this.loaderService.hide();
				// this.userIdle.stopTimer();
				// this.userIdle.stopWatching();
				// this.userIdle.startWatching();
				console.error(error);
			});
		}
	}

	ngAfterViewInit() {
		//callGeneralJs();
	}

	ngOnDestroy(): void {
		this._GlobalStateService.unsubscribe('TOKEN_REFRESHER_INTERVAL_EVENT');
		clearInterval(this.setTimeOutRequestCall);

		if (this.dialCodeSubscription) {
			this.dialCodeSubscription.unsubscribe();
		}
		if (this.countrySubscription) {
			this.countrySubscription.unsubscribe();
		}
		if (this.stateSubscription) {
			this.stateSubscription.unsubscribe();
		}
		if (this.nameDictionarySubscription) {
			this.nameDictionarySubscription.unsubscribe();
		}
	}

	// #region for Dialcode Related Logic

	// For Fetched List of Dialcodes
	private fetchedListOfDialCode() {
		if (this.dialCodeSubscription) {
			this.dialCodeSubscription.unsubscribe();
		}
		// this._LoaderService.show();
		this.dialCodeSubscription = this._RestApiService.doPhoneCodeReq().subscribe((res: any) => {
			// this._LoaderService.hide();
			this._AppStateService.set('LIST_DIALCODES', []);
			this._AppStateService.set('LIST_PREFERRED_DIALCODES', []);
			if (res && res.length > 0) {
				this._AppStateService.set('LIST_DIALCODES', res);
				const preferredCountries = [];
				this.preferredDialcodes.forEach(country => {
					const preferredCountryIndex = res.findIndex(countryItem => countryItem.id === country);
					preferredCountries.push(res[preferredCountryIndex]);
				});
				this._AppStateService.set('LIST_PREFERRED_DIALCODES', preferredCountries);
			}
		}, error => {
			this._AppStateService.set('LIST_DIALCODES', []);
			this._AppStateService.set('LIST_PREFERRED_DIALCODES', []);
			// this._LoaderService.hide();
		});
	}

	// #endregion

	// #region for Country Related Logic
	// For Fetched List of Countries
	private fetchedListOfCountry() {
		if (this.countrySubscription) {
			this.countrySubscription.unsubscribe();
		}
		this.countrySubscription = this._RestApiService.doGetContryReq().subscribe(response => {
			this._AppStateService.set('LIST_COUNTRIES', response);
		}, error => {
			this._AppStateService.set('LIST_COUNTRIES', []);
		});
	}
	// #endregion

	// #region for state related Logic
	private fetchedListOfStates() {
		if (this.stateSubscription) {
			this.stateSubscription.unsubscribe();
		}
		this.stateSubscription = this._RestApiService.doGetStateReq().subscribe(response => {
			this._AppStateService.set('LIST_STATES', response || []);
		}, error => {
			this._AppStateService.set('LIST_STATES', []);
		});

	}
	// #endregion

	// #region for Get Name Dictionary to Support Generate Password
	private fetchedListOfNameDictionary() {
		if (this.nameDictionarySubscription) {
			this.nameDictionarySubscription.unsubscribe();
		}
		this.nameDictionarySubscription = this._RestApiService.doNameDictionaryReq().subscribe(response => {
			this._AppStateService.set('LIST_NAME_DICTIONARY', response.dictionary || []);
		}, error => {
			this._AppStateService.set('LIST_NAME_DICTIONARY', []);
		});
	}
	// #endregion
}
