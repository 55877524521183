import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { CommissionSettlementReportSectionEnum, ContractTypeValEnum } from '@app/constants-enums/enums';
import { CommissionSettlemntRptModel } from '@app/models/commission-contract.model';
import { ViewInvoiceDetailPopUpModel, ViewPopUpModel } from '@app/models/popup.model';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CtsOrderRptTabComponent } from './cts-order-rpt-tab/cts-order-rpt-tab.component';
import { CtsChangeOrderRptTabComponent } from './cts-change-order-rpt-tab/cts-change-order-rpt-tab.component';
import { CtsInvoiceRptTabComponent } from './cts-invoice-rpt-tab/cts-invoice-rpt-tab.component';
import { CtsAmortizedPaymentRptTabComponent } from './cts-amortized-payment-rpt-tab/cts-amortized-payment-rpt-tab.component';
import { CtsExtraCommBasedruleRptTabComponent } from './cts-extra-comm-basedrule-rpt-tab/cts-extra-comm-basedrule-rpt-tab.component';
import { CtsMiscDeductruleRptTabComponent } from './cts-misc-deductrule-rpt-tab/cts-misc-deductrule-rpt-tab.component';
import { CtsServiceCreditMemoRptTabComponent } from './cts-service-credit-memo-rpt-tab/cts-service-credit-memo-rpt-tab.component';
import { CtsModifiedInvoiceRptTabComponent } from './cts-modified-invoice-rpt-tab/cts-modified-invoice-rpt-tab.component';
import { CellRange, exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridPdf } from 'devextreme/pdf_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { jsPDF } from 'jspdf';
import saveAs from 'file-saver';
import { CurrencyOptions } from '@app/models/common.model';
import { proxima_nova, proxima_nova_bold, proxima_nova_semi_bold } from '@app/services/app-common-srvc/app-common-fonts.service';
import moment from 'moment';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
import { CustomNumberNoRoundingPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';
declare const ExcelJS: ExcelJS;
@Component({
	selector: 'app-commission-tierbase-settlement-report',
	templateUrl: './commission-tierbase-settlement-report.component.html',
	styleUrls: ['./commission-tierbase-settlement-report.component.css']
})
export class CommissionTierbaseSettlementReportComponent implements OnInit, OnDestroy, AfterViewInit {
	private dataOutReqSubscription: Subscription;
	private dataOutAllSecReqSubscription: Subscription;
	private getCommssionReportExportDataSbsn: Subscription;
	public commissionRptTabs: any[] = [];
	public currentRptTab: string;
	public CommissionSettlementReportSectionEnum = CommissionSettlementReportSectionEnum;
	public commissionRptDetails: CommissionSettlemntRptModel;
	public viewPopUpModelProps: ViewPopUpModel = new ViewPopUpModel();
	public commissionSatusUIClass: any;
	public enable_amortise_payments_for_commission: boolean = false;
	public enable_incremental_payment: boolean = false;
	public enable_profit_on_document_header: boolean = false;
	public isShowExportPdf: boolean;
	public listOfActions: any[] = [];
	public isShowLoaderIndicator: boolean = false;
	public reloadAllDatagrid: boolean = true;
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();
	@ViewChild(CtsInvoiceRptTabComponent) ctsInvoiceRptTabCom!: CtsInvoiceRptTabComponent;
	@ViewChild(CtsOrderRptTabComponent) ctsOrderRptTabCom!: CtsOrderRptTabComponent;
	@ViewChild(CtsChangeOrderRptTabComponent) ctsChnageOrderRptTabCom!: CtsChangeOrderRptTabComponent;
	@ViewChild(CtsModifiedInvoiceRptTabComponent) ctsModifiedInvoiceRptTabCom!: CtsModifiedInvoiceRptTabComponent;
	@ViewChild(CtsAmortizedPaymentRptTabComponent) ctsAmortizedPaymentRptTabCom!: CtsAmortizedPaymentRptTabComponent;
	@ViewChild(CtsExtraCommBasedruleRptTabComponent) ctsExtraCommBasedruleRptTabCom!: CtsExtraCommBasedruleRptTabComponent;
	@ViewChild(CtsMiscDeductruleRptTabComponent) ctsMiscDeductruleRptTabComponent!: CtsMiscDeductruleRptTabComponent;
	@ViewChild(CtsServiceCreditMemoRptTabComponent) ctsServiceCreditMemoRptTabCom!: CtsServiceCreditMemoRptTabComponent;
	public indexTab: number = 0;
	public enable_incentive_accrual_journal_entry: boolean = false;
	constructor(
		private _LoaderService: LoaderService,
		private _ToastrService: ToastrService,
		private _CommissionService: CommissionService,
		private _LoginService: LoginService,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		private _CustomNumberNoRoundingPipe: CustomNumberNoRoundingPipe,
		private _RestApiService: RestApiService,
		private _IxDxAlertService: IxDxAlertService,
		private _AppCommonSrvc: AppCommonSrvc,

	) { }

	ngOnInit(): void {
		this.enable_amortise_payments_for_commission = false;
		this.commissionRptTabs = this._CommissionService.getCommissionSttlementReportTab();
		this.commissionRptDetails = this._CommissionService.getCommissionRptDetails();
		this.listOfActions = this._CommissionService.getListOfActionsForCommissionReport() || [];;
		this.enable_incentive_accrual_journal_entry = this._AppCommonSrvc.getEnableIncentiveAccualJournalEntery();
		this.commissionSatusUIClass = {
			'orangeInvoice': this.commissionRptDetails.apInvoiceCreatedInERP === 'Processing',
			'purpleInvoice': this.commissionRptDetails.apInvoiceCreatedInERP === 'Open',
			'redInvoice': this.commissionRptDetails.apInvoiceCreatedInERP === 'Error',
			'greenInvoice': this.commissionRptDetails.apInvoiceCreatedInERP === 'Success'
		};
		this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption(this.commissionRptDetails);
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_amortise_payments')) {
			const enable_amortise_payments = this._LoginService.loginUser.account_detail.enable_amortise_payments;
			if (enable_amortise_payments) {
				if (this._LoginService.loginUser.account_detail.hasOwnProperty('amortise_payment_use_for')) {
					const amortisePaymentUseFor = this._LoginService.loginUser.account_detail.amortise_payment_use_for
					const commissionIndex = amortisePaymentUseFor.findIndex(u => u === 'commission');
					if (commissionIndex !== -1) {
						this.enable_amortise_payments_for_commission = true;
					}
				}
			}
		}
		this.enable_incremental_payment = false;
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_incremental_payment')) {
			this.enable_incremental_payment = this._LoginService.loginUser.account_detail.enable_incremental_payment;
		}
		this.enable_profit_on_document_header = false;
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_profit_on_document_header')) {
			this.enable_profit_on_document_header = this._LoginService.loginUser.account_detail.enable_profit_on_document_header;
		}
		this.isShowExportPdf = false;
		if (this.enable_profit_on_document_header) {
			this.isShowExportPdf = true;
		}
		this.loadRptTab();
		this.getAllSectionTotal();
	}

	ngOnDestroy(): void {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.dataOutAllSecReqSubscription) {
			this.dataOutAllSecReqSubscription.unsubscribe();
		}
		if (this.getCommssionReportExportDataSbsn) {
			this.getCommssionReportExportDataSbsn.unsubscribe();
		}
	}

	ngAfterViewInit(): void {
		// this.reloadAllDatagrid = false;
	}

	public doActionsBtnClick(event: any) {
		const addIndex = this.listOfActions.findIndex(i => i.btn_code === 'EXPORT_PDF');
		const closeCurrSettIndex = this.listOfActions.findIndex(i => i.btn_code === 'CLOSE_CURRENT_SETTLEMENT_PERIOD');
		const updateCurrSettIndex = this.listOfActions.findIndex(i => i.btn_code === 'UPDATE_CURRENT_SETTLEMENT');
		if (addIndex !== -1) {
			this.listOfActions[addIndex].visible = this.isShowExportPdf;
		}
		if (closeCurrSettIndex !== -1) {
			this.listOfActions[closeCurrSettIndex].visible = this.commissionRptDetails.apInvoiceCreatedInERP === 'Open' ? true : false;
		}
		if (updateCurrSettIndex !== -1) {
			this.listOfActions[updateCurrSettIndex].visible = this.commissionRptDetails.apInvoiceCreatedInERP === 'Open' ? true : false;
		}
	}

	public doActionsBtnItemClick(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'CLOSE_CURRENT_SETTLEMENT_PERIOD':
					this.doActionCloseCurrentSettlementPeriod();
					break;
				case 'UPDATE_CURRENT_SETTLEMENT':
					this.doActionUpdateCurrentSettlement();
					break;
				case 'EXPORT_PDF':
					this.doExportJsPdf();
					break;
				case 'EXPORT':
					this.onExporting();
					break;
			}
		}
	}

	doOnTabOptionChanged(e) {
		if (e.name === "changedProperty") {

		}
	}

	public doSelectionChanged(e) {
		if (e.addedItems && e.addedItems.length > 0) {
			let selectedItem = e.addedItems[0];
			if (selectedItem.key === CommissionSettlementReportSectionEnum.INVOICES) {
				this.ctsInvoiceRptTabCom.updateStatesClearBtn();
			}
			if (selectedItem.key === CommissionSettlementReportSectionEnum.ORDERS) {
				this.ctsOrderRptTabCom.updateStatesClearBtn();
			}
			if (selectedItem.key === CommissionSettlementReportSectionEnum.MODIFIED_INVOICES) {
				this.ctsModifiedInvoiceRptTabCom.updateStatesClearBtn();
			}
			if (selectedItem.key === CommissionSettlementReportSectionEnum.CHANGE_ORDERS) {
				this.ctsChnageOrderRptTabCom.updateStatesClearBtn();
			}
			if (selectedItem.key === CommissionSettlementReportSectionEnum.AMORTIZED_PAYMENTS) {
				this.ctsAmortizedPaymentRptTabCom.updateStatesClearBtn();
			}
			if (selectedItem.key === CommissionSettlementReportSectionEnum.EXTRA_COMMISSION_BASED_RULES) {
				this.ctsExtraCommBasedruleRptTabCom.updateStatesClearBtn();
			}
			if (selectedItem.key === CommissionSettlementReportSectionEnum.MISCELLANEOUS_DEDUCTION_RULES) {
				this.ctsMiscDeductruleRptTabComponent.updateStatesClearBtn();
			}
			if (selectedItem.key === CommissionSettlementReportSectionEnum.SERVICE_CREDIT_MEMOS) {
				this.ctsServiceCreditMemoRptTabCom.updateStatesClearBtn();
			}
		}
	}

	private addFontToJsPdf(doc: jsPDF) {
		const regularFont = 'proxima_nova.ttf';
		const semiBoldFont = 'proxima_nova semiBold.ttf';
		const boldFont = 'proxima_nova Bold.ttf';
		doc.addFileToVFS(regularFont, proxima_nova);
		doc.addFileToVFS(semiBoldFont, proxima_nova_semi_bold);
		doc.addFileToVFS(boldFont, proxima_nova_bold);
		doc.addFont(regularFont, 'proxima_nova', 'normal');
		doc.addFont(semiBoldFont, 'proxima_nova semiBold', 'normal');
		doc.addFont(boldFont, 'proxima_nova Bold', 'bold');
	}

	private addPageNumberToJsPdf(doc: jsPDF) {
		const regularFont = 'proxima_nova';
		const pageCount = doc.getNumberOfPages();
		doc.setFont(regularFont, 'normal');
		doc.setFontSize(9);
		for (var i = 1; i <= pageCount; i++) {
			doc.setPage(i);
			doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 10, {
				align: 'center'
			});
		}
	}

	private addHeaderToJsPdf(doc: jsPDF) {
		const regularFont = 'proxima_nova';
		const semiBoldFont = 'proxima_nova semiBold';
		const boldFont = 'proxima_nova Bold';
		const pageWidth = doc.internal.pageSize.getWidth();
		const pageHeight = doc.internal.pageSize.getHeight();
		const pageCount = doc.getNumberOfPages();
		let invoiceOrOrderTotal = 0;
		let companyName = '';
		let companyImageBase64 = '';
		let fileName = '';
		if (this._LoginService.loginUser.account_detail.company_name) {
			companyName = this._LoginService.loginUser.account_detail.company_name;
		}
		if (this._LoginService.loginUser.account_detail.companylogo) {
			companyImageBase64 = this._LoginService.loginUser.account_detail.companylogo_base64;
		}
		for (let i = 1; i <= pageCount; i++) {
			let intialAxisX = 5;
			const intialAxisY = 15;
			let incrementalAxisY = 15;
			doc.setPage(i);
			const commissionReport = 'Commission Report';
			doc.setFont(regularFont, 'normal');
			doc.setFontSize(18);
			doc.text(commissionReport, intialAxisX, incrementalAxisY);

			incrementalAxisY += 7; // 22;
			const contractName = this.commissionRptDetails.contractName;
			doc.setFont(boldFont, 'bold');
			doc.setFontSize(18);
			doc.text(contractName, intialAxisX, incrementalAxisY);

			const fontSizeForSubRow = 12;
			let isSalesRep = false;
			if (this.commissionRptDetails.subusername) {
				isSalesRep = true;
				// Sales Rep :
				incrementalAxisY += 10;
				const salesRepLabel = 'Sales Rep: ';
				doc.setFontSize(fontSizeForSubRow);
				doc.setFont(regularFont, 'normal');
				doc.text(salesRepLabel, intialAxisX, incrementalAxisY);
				let row0Xaxis = intialAxisX + doc.getStringUnitWidth(salesRepLabel) * fontSizeForSubRow / doc.internal.scaleFactor;

				const salesRepValue = this.commissionRptDetails.subusername;
				doc.setFontSize(fontSizeForSubRow);
				doc.setFont(boldFont, 'bold');
				doc.text(salesRepValue, row0Xaxis, incrementalAxisY);
			}

			// Generated: DATETIME
			incrementalAxisY += isSalesRep ? 6 : 10; // 34
			const generatedLabel = 'Generated: ';
			doc.setFontSize(fontSizeForSubRow);
			doc.setFont(regularFont, 'normal');
			doc.text(generatedLabel, intialAxisX, incrementalAxisY);
			let row1Xaxis = intialAxisX + doc.getStringUnitWidth(generatedLabel) * fontSizeForSubRow / doc.internal.scaleFactor;

			const generatedValue = moment().format('YYYY-MM-DD | hh:mm:ss A');
			doc.setFont(boldFont, 'bold');
			doc.setFontSize(fontSizeForSubRow);
			doc.text(generatedValue, row1Xaxis, incrementalAxisY);
			row1Xaxis += doc.getStringUnitWidth(generatedValue) * fontSizeForSubRow / doc.internal.scaleFactor;

			// Settlement Period: : DATE TO DATE
			const settlementNoLabel = 'Settlement No.: ';
			doc.setFont(regularFont, 'normal');
			doc.setFontSize(fontSizeForSubRow);
			row1Xaxis += 10;
			doc.text(settlementNoLabel, row1Xaxis, incrementalAxisY);
			row1Xaxis += doc.getStringUnitWidth(settlementNoLabel) * fontSizeForSubRow / doc.internal.scaleFactor;

			const settlementNoValue = this.commissionRptDetails.ixcode;
			doc.setFont(boldFont, 'bold');
			doc.setFontSize(fontSizeForSubRow);
			doc.text(settlementNoValue, row1Xaxis, incrementalAxisY);

			// Settlement Period: : DATE TO DATE
			row1Xaxis += 35;
			const settlementPeriodLabel = 'Settlement Period: ';
			doc.setFont(regularFont, 'normal');
			doc.setFontSize(fontSizeForSubRow);
			doc.text(settlementPeriodLabel, row1Xaxis, incrementalAxisY);
			row1Xaxis += doc.getStringUnitWidth(settlementPeriodLabel) * fontSizeForSubRow / doc.internal.scaleFactor;

			const settlementPeriodValue = this.commissionRptDetails.settlementPeriodStartDate + ' To ' + this.commissionRptDetails.settlementPeriodEndDate;
			doc.setFont(boldFont, 'bold');
			doc.setFontSize(fontSizeForSubRow);
			doc.text(settlementPeriodValue, row1Xaxis, incrementalAxisY);

			const changeOrdersIndex = this.commissionRptTabs.findIndex(tab => tab.key === CommissionSettlementReportSectionEnum.CHANGE_ORDERS);
			const orderIndex = this.commissionRptTabs.findIndex(tab => tab.key === CommissionSettlementReportSectionEnum.ORDERS);
			const invoiceIndex = this.commissionRptTabs.findIndex(tab => tab.key === CommissionSettlementReportSectionEnum.INVOICES);

			let invoiceOrorderValue = 0;
			if (this.commissionRptDetails.calculate_commission_by === 'orders') {
				invoiceOrorderValue = this.commissionRptTabs[orderIndex].value || 0;
			} else {
				invoiceOrorderValue = this.commissionRptTabs[invoiceIndex].value || 0;
			}

			// Commission : $00.00
			incrementalAxisY += 6; // 40
			const currentCommissionText = 'Current Commissions: ';
			doc.setFont(regularFont, 'normal');
			doc.setFontSize(fontSizeForSubRow);
			doc.text(currentCommissionText, intialAxisX, incrementalAxisY);
			let row2Xaxis = intialAxisX + doc.getStringUnitWidth(currentCommissionText) * fontSizeForSubRow / doc.internal.scaleFactor;

			const currentCommissionValueText = this._CommissionService.convertCurrency(invoiceOrorderValue || 0, this.customCurrencyOption);
			doc.setFont(boldFont, 'bold');
			doc.setFontSize(fontSizeForSubRow);
			doc.text(currentCommissionValueText, row2Xaxis, incrementalAxisY);
			row2Xaxis += doc.getStringUnitWidth(currentCommissionValueText) * fontSizeForSubRow / doc.internal.scaleFactor;


			if (changeOrdersIndex !== -1) {
				row2Xaxis += 10;
				const changeOrderText = this.commissionRptTabs[changeOrdersIndex].title + ': ';
				doc.setFont(regularFont, 'normal');
				doc.setFontSize(fontSizeForSubRow);
				doc.text(changeOrderText, row2Xaxis, incrementalAxisY);
				row2Xaxis = row2Xaxis + doc.getStringUnitWidth(changeOrderText) * fontSizeForSubRow / doc.internal.scaleFactor;

				const changeOrderValueText = this._CommissionService.convertCurrency(this.commissionRptTabs[changeOrdersIndex].value || 0, this.customCurrencyOption);
				doc.setFont(boldFont, 'bold');
				doc.setFontSize(fontSizeForSubRow);
				doc.text(changeOrderValueText, row2Xaxis, incrementalAxisY);
				row2Xaxis += doc.getStringUnitWidth(changeOrderValueText) * fontSizeForSubRow / doc.internal.scaleFactor;
			}

			row2Xaxis += 10;
			const currentTotalText = 'Current Total: ';
			doc.setFont(regularFont, 'normal');
			doc.setFontSize(fontSizeForSubRow);
			doc.text(currentTotalText, row2Xaxis, incrementalAxisY);
			row2Xaxis = row2Xaxis + doc.getStringUnitWidth(currentTotalText) * fontSizeForSubRow / doc.internal.scaleFactor;

			let currentTotal = invoiceOrorderValue;
			if (changeOrdersIndex !== -1) {
				currentTotal = currentTotal + this.commissionRptTabs[changeOrdersIndex].value;
			}

			const currentTotalValueText = this._CommissionService.convertCurrency(currentTotal || 0, this.customCurrencyOption);
			doc.setFont(boldFont, 'bold');
			doc.setFontSize(fontSizeForSubRow);
			doc.text(currentTotalValueText, row2Xaxis, incrementalAxisY);
			row2Xaxis += doc.getStringUnitWidth(currentTotalValueText) * fontSizeForSubRow / doc.internal.scaleFactor;

			if (companyImageBase64) {
				const imageWidth = 40;
				const imageHeight = 18;
				const imageXPosition = (pageWidth - imageWidth) - intialAxisX;
				const imageYPosition = intialAxisY;
				const imageProperties = doc.getImageProperties(companyImageBase64);
				doc.addImage(companyImageBase64, imageProperties.fileType.toLowerCase(), imageXPosition, imageYPosition, imageWidth, imageHeight);
			} else {
				const companyNameLabel = this._LoginService.loginUser.account_detail.company_name;
				doc.setFont(boldFont, 'bold');
				doc.setFontSize(18);
				doc.text(companyNameLabel, pageWidth - intialAxisX, intialAxisY, { align: 'right' });
			}

		}
	}

	private doCustomizeCell(gridCell: any, pdfCell: any) {
		pdfCell.borderColor = '#C4C4C4';
		pdfCell.drawLeftBorder = false;
		pdfCell.drawRightBorder = false;
		pdfCell.borderWidth = 0.5;
		pdfCell.textColor = '#000';
		pdfCell.wordWrapEnabled = true;
	}


	private doCustomizeDrawCell(gridCell: any, pdfCell: any) {
		const regularFont = 'proxima_nova';
		const semiBoldFont = 'proxima_nova semiBold';
		const boldFont = 'proxima_nova Bold';

		if (gridCell.rowType === 'header') {
			pdfCell.font.name = boldFont;
			pdfCell.font.style = 'bold';
			pdfCell.font.size = 9;
		}
		if (gridCell.rowType === 'data') {
			pdfCell.font.name = regularFont;
			pdfCell.font.style = 'normal';
			pdfCell.font.size = 9;
		}
		if (gridCell.rowType === 'totalFooter') {
			pdfCell.font.name = boldFont;
			pdfCell.font.style = 'bold';
			pdfCell.font.size = 9;
		}
	}
	doExportJsPdf() {
		const pdfDoc = new jsPDF('landscape', 'mm', 'legal');
		let fileName = '';
		if (this.commissionRptDetails?.apInvoiceNumber) {
			fileName = this.commissionRptDetails.apInvoiceNumber + '_';
		}
		if (this.commissionRptDetails?.subusername) {
			fileName = this.commissionRptDetails.subusername + '_';
		}
		fileName = fileName + 'Commission_Report.pdf';
		let arrayGridInstance: any[] = [];
		let tabNames: any[] = [];
		this.indexTab = 0;
		if (this.commissionRptTabs.length > 0) {
			this.commissionRptTabs.forEach(element => {
				arrayGridInstance.push(this.getDataGridInstance(element.key));
				tabNames.push(element.title);
			});
			this.recursiveAddGridToPdf(arrayGridInstance, pdfDoc, tabNames).then(() => {
				//if(this.indexTab)
				this.addHeaderToJsPdf(pdfDoc);
				this.addPageNumberToJsPdf(pdfDoc);
				pdfDoc.save(fileName);
			}
			)

		}
	}
	recursiveAddGridToPdf(grid, pdfDoc, tabNames) {
		const nextGrid = grid.shift();
		if (nextGrid) {
			return this.AddGridToPDF(pdfDoc, nextGrid, tabNames).then(_ => this.recursiveAddGridToPdf(grid, pdfDoc, tabNames))
		} else {
			return Promise.resolve();
		}

	}
	AddGridToPDF(pdfDoc, grid, tabNames) {
		const context = this;
		if (this.indexTab > 0) {
			pdfDoc.addPage();
		}
		const lastPoint = { x: 0, y: 0 };
		this.addFontToJsPdf(pdfDoc);
		const semiBoldFont = 'proxima_nova semiBold';
		let intialAxisX = 5;
		const intialAxisY = 15;
		let incrementalAxisY = 0;
		incrementalAxisY = 0;
		incrementalAxisY += 55;
		const tabLabel = tabNames[this.indexTab];
		pdfDoc.setFont(semiBoldFont, 'normal');
		pdfDoc.setFontSize(15);
		pdfDoc.text(tabLabel, intialAxisX, incrementalAxisY);
		incrementalAxisY += 5;
		// var options = {
		// 	jsPDFDocument: pdfDoc,
		// 	component: grid,
		// 	autoTableOptions: {
		// 		margin: { left: x },
		// 		startY: y,
		// 		tableWidth: tableWidth
		// 	}
		// };
		let tabObj =
		{
			jsPDFDocument: pdfDoc,
			component: grid,
			margin: { left: intialAxisX, right: intialAxisX, top: incrementalAxisY, bottom: intialAxisY },
			repeatHeaders: true,
			customizeCell(options) {
				const { gridCell, pdfCell } = options;
				context.doCustomizeCell(gridCell, pdfCell);
				if (gridCell.rowType === 'data' && gridCell.column.dataField === 'eligible_for_commission') {
					pdfCell.text = gridCell.value ? 'Yes' : 'No';
				}
			},
			customDrawCell(options) {
				const { gridCell, pdfCell, rect } = options;
				context.doCustomizeDrawCell(gridCell, pdfCell);
				if (context.indexTab == 0) {
					if (lastPoint.x < rect.x + rect.w) {
						lastPoint.x = rect.x + rect.w;
					}
					if (lastPoint.y < rect.y + rect.h) {
						lastPoint.y = rect.y + rect.h;
					}
				}
			},
			onRowExporting(options) {
				const { rowCells, rowHeight } = options;
			},
		}
		this.indexTab++;
		return exportDataGridPdf(tabObj);
	}

	doExportJsPdf1() {
		const context = this;
		let fileName = '';
		if (this.commissionRptDetails?.apInvoiceNumber) {
			fileName = this.commissionRptDetails.apInvoiceNumber + '_';
		}
		if (this.commissionRptDetails?.subusername) {
			fileName = this.commissionRptDetails.subusername + '_';
		}
		fileName = fileName + 'Commission_Report.pdf';

		const doc = new jsPDF('landscape', 'mm', 'legal');
		const lastPoint = { x: 0, y: 0 };

		/* Put into Center
		const contractName = this.invoiceDetails.contractName;
		const headerWidth = doc.getTextDimensions(contractName).w;
		doc.setFontSize(16);
		doc.text(contractName, (pageWidth - headerWidth) / 2, 20);
		*/
		this.addFontToJsPdf(doc);
		const regularFont = 'proxima_nova';
		const semiBoldFont = 'proxima_nova semiBold';
		const boldFont = 'proxima_nova Bold';
		let intialAxisX = 5;
		const intialAxisY = 15;
		const pageWidth = doc.internal.pageSize.getWidth();
		const pageHeight = doc.internal.pageSize.getHeight();

		let incrementalAxisY = 0;
		if (this.commissionRptTabs.length > 0) {
			incrementalAxisY = 0;
			incrementalAxisY += 55;
			const tabLabel = this.commissionRptTabs[0].title;
			doc.setFont(semiBoldFont, 'normal');
			doc.setFontSize(15);
			doc.text(tabLabel, intialAxisX, incrementalAxisY);
			incrementalAxisY += 5;
			let tabObj =
			{
				jsPDFDocument: doc,
				component: context.getDataGridInstance(this.commissionRptTabs[0].key),
				margin: { left: intialAxisX, right: intialAxisX, top: incrementalAxisY, bottom: intialAxisY },
				repeatHeaders: true,
				customizeCell(options) {
					const { gridCell, pdfCell } = options;
					context.doCustomizeCell(gridCell, pdfCell);
					if (gridCell.rowType === 'data' && gridCell.column.dataField === 'eligible_for_commission') {
						pdfCell.text = gridCell.value ? 'Yes' : 'No';
					}
				},
				customDrawCell(options) {
					const { gridCell, pdfCell, rect } = options;
					context.doCustomizeDrawCell(gridCell, pdfCell);
					if (lastPoint.x < rect.x + rect.w) {
						lastPoint.x = rect.x + rect.w;
					}
					if (lastPoint.y < rect.y + rect.h) {
						lastPoint.y = rect.y + rect.h;
					}
				},
				onRowExporting(options) {
					const { rowCells, rowHeight } = options;
				},
			}
			exportDataGridPdf(tabObj).then(() => {
				for (let index = 0; index < this.commissionRptTabs.length; index++) {
					if (index > 0) {
						tabObj['component'] = context.getDataGridInstance(this.commissionRptTabs[index].key);
						doc.addPage();
						incrementalAxisY = 0;
						incrementalAxisY += 55;
						const tabLabel = this.commissionRptTabs[index].title;
						doc.setFont(semiBoldFont, 'normal');
						doc.setFontSize(15);
						doc.text(tabLabel, intialAxisX, incrementalAxisY);
						incrementalAxisY += 5;
						return exportDataGridPdf(tabObj);
					}
				}
			}).then(() => {
				this.addHeaderToJsPdf(doc);
				this.addPageNumberToJsPdf(doc);
				doc.save(fileName);
			});
		}
	}

	onExporting() {
		const context = this;
		const workbook = new ExcelJS.Workbook();
		let companyName = '';
		let companyImageBase64 = '';
		let fileName = '';
		if (this._LoginService.loginUser.account_detail.company_name) {
			companyName = this._LoginService.loginUser.account_detail.company_name;
		}
		if (this._LoginService.loginUser.account_detail.companylogo) {
			companyImageBase64 = this._LoginService.loginUser.account_detail.companylogo_base64;
		}
		if (this.commissionRptDetails?.apInvoiceNumber) {
			fileName = this.commissionRptDetails.apInvoiceNumber + '_';
		}
		if (this.commissionRptDetails?.subusername) {
			fileName = this.commissionRptDetails.subusername + '_';
		}
		fileName = fileName + 'Commission_Report.xlsx';
		if (this.commissionRptTabs.length > 0) {
			for (let index = 0; index < this.commissionRptTabs.length; index++) {
				const tabSheet = workbook.addWorksheet(this.commissionRptTabs[index].title);
				let tabSheetObj =
				{
					worksheet: tabSheet,
					component: context.getDataGridInstance(this.commissionRptTabs[index].key),
					keepColumnWidths: true,
					autoFilterEnabled: false,
				}

				if (index === 0) {
					tabSheetObj['topLeftCell'] = { row: 12, column: 1 }; // skip first 12 row
					// add company logo
					tabSheet.mergeCells(1, 1, 3, 2);
					if (companyImageBase64) {
						const image = workbook.addImage({
							base64: companyImageBase64,
							//ext: { width: 500, height: 200 },
							extension: 'png',
							editAs: 'oneCell'
						});
						tabSheet.addImage(image, 'A1:B2');
					} else {
						tabSheet.getRow(1).getCell(1).font = { size: 20, bold: true };
						tabSheet.getRow(1).getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
						tabSheet.getRow(1).getCell(1).value = companyName;
					}
					// customize cell as per original value
					tabSheetObj['customizeCell'] = (options) => {
						const { gridCell, excelCell } = options;
						if (gridCell.rowType === "data") {
							if (gridCell.column.dataField === 'document_type') {
								const listOfDocTypeHeaderFilter = this.ctsInvoiceRptTabCom.listOfDocTypeHeaderFilter;
								const indexDT = listOfDocTypeHeaderFilter.findIndex(i => i.value === gridCell.value);
								excelCell.value = '';
								if (indexDT !== -1) {
									excelCell.value = listOfDocTypeHeaderFilter[indexDT].text;
								}
							}
							if (gridCell.column.dataField === 'uomcode' || gridCell.column.dataField === 'contract_selectedUom') {
								const listOfUoms = this.ctsInvoiceRptTabCom.listOfUoms;
								const indexDT = listOfUoms.findIndex(i => i.code === gridCell.value);
								excelCell.value = '';
								if (indexDT !== -1) {
									excelCell.value = listOfUoms[indexDT].name;
								}
							}
							if (gridCell.column.dataField === 'tier_basis') {
								const listOfTierBasis = this.ctsInvoiceRptTabCom.listOfTierBasis;
								const indexDT = listOfTierBasis.findIndex(i => i.code === gridCell.value);
								excelCell.value = '';
								if (indexDT !== -1) {
									excelCell.value = listOfTierBasis[indexDT].label;
								}
							}
							if (gridCell.column.dataField === 'commissionRate') {
								if (gridCell.data.commissionRate) {
									if (gridCell.data.commissionRateIn === '$') {
										excelCell.value = this._CustomCurrencyPipe.transform(gridCell.data.commissionRate || 0, this.customCurrencyOption);
									} else if (gridCell.data.commissionRateIn === '%') {
										const commissionRateVal = this._CustomNumberNoRoundingPipe.transform((+gridCell.data.commissionRate || 0))
										excelCell.value = commissionRateVal + gridCell.data.commissionRateIn;
									} else {
										excelCell.value = gridCell.data.commissionRate;
									}
								}
							}
							if (gridCell.column.dataField === 'commission_basis') {
								const listOfCommissionBasis = this.ctsInvoiceRptTabCom.listOfCommissionBasis;
								const indexDT = listOfCommissionBasis.findIndex(i => i.code === gridCell.value);
								excelCell.value = '';
								if (indexDT !== -1) {
									excelCell.value = listOfCommissionBasis[indexDT].label;
								}
							}
						}
					}
					//End customize cell as per original value
				}

				if (this.commissionRptTabs[index].key === this.CommissionSettlementReportSectionEnum.MODIFIED_INVOICES) {
					tabSheetObj['customizeCell'] = (options) => {
						const { gridCell, excelCell } = options;
						if (gridCell.rowType === "data") {
							if (gridCell.column.dataField === 'commissionRate') {
								if (gridCell.data.commissionRate) {
									if (gridCell.data.commissionRateIn === '$') {
										excelCell.value = this._CustomCurrencyPipe.transform(gridCell.data.commissionRate || 0, this.customCurrencyOption);
									} else if (gridCell.data.commissionRateIn === '%') {
										const commissionRateVal = this._CustomNumberNoRoundingPipe.transform((+gridCell.data.commissionRate || 0))
										excelCell.value = commissionRateVal + gridCell.data.commissionRateIn;
									} else {
										excelCell.value = gridCell.data.commissionRate;
									}
								}
							}
						}
					}
				}

				if (this.commissionRptTabs[index].key === this.CommissionSettlementReportSectionEnum.AMORTIZED_PAYMENTS) {
					// customize cell as per original value
					tabSheetObj['customizeCell'] = (options) => {
						const { gridCell, excelCell } = options;
						if (gridCell.rowType === "data") {
							if (gridCell.column.dataField === 'condition') {
								{
									excelCell.value = gridCell.data.current_payment + ' of ' + gridCell.data.number_of_payments;
								}
							}
						}
					}
				}
				exportDataGrid(tabSheetObj)
					.then(() => {
						if (index === 0) {
							// Excel Summary View
							const firstRow = tabSheet.getRow(5);
							const statusColor = this.commissionRptDetails.apInvoiceCreatedInERP === 'Processing' ? 'F99301' :
								this.commissionRptDetails.apInvoiceCreatedInERP === 'Open' ? '706cce' :
									this.commissionRptDetails.apInvoiceCreatedInERP === 'Error' ? 'FE5059' :
										this.commissionRptDetails.apInvoiceCreatedInERP === 'Success' ? '02C19F' : '';
							firstRow.height = 20;
							tabSheet.mergeCells('A5:B5');
							tabSheet.mergeCells('C5:H5');
							firstRow.getCell(1).font = { size: 14 };
							firstRow.getCell(1).value = 'Commission Report: ';
							firstRow.getCell(3).font = { size: 16, bold: true };
							firstRow.getCell(3).value = this.commissionRptDetails.contractName;
							firstRow.getCell(12).font = { size: 14 };
							firstRow.getCell(12).value = 'Status ';
							firstRow.getCell(13).value = this.commissionRptDetails.apInvoiceCreatedInERP;
							firstRow.getCell(13).font = { size: 14, color: { argb: statusColor }, bold: true };
							const secondRow = tabSheet.getRow(6);
							secondRow.height = 20;
							tabSheet.mergeCells('A6:B6');
							tabSheet.mergeCells('C6:H6');
							secondRow.getCell(1).font = { size: 14 };
							secondRow.getCell(1).value = 'Sales Rep: ';
							secondRow.getCell(3).font = { size: 14 };
							secondRow.getCell(3).value = this.commissionRptDetails.salesRep;
							secondRow.getCell(12).font = { size: 14 };
							secondRow.getCell(12).value = 'Generated: ';
							secondRow.getCell(13).font = { size: 14 };
							secondRow.getCell(13).value = this.commissionRptDetails.created_date;
							tabSheet.mergeCells('A7:B7');
							tabSheet.mergeCells('C7:H7');
							const thirdRow = tabSheet.getRow(7);
							thirdRow.getCell(1).font = { size: 14 };
							thirdRow.getCell(1).value = 'Settlement No.: ';
							thirdRow.getCell(3).font = { size: 14 };
							thirdRow.getCell(3).value = this.commissionRptDetails.ixcode;
							thirdRow.getCell(12).font = { size: 14 };
							thirdRow.getCell(12).value = 'Settlement Period: ';
							thirdRow.getCell(13).font = { size: 14 };
							thirdRow.getCell(13).value = this.commissionRptDetails.settlementPeriodStartDate + ' To ' + this.commissionRptDetails.settlementPeriodEndDate;
							const fifthRow = tabSheet.getRow(9);
							const sixthRow = tabSheet.getRow(10);
							if (this.commissionRptTabs.length > 0) {
								for (let index = 0; index < this.commissionRptTabs.length; index++) {
									const element = this.commissionRptTabs[index];
									fifthRow.getCell(1 + index).value = element.title;
									fifthRow.getCell(1 + index).font = { size: 14 };
									sixthRow.getCell(1 + index).value = this._CommissionService.convertCurrency(element.value, this.customCurrencyOption);
									sixthRow.getCell(1 + index).font = { size: 14, bold: true };
								}
							}
							tabSheet.mergeCells('H9:J9');
							tabSheet.mergeCells('H10:J10');
							tabSheet.mergeCells('K9:M9');
							tabSheet.mergeCells('K10:M10');
							fifthRow.getCell(10).font = { size: 14 };
							fifthRow.getCell(10).value = 'Total Commission Payable';
							fifthRow.getCell(12).font = { size: 14 };
							const txt = this.enable_incentive_accrual_journal_entry ? 'Accrued' : 'Generated'
							fifthRow.getCell(12).value = 'Total Commission ' + txt;

							sixthRow.getCell(10).font = { size: 14, bold: true };
							sixthRow.getCell(10).value = this._CommissionService.convertCurrency(this.commissionRptDetails.totalCommissionPayable, this.customCurrencyOption);
							sixthRow.getCell(12).font = { size: 14, bold: true };
							sixthRow.getCell(12).value = this._CommissionService.convertCurrency(this.commissionRptDetails.totalCommissionGenerated, this.customCurrencyOption);
							tabSheet.getCell('H9').border = {
								top: { style: 'thin', color: { argb: '05C1A0' } },
								left: { style: 'thin', color: { argb: '05C1A0' } },
							};
							tabSheet.getCell('K9').border = {
								top: { style: 'thin', color: { argb: '05C1A0' } },
								right: { style: 'thin', color: { argb: '05C1A0' } }
							};
							tabSheet.getCell('H10').border = {
								bottom: { style: 'thin', color: { argb: '05C1A0' } },
								left: { style: 'thin', color: { argb: '05C1A0' } },
							};
							tabSheet.getCell('K10').border = {
								bottom: { style: 'thin', color: { argb: '05C1A0' } },
								right: { style: 'thin', color: { argb: '05C1A0' } }
							};
							tabSheet.getCell('H9').fill = {
								type: 'pattern',
								pattern: 'darkVertical',
								fgColor: { argb: 'CEF2EC' },
								bgColor: { argb: 'CEF2EC' }
							}
							tabSheet.getCell('K9').fill = {
								type: 'pattern',
								pattern: 'darkVertical',
								fgColor: { argb: 'CEF2EC' },
								bgColor: { argb: 'CEF2EC' }
							}
							tabSheet.getCell('H10').fill = {
								type: 'pattern',
								pattern: 'darkVertical',
								fgColor: { argb: 'CEF2EC' },
								bgColor: { argb: 'CEF2EC' }
							}
							tabSheet.getCell('K10').fill = {
								type: 'pattern',
								pattern: 'darkVertical',
								fgColor: { argb: 'CEF2EC' },
								bgColor: { argb: 'CEF2EC' }
							}

							// End Excel Summary View
						} else {
							return exportDataGrid(tabSheetObj);
						}
					})
					//last index export file 
					.then(() => {
						if (index === (this.commissionRptTabs.length - 1)) {
							workbook.xlsx.writeBuffer().then(function (buffer) {
								saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
							})
						}
					}
					)
			}
		} else {
			this._ToastrService.info('Data Not Found !', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
	}

	getDataGridInstance(tabKey) {
		let returnIns: any;
		switch (tabKey) {
			case CommissionSettlementReportSectionEnum.INVOICES:
				const invoiceDataGridIns = this.ctsInvoiceRptTabCom.invoiceDataGridRef.instance;
				returnIns = invoiceDataGridIns;
				break;
			case CommissionSettlementReportSectionEnum.ORDERS:
				const orderDataGridIns = this.ctsOrderRptTabCom.orderDataGridRef.instance;
				returnIns = orderDataGridIns;
				break;
			case CommissionSettlementReportSectionEnum.CHANGE_ORDERS:
				const chnageOrderDataGridIns = this.ctsChnageOrderRptTabCom.changeOrderDataGridRef.instance;
				returnIns = chnageOrderDataGridIns;
				break;
			case CommissionSettlementReportSectionEnum.MODIFIED_INVOICES:
				const modifiedInvoiceDataGridIns = this.ctsModifiedInvoiceRptTabCom.modifiedInvoiceDataGridRef.instance;
				returnIns = modifiedInvoiceDataGridIns;
				break;
			case CommissionSettlementReportSectionEnum.AMORTIZED_PAYMENTS:
				const amortizedPaymentDataGridIns = this.ctsAmortizedPaymentRptTabCom.amortizedPaymentDataGridRef.instance;
				returnIns = amortizedPaymentDataGridIns;
				break;
			case CommissionSettlementReportSectionEnum.EXTRA_COMMISSION_BASED_RULES:
				const extraCommBasedruleDataGridIns = this.ctsExtraCommBasedruleRptTabCom.ExtraCommBasedruleDataGridRef.instance;
				returnIns = extraCommBasedruleDataGridIns;
				break;
			case CommissionSettlementReportSectionEnum.SERVICE_CREDIT_MEMOS:
				const serviceCreditMemoDataGridIns = this.ctsServiceCreditMemoRptTabCom.serviceCreditMemoDataGridRef.instance;
				returnIns = serviceCreditMemoDataGridIns;
				break;
			case CommissionSettlementReportSectionEnum.MISCELLANEOUS_DEDUCTION_RULES:
				const miscDeductruleDataGridIns = this.ctsMiscDeductruleRptTabComponent.miscDeductruleDataGridRef.instance;
				returnIns = miscDeductruleDataGridIns;
				break;
			// default:
			// 	break;
		}
		return returnIns;
	}

	exportBackendRequest() {
		let viewTabSection: any[] = []
		if (this.commissionRptTabs.length > 0) {
			this.commissionRptTabs.forEach(elementTab => {
				viewTabSection.push(elementTab.section);
			});
		}
		if (viewTabSection.length == 0) {
			this._ToastrService.info('Data Not Found !', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('entity', ServerEntity.AP_INVOICE_REPORT);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('contractguid', this.commissionRptDetails.contractGuid);
		formData.append('contracttype', ContractTypeValEnum.COMMISSIONCONTRACTS);
		formData.append('section', JSON.stringify(viewTabSection));
		formData.append('action', 'export');
		if (this.getCommssionReportExportDataSbsn) {
			this.getCommssionReportExportDataSbsn.unsubscribe();
		}
		this._LoaderService.show();
		this.getCommssionReportExportDataSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: response => {
				this._LoaderService.hide();

				if (response && response.flag) {

				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	//#region close settlement period

	doActionCloseCurrentSettlementPeriod() {
		const ixCustomDialogOptions: any = {
			popupIconsHtml: '<div class="closeSettlementIconPopupIx"><div class="infoIcon mrgT0"></div></div>',
			title: '',
			showTitle: false,
			subtitle: 'Close Settlement',
			message: 'Are you sure you want to close current settlement period? <br/>You will not be able to undo the operation.'
		};
		this._IxDxAlertService.doShowCustomConfirmAlert(ixCustomDialogOptions).then(result => {
			if (result) {
				const IsOpenMultiSettlmentPopUp = this._CommissionService.getIsOpenMultiSettlmentPopUp();
				if (IsOpenMultiSettlmentPopUp) {
					this._ToastrService.info('The previous process is still in progress. Please try after that complete.', 'Info', { closeButton: true, tapToDismiss: true });
					return;
				}
				if (this.commissionRptDetails.contractGuid !== undefined) {
					// this.calculateSettlementPeriod();
					this.callCloseSettlementReq();
				}
			}
		});
	}

	callCloseSettlementReq() {
		const formData = new FormData();
		this._LoaderService.show();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.CLOSE_CURRENT_SETTELEMENT);
		formData.append('contractguid', this.commissionRptDetails.contractGuid);
		formData.append('contracttype', ContractTypeValEnum.COMMISSIONCONTRACTS);
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						if (response.data) {
							const closeSettlementData = response.data['closesettlement'];
							if (closeSettlementData && closeSettlementData.length > 0) {
								this.commissionRptDetails.ixcode = closeSettlementData[0]['ixcode'];
								this.commissionRptDetails.apInvoiceCreatedInERP = closeSettlementData[0]['apInvoiceCreatedInERP'];
								this.commissionRptDetails.created_date = closeSettlementData[0]['created_date'];
								this.commissionRptDetails.settlementValue = closeSettlementData[0]['settlementValue'];
								this.commissionRptDetails.totalCommissionGenerated = closeSettlementData[0]['totalCommissionGenerated'];
								this.ngOnInit();
							}
						}
					} else {
						this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			},
			error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	//#endregion

	//#region update settlement period
	doActionUpdateCurrentSettlement() {
		this._LoaderService.show();
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.UPDATE_CURRENT_SETTELEMENT_PERIOD);
		formData.append('contractguid', this.commissionRptDetails.contractGuid);
		formData.append('contracttype', ContractTypeValEnum.COMMISSIONCONTRACTS);
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag && response.data) {
						if (response.data['settlementValue']) {
							this.commissionRptDetails.settlementValue = response.data['settlementValue'];
						}
						if (response.data['totalCommissionGenerated']) {
							this.commissionRptDetails.totalCommissionGenerated = response.data['totalCommissionGenerated'];
						}
						this.ngOnInit();
					} else {
						this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			},
			error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}
	//#endregion

	public loadRptTab() {
		this.commissionRptTabs = this._CommissionService.getCommissionSttlementReportTab();
		if (this.commissionRptDetails.calculate_commission_by === CommissionSettlementReportSectionEnum.INVOICES) {
			const indexOrders = this.commissionRptTabs.findIndex(i => i.key === CommissionSettlementReportSectionEnum.ORDERS);
			if (indexOrders !== -1) {
				this.commissionRptTabs.splice(indexOrders, 1);
			}
			const indexOrdersChanges = this.commissionRptTabs.findIndex(i => i.key === CommissionSettlementReportSectionEnum.CHANGE_ORDERS);
			if (indexOrdersChanges !== -1) {
				this.commissionRptTabs.splice(indexOrdersChanges, 1);
			}
			this.currentRptTab = this.commissionRptTabs[0].key;
		}
		if (this.commissionRptDetails.calculate_commission_by === CommissionSettlementReportSectionEnum.ORDERS) {
			const indexInvoices = this.commissionRptTabs.findIndex(i => i.key === CommissionSettlementReportSectionEnum.INVOICES);
			if (indexInvoices !== -1) {
				this.commissionRptTabs.splice(indexInvoices, 1);
			}
			this.currentRptTab = this.commissionRptTabs[0].key;
		}
		if (!this.enable_amortise_payments_for_commission) {
			const indexAmortisePayment = this.commissionRptTabs.findIndex(i => i.key === CommissionSettlementReportSectionEnum.AMORTIZED_PAYMENTS);
			if (indexAmortisePayment !== -1) {
				this.commissionRptTabs.splice(indexAmortisePayment, 1);
			}
		}
		if (!this.enable_incremental_payment) {
			const indexModifiedInvoices = this.commissionRptTabs.findIndex(i => i.key === CommissionSettlementReportSectionEnum.MODIFIED_INVOICES);
			if (indexModifiedInvoices !== -1) {
				this.commissionRptTabs.splice(indexModifiedInvoices, 1);
			}
		}
		this.refreshFilterValue();
	}

	refreshFilterValue() {
		if (this.ctsInvoiceRptTabCom) {
			this.ctsInvoiceRptTabCom.updateStatesClearBtn();
		}
		if (this.ctsOrderRptTabCom) {
			this.ctsOrderRptTabCom.updateStatesClearBtn();
		}
		if (this.ctsChnageOrderRptTabCom) {
			this.ctsChnageOrderRptTabCom.updateStatesClearBtn();
		}
		if (this.ctsModifiedInvoiceRptTabCom) {
			this.ctsModifiedInvoiceRptTabCom.updateStatesClearBtn();
		}
		if (this.ctsAmortizedPaymentRptTabCom) {
			this.ctsAmortizedPaymentRptTabCom.updateStatesClearBtn();
		}
		if (this.ctsExtraCommBasedruleRptTabCom) {
			this.ctsExtraCommBasedruleRptTabCom.updateStatesClearBtn();
		}
		if (this.ctsMiscDeductruleRptTabComponent) {
			this.ctsMiscDeductruleRptTabComponent.updateStatesClearBtn();
		}
		if (this.ctsServiceCreditMemoRptTabCom) {
			this.ctsServiceCreditMemoRptTabCom.updateStatesClearBtn();
		}
	}

	clickRptTab(tabKey) {
		this.currentRptTab = tabKey;
	}

	public getAllSectionTotal() {
		const formData = new FormData();
		this.isShowLoaderIndicator = true;
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.AP_INVOICE_REPORT);
		formData.append('section', 'all');
		formData.append('contracttype', ContractTypeValEnum.COMMISSIONCONTRACTS);
		formData.append('contractguid', this.commissionRptDetails.contractGuid);
		if (this.dataOutAllSecReqSubscription) {
			this.dataOutAllSecReqSubscription.unsubscribe();
		}
		this.dataOutAllSecReqSubscription = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this.isShowLoaderIndicator = false;
				if (response.flag) {
					if (response.data && response.data.length > 0) {
						const sectionLstObj = response.data[0] || [];
						this.commissionRptTabs.forEach(element => {
							switch (element.key) {
								case CommissionSettlementReportSectionEnum.INVOICES:
									element.value = sectionLstObj.hasOwnProperty('invoices') ? sectionLstObj['invoices'] : 0;
									break;
								case CommissionSettlementReportSectionEnum.ORDERS:
									element.value = sectionLstObj.hasOwnProperty('orders') ? sectionLstObj['orders'] : 0;
									break;
								case CommissionSettlementReportSectionEnum.CHANGE_ORDERS:
									element.value = sectionLstObj.hasOwnProperty('changeOrders') ? sectionLstObj['changeOrders'] : 0;
									break;
								case CommissionSettlementReportSectionEnum.MODIFIED_INVOICES:
									element.value = sectionLstObj.hasOwnProperty('changeInvoices') ? sectionLstObj['changeInvoices'] : 0;
									break;
								case CommissionSettlementReportSectionEnum.EXTRA_COMMISSION_BASED_RULES:
									element.value = sectionLstObj.hasOwnProperty('extraCommissionBasedRules') ? sectionLstObj['extraCommissionBasedRules'] : 0;
									break;
								case CommissionSettlementReportSectionEnum.AMORTIZED_PAYMENTS:
									element.value = sectionLstObj.hasOwnProperty('amortizedPayments') ? sectionLstObj['amortizedPayments'] : 0;
									break;
								case CommissionSettlementReportSectionEnum.SERVICE_CREDIT_MEMOS:
									element.value = sectionLstObj.hasOwnProperty('serviceArcreditmemos') ? sectionLstObj['serviceArcreditmemos'] : 0;
									break;
								// case CommissionSettlementReportSectionEnum.MISCELLANEOUS_DEDUCTION_RULES:
								// 	element.value = sectionLstObj.hasOwnProperty('miscellaneousDeductionRules') ? sectionLstObj['miscellaneousDeductionRules'] : 0;
								// 	break;
								default:
									break;
							}
						});

					}

				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this.isShowLoaderIndicator = false;
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}
}
