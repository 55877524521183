import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '@app/services/login.service';
import { SalesDataSharingService } from '@app/services/sales-service/sales-data-sharing.service';
import { BusinessPlanDataSharingService } from '@app/services/business-plan-service/business-plan-data-sharing.service';
import { CrmsService } from '@app/services/crms-service/crms-service.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { ProductServiceService } from '@app/services/product-service/product-service.service';
import { Login } from '@app/models/auth';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { ApCustomersService } from '@app/services/ap-services/ap-customers-service/ap-customers.service';
// import { UserIdleService } from 'angular-user-idle';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { CRMTabValEnum, SalesTabsValEnum, AppsCofigsTabValEnum, SubscriptionPlansTabValEnum } from '@app/constants-enums/enums';
import { SidemenuService } from '@app/services/sidemenu-service/sidemenu.service';
import { ApSubscriptionPlansService } from '@app/services/ap-services/ap-subscription-plans-service/ap-subscription-plans.service';

declare function callGeneralJs(): any;
declare function callSidemenu(): any;


@Component({
	selector: 'app-ap-sidemenu',
	templateUrl: './ap-sidemenu.component.html',
	styleUrls: ['./ap-sidemenu.component.css']
})
export class ApSidemenuComponent implements OnInit, AfterViewInit {
	loginUser: Login = new Login();
	apCurrentTab: string;
	constructor(private router: Router,
		private activatedRoute: ActivatedRoute,
		private loginService: LoginService,
		private _loaderService: LoaderService,
		private _restApiService: RestApiService,
		private _GlobalStateService: GlobalStateService,
		public _CrmsService: CrmsService,
		public _BusinessPlanDataSharingService: BusinessPlanDataSharingService,
		private _ApSubscriptionPlansService: ApSubscriptionPlansService,
		public _SalesDataSharingService: SalesDataSharingService,
		public _AppCommonSrvc: AppCommonSrvc,
		private _ApCustomersService: ApCustomersService,
		// private userIdle: UserIdleService,
		public _SidemenuService: SidemenuService,
		private _PermissionsService: NgxPermissionsService) {
		// this.router = _router;
	}

	ngOnInit() {
		this.loginUser = this.loginService.loginUser;
		this.apCurrentTab = 'dashboard';
		this._SidemenuService.setCurrentMenu(this.apCurrentTab);
	}

	ngAfterViewInit() {
		callGeneralJs();
	}

	sideMenuClick(sidemenuadminportal) {
		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		const permsn = [];
		for (const per in permissions) {
			permsn.push(per);
		}
		// End Get current permissions
		// use for removing responsive side menu class in general.js
		callSidemenu();
		this.apCurrentTab = sidemenuadminportal;
		this._SidemenuService.setCurrentMenu(this.apCurrentTab);
		switch (sidemenuadminportal) {
			case 'dahboard':
				this.router.navigate(['adminportal', 'dashboard']);
				break;
			case 'customers':
				this._ApCustomersService.setSelectedCustomerTab(undefined);
				this._ApCustomersService.setStoredFilters(undefined);
				this.router.navigate(['adminportal', 'customers']);
				break;

			case 'subscription-plans':
				this._ApSubscriptionPlansService.setTabSelection(SubscriptionPlansTabValEnum.SUBSCRIPTION_PLANS);
				this.router.navigate(['adminportal', 'subscriptionplans', 'subscription-plans-list']);
				break;
			case 'module-permissions':
				this._ApSubscriptionPlansService.setTabSelection(SubscriptionPlansTabValEnum.MODULE_PERMISSIONS);
				this.router.navigate(['adminportal', 'subscriptionplans', 'module-permissions-list']);
				break;
			case 'user-type-creation':
				this._ApSubscriptionPlansService.setTabSelection(SubscriptionPlansTabValEnum.USER_TYPE_CREATION);
				this.router.navigate(['adminportal', 'subscriptionplans', 'user-type-creation-list']);
				break;

			case 'usersmanagements':
				this.router.navigate(['adminportal', 'usermanagements', 'userslist']);
				break;
			case 'usermanagement':
				this.router.navigate(['adminportal', 'usermanagements', 'userslist']);
				break;
			case 'rolesmanagement':
				this.router.navigate(['adminportal', 'usermanagements', 'roleslist']);
				break;
			case 'userprofile':
				this.router.navigate(['adminportal', 'usermanagements', 'userprofile']);
				break;
			// #region for INCENTIVE
			case 'incentives':
				if (permsn.indexOf('INCENTIVE_COMMISSION_VIEW') !== -1) {
					this.router.navigate(['adminportal', 'contracts', 'commissionlist']);
				} else if (permsn.indexOf('INCENTIVE_REBATES_VIEW') !== -1) {
					this.router.navigate(['adminportal', 'contracts', 'rebateslist']);
				} else if (permsn.indexOf('INCENTIVE_ROYALTIES_VIEW') !== -1) {
					this.router.navigate(['adminportal', 'contracts', 'royaltieslist']);
				} else if (permsn.indexOf('INCENTIVE_BILLBACKCHARGEBACK_VIEW') !== -1) {
					this.router.navigate(['adminportal', 'contracts', 'billbacklist']);
				}
				break;
			case 'commission':
				// this.router.navigate(['/contracts/list']);
				this.router.navigate(['adminportal', 'contracts', 'commissionlist']);
				break;
			case 'rebates':
				this.router.navigate(['adminportal', 'contracts', 'rebateslist']);
				break;
			case 'royalties':
				this.router.navigate(['adminportal', 'contracts', 'royaltieslist']);
				break;
			case 'billbacks':
				this.router.navigate(['adminportal', 'contracts', 'billbacklist']);
				break;
			// #endregion

			// #region for CRMS
			case 'crms':
				if (permsn.indexOf('CRM_LEAD_VIEW') !== -1) {
					this._CrmsService.setCrmSelectedtab(CRMTabValEnum.LEAD);
					this._CrmsService.setStoredFilters(undefined);
					this.router.navigate(['adminportal', 'crms', 'leadslist']);
				} else if (permsn.indexOf('CRM_PROSPECT_VIEW') !== -1) {
					this._CrmsService.setCrmSelectedtab(CRMTabValEnum.PROSPECT);
					this._CrmsService.setStoredFilters(undefined);
					this.router.navigate(['adminportal', 'crms', 'prospectslist']);
				} else if (permsn.indexOf('CRM_CUSTOMER_VIEW') !== -1) {
					this._CrmsService.setCrmSelectedtab(CRMTabValEnum.CLIENT);
					this._CrmsService.setStoredFilters(undefined);
					this.router.navigate(['adminportal', 'crms', 'clientslist']);
				} else if (permsn.indexOf('CRM_TASK_VIEW') !== -1) {
					this._CrmsService.setCrmSelectedtab(CRMTabValEnum.TASKS);
					this._CrmsService.setStoredFilters(undefined);
					this.router.navigate(['adminportal', 'crms', 'taskslist']);
				}
				break;
			case 'leads':
				this._CrmsService.setCrmSelectedtab(CRMTabValEnum.LEAD);
				this._CrmsService.setStoredFilters(undefined);
				this.router.navigate(['adminportal', 'crms', 'leadslist']);
				break;
			case 'prospects':
				this._CrmsService.setCrmSelectedtab(CRMTabValEnum.PROSPECT);
				this._CrmsService.setStoredFilters(undefined);
				this.router.navigate(['adminportal', 'crms', 'prospectslist']);
				break;
			case 'clients':
				this._CrmsService.setCrmSelectedtab(CRMTabValEnum.CLIENT);
				this._CrmsService.setStoredFilters(undefined);
				this.router.navigate(['adminportal', 'crms', 'clientslist']);
				break;
			case 'tasks':
				this._CrmsService.setCrmSelectedtab(CRMTabValEnum.TASKS);
				this._CrmsService.setStoredFilters(undefined);
				this.router.navigate(['adminportal', 'crms', 'taskslist']);
				break;
			// #endregion

			// #region for SALES
			case 'sales':
				/*
					if (permsn.indexOf('SALES_QUOTATION_VIEW') !== -1) {
						this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.QUOTATIONS);
						this._SalesDataSharingService.setStoredFilters(undefined);
						this.router.navigate(['adminportal', 'sales', 'quotationslist']);
					} else if (permsn.indexOf('SALES_ORDER_VIEW') !== -1) {
						this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.ORDERS);
						this._SalesDataSharingService.setStoredFilters(undefined);
						this.router.navigate(['adminportal', 'sales', 'orderslist']);
					} else if (permsn.indexOf('SALES_ARINVOIECES_VIEW') !== -1) {
						this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.SALESINVOICES);
						this._SalesDataSharingService.setStoredFilters(undefined);
						this.router.navigate(['adminportal', 'sales', 'invoiceslist']);
					} else if (permsn.indexOf('SALES_ARCREDITMEMO_VIEW') !== -1) {
						this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.ARINVOICES);
						this._SalesDataSharingService.setStoredFilters(undefined);
						this.router.navigate(['adminportal', 'sales', 'ar-invoices-list']);
					}
					*/

				if (permsn.indexOf('SALES_ARINVOIECES_VIEW') !== -1) {
					this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.BILLING_INVOICES);
					this._SalesDataSharingService.setStoredFilters(undefined);
					this.router.navigate(['adminportal', 'sales', 'billing-invoices-list']);
				}
				break;
			case 'quotes':
				/*
					this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.QUOTATIONS);
					this._SalesDataSharingService.setStoredFilters(undefined);
					this.router.navigate(['adminportal', 'sales', 'quotationslist']);
					*/
				break;
			case 'salesorders':
				/*
					this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.ORDERS);
					this._SalesDataSharingService.setStoredFilters(undefined);
					this.router.navigate(['adminportal', 'sales', 'orderslist']);
					*/
				break;
			case 'salesinvoices':
				/*
					this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.SALESINVOICES);
					this._SalesDataSharingService.setStoredFilters(undefined);
					this.router.navigate(['adminportal', 'sales', 'invoiceslist']);
					*/
				break;
			case 'arinvoiceslist':
				/*
					this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.ARINVOICES);
					this._SalesDataSharingService.setStoredFilters(undefined);
					this.router.navigate(['adminportal', 'sales', 'ar-invoices-list']);
					*/
				break;
			case 'invoices':
				this._SalesDataSharingService.setTabSelection(SalesTabsValEnum.BILLING_INVOICES);
				this._SalesDataSharingService.setStoredFilters(undefined);
				this.router.navigate(['adminportal', 'sales', 'billing-invoices-list']);
				break;
			// #endregion

			// #region for APPLICATION CONFIGURATION
			case 'apps-configs':
				if (permsn.indexOf('EXTERNAL_SYSTEM_CONFIG_VIEW') !== -1) {
					this._AppCommonSrvc.setSelectedApplicationConfigsTab(AppsCofigsTabValEnum.EXTERNALSYSCONFIGS);
					this.router.navigate(['adminportal', 'apps-configs', 'externalsysconfig']);
				} else if (permsn.indexOf('FISCAL_CALCULATION_OPTIONS_VIEW') !== -1) {
					this._AppCommonSrvc.setSelectedApplicationConfigsTab(AppsCofigsTabValEnum.FISCALCALCULATIONOPTIONS);
					this.router.navigate(['adminportal', 'apps-configs', 'fiscalcalcoptions']);
				} else if (permsn.indexOf('SYSTEM_CONFIGURATIONS_VIEW') !== -1) {
					this._AppCommonSrvc.setSelectedApplicationConfigsTab(AppsCofigsTabValEnum.SYSTEMCONFIGURATIONS);
					this.router.navigate(['adminportal', 'apps-configs', 'systemconfigurations']);
				}
				break;
			case 'externalsystemconfiguration':
				this._AppCommonSrvc.setSelectedApplicationConfigsTab(AppsCofigsTabValEnum.EXTERNALSYSCONFIGS);
				this.router.navigate(['adminportal', 'apps-configs', 'externalsysconfig']);
				break;
			case 'fiscalcalculationoptions':
				this._AppCommonSrvc.setSelectedApplicationConfigsTab(AppsCofigsTabValEnum.FISCALCALCULATIONOPTIONS);
				this.router.navigate(['adminportal', 'apps-configs', 'fiscalcalcoptions']);
				break;
			case 'systemconfigurations':
				this._AppCommonSrvc.setSelectedApplicationConfigsTab(AppsCofigsTabValEnum.SYSTEMCONFIGURATIONS);
				this.router.navigate(['adminportal', 'apps-configs', 'systemconfigurations']);
				break;
			case 'dynamicfields':
				this._AppCommonSrvc.setSelectedApplicationConfigsTab(AppsCofigsTabValEnum.DYNAMICFIELDS);
				this.router.navigate(['adminportal', 'apps-configs', 'dynamicfields']);
				break;
			// #endregion
			default:
				this.router.navigate(['adminportal', 'dashboard']);
				break;
		}
	}

	logout() {
		const formdata = 'usr=' + this.loginService.loginUser.user + '&' + 'token=' + this.loginService.loginUser.token + '&logout=1';
		this._loaderService.show();
		this._restApiService.doLoginReq(formdata).subscribe(response => {
			this._loaderService.hide();
			this._PermissionsService.flushPermissions();
			// this.userIdle.stopTimer();
			// this.userIdle.stopWatching();
			this.loginService.loginUser = undefined;
			this._GlobalStateService.notifyDataChangedDuplicate('TOKEN_REFRESHER_INTERVAL_EVENT', new Date().getTime());
			this.router.navigate(['/auth/login'], { replaceUrl: true });
		}, error => {
			console.error(error);
		});
	}
}
