<div *ngxPermissionsOnly="isCurrentTabPermission">
	<div class="row" *ngIf="!enable_classification_for_commision">
		<div class="cols12 radioGroup padT0">
			<div class="feildCol mrgT0 feildColAuto radio">
				<input name="exclusionsgroupradiobutton" type="radio" value="addproductexclusion" id="addProductExclusion"
					(click)="clickExclusionsGroupRadioButton('addproductexclusion')" [(ngModel)]="exclusionsGroupRadioButton"
					checked>
				<label for="addProductExclusion" class="radio-label">Items</label>
				<input name="exclusionsgroupradiobutton" type="radio" value="addgroupexclusion" id="addGroupExclusion"
					(click)="clickExclusionsGroupRadioButton('addgroupexclusion')" [(ngModel)]="exclusionsGroupRadioButton">
				<label for="addGroupExclusion" class="radio-label">Item Groups</label>
			</div>
		</div>
	</div>
	<div class="row proSearch">
		<div class="cols5" *ngIf="exclusionsGroupRadioButton === 'addproductexclusion'">
			<div class="dx-field feildCol" *ngIf="!is_use_territory_for_commission_contract">
				<div class="dx-field-label">Customers</div>
				<div class="dx-field-value" style="width: 100%;">
					<dx-drop-down-box [dataSource]="customerDataSource" [(value)]="lengthSelectedCustomersAndGroups"
						[displayExpr]="doDisplayExprCustomerGroupText" valueExpr="code" placeholder="Select Customer(s)..."
						[showClearButton]="true" (onValueChanged)="onCustomerValueChanged($event)">
						<div *dxTemplate="let data of 'content'">
							<!-- <div class="tabbing cf">
								<ul>
									<li (click)="clickCustomersOrGroupTab($event,'customers')"
										[ngClass]="{active: customerOrGroupTab == 'customers'}">
										<a>Customers</a>
									</li>
									<li (click)="clickCustomersOrGroupTab($event,'groups')"
										[ngClass]="{active: customerOrGroupTab == 'groups'}">
										<a>Groups</a>
									</li>

								</ul>
							</div>-->
							<div *ngIf="customerOrGroupTab == 'customers'">
								<dx-check-box text="All Customers" (onValueChanged)="checkAllCustomer()"
									[(value)]="chkAllCustomer" class="mrgB10">
								</dx-check-box>
								<dx-check-box text="Assigned Customer(s)" (onValueChanged)="checkAssignedCustomer()"
									[(value)]="chkAssignedCustomer" class="mrgB10">
								</dx-check-box>
								<dx-check-box text="By Transaction" (onValueChanged)="checkByTransaction()"
									[(value)]="chkByTransaction" class="mrgB10">
								</dx-check-box>
								<dx-check-box text="Sales Team Transaction" (onValueChanged)="checkSalesTeantransaction()"
									[(value)]="chkSalesTeamTransaction" class="mrgB10">
								</dx-check-box>
								<!--<dx-check-box text="My Customer(s)" (onValueChanged)="checkMyCustomers()"
									[(value)]="chkMyCustomers" class="mrgB10" width="30%">
								</dx-check-box>-->
								<dx-tag-box [(value)]="arrSelectedCustomerList" [grouped]="true"></dx-tag-box>
								<dx-data-grid #customerDataGrid id="gridContainer" [dataSource]="customerDataSource"
									[columns]="customerGridBoxColumns" [selection]="{ mode: 'multiple' }"
									[hoverStateEnabled]="true" [paging]="{ enabled: true, pageSize: 10 }"
									[allowColumnReordering]="true" [allowColumnResizing]="true" [filterRow]="{ visible: true }"
									[height]="345" [(selectedRowKeys)]="arrSelectedCustomerList" [showBorders]="true"
									[remoteOperations]="true" (onSelectionChanged)="onCustomerSelectionChanged($event)">
									<dxo-scrolling mode="virtual" rowRenderingMode="virtual" [scrollByThumb]="true"
										[showScrollbar]="'always'" [useNative]="false">
									</dxo-scrolling>
									<dxo-selection [allowSelectAll]="false"></dxo-selection>
								</dx-data-grid>
							</div>
							<div *ngIf="customerOrGroupTab === 'groups'">
								<dx-check-box text="All Groups" (onValueChanged)="checkAllGroup()"
									[(value)]="chkAllCustomrGroup" class="mrgB10" width="25%">
								</dx-check-box>
								<dx-tag-box [(value)]="arrSelectedCustomerGroupList" [grouped]="true"></dx-tag-box>
								<dx-data-grid #customerGroupDataGrid id="gridContainer" [dataSource]="groupDataSource"
									[allowColumnReordering]="true" [allowColumnResizing]="true"
									[masterDetail]="{ enabled: true, template: 'userListTemplate','autoExpandAll': false }"
									[selection]="{mode: 'multiple'}" [hoverStateEnabled]="true"
									[paging]="{ enabled: true, pageSize: 10 }" [filterRow]="{ visible: true }" [height]="345"
									[(selectedRowKeys)]="arrSelectedCustomerGroupList" [showBorders]="true"
									[remoteOperations]="true" (onSelectionChanged)="onCustomerGroupSelectionChanged($event)">

									<dxi-column caption="Group" dataField="group_name" [allowEditing]="false"
										cellTemplate="cellTemplateGroupName" [allowHeaderFiltering]="false"
										[allowFiltering]="true" [allowSorting]="true">
										<div *dxTemplate="let element of 'cellTemplateGroupName'">
											{{element.data.group_name || ''}}
										</div>
									</dxi-column>
									<div *dxTemplate="let c of 'userListTemplate'">
										<dx-data-grid #userListDetailssDataGridRef
											id="userListDetailssDataGridRef_{{c.data.guid}}" [allowColumnReordering]="true"
											[allowColumnResizing]="true" [dataSource]="getUserListByGroupDetails(c)"
											[remoteOperations]="false" keyExpr="sub_account_id" [showBorders]="true"
											[columnAutoWidth]="true">
											<dxo-filter-row [visible]="true"></dxo-filter-row>
											<dxo-filter-panel [visible]="false"></dxo-filter-panel>
											<dxo-header-filter [visible]="false"></dxo-header-filter>
											<!-- 
											<dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="false" [useIcons]="false"
												[allowAdding]="false">
											</dxo-editing> -->

											<dxi-column caption="Salesperson" dataType="string"
												cellTemplate="cellTemplateSalesperson" [allowFiltering]="true"
												[allowHeaderFiltering]="false">
												<div *dxTemplate="let element of 'cellTemplateSalesperson'">
													{{element.data.first_name || ''}} {{' '+ element.data.last_name || ''}}
												</div>
											</dxi-column>
										</dx-data-grid>
									</div>
									<dxo-scrolling mode="virtual" rowRenderingMode="virtual" [scrollByThumb]="true"
										[showScrollbar]="'always'" [useNative]="false">
									</dxo-scrolling>
									<dxo-selection [allowSelectAll]="false"></dxo-selection>
								</dx-data-grid>
							</div>
						</div>
					</dx-drop-down-box>
				</div>
			</div>

			<app-territory-dx-drop-down-box *ngIf="is_use_territory_for_commission_contract"
				[territoryModel]="territoryModel">
			</app-territory-dx-drop-down-box>
		</div>
		<div class="cols6 cols6addbtn" *ngIf="!enable_classification_for_commision">
			<div class="dx-field feildCol">
				<div class="dx-field-label">Items</div>
				<div class="dx-field-value" style="width: 100%;">
					<dx-drop-down-box [dataSource]="itemDataSource" [(value)]="lengthSelectedItems"
						[displayExpr]="doDisplayExprItemsText" valueExpr="itemname" placeholder="Select Item(s)..."
						[showClearButton]="true" (onValueChanged)="onItemValueChanged($event)">
						<div *dxTemplate="let data of 'content'">
							<dx-check-box text="All Items" (onValueChanged)="checkAllItem()" [(value)]="chkAllItem"
								class="mrgB10" width="25%">
							</dx-check-box>
							<!-- <dx-text-box *ngIf="itemGridBoxValue && itemGridBoxValue.length > 0" [readOnly]="true"
								[hoverStateEnabled]="false" [(value)]="strSelectedItemList"></dx-text-box> -->
							<dx-tag-box [(value)]="arrSelectedItemList" [grouped]="true"
								[visible]="!enable_profit_on_document_header">
							</dx-tag-box>
							<dx-data-grid #itemDataGrid [dataSource]="itemDataSource" [columns]="itemGridBoxColumns"
								[selection]="{ mode: 'multiple'}" [hoverStateEnabled]="true" [allowColumnReordering]="true"
								[allowColumnResizing]="true" [paging]="{ enabled: true, pageSize: 10 }"
								[filterRow]="{ visible: true }" [height]="325" [(selectedRowKeys)]="arrSelectedItemList"
								[remoteOperations]="true" [wordWrapEnabled]="true" [showBorders]="true"
								(onSelectionChanged)="onItemSelectionChanged($event)"
								[visible]="!enable_profit_on_document_header">
								<dxo-scrolling mode="virtual" rowRenderingMode="virtual" [scrollByThumb]="true"
									[showScrollbar]="'always'" [useNative]="false">
								</dxo-scrolling>
								<dxo-selection [allowSelectAll]="false"></dxo-selection>
							</dx-data-grid>
						</div>
					</dx-drop-down-box>
				</div>
			</div>
		</div>
		<div class="cols6 cols6addbtn" *ngIf="enable_classification_for_commision">
			<div class="dx-field feildCol">
				<div class="dx-field-label">Classes</div>
				<div class="dx-field-value" style="width: 100%;">
					<dx-drop-down-box [dataSource]="classesDataSource" [(value)]="lengthSelectedClasses"
						[displayExpr]="doDisplayExprClassesText" valueExpr="name" placeholder="Select Classes..."
						[showClearButton]="true" (onValueChanged)="onClassesValueChanged($event)">
						<div *dxTemplate="let data of 'content'">
							<dx-check-box text="All Classes" (onValueChanged)="checkAllClasses()" [(value)]="chkAllClasses"
								class="mrgB10" width="25%">
							</dx-check-box>
							<!-- <dx-text-box *ngIf="classesGridBoxValue && classesGridBoxValue.length > 0" [readOnly]="true"
								[hoverStateEnabled]="false" [(value)]="strSelectedClassesList"></dx-text-box> -->
							<dx-tag-box [(value)]="arrSelectedClassesList" [grouped]="true">
							</dx-tag-box>
							<dx-data-grid #classesDataGrid [dataSource]="classesDataSource" [columns]="classesGridBoxColumns"
								[selection]="{ mode: 'multiple'}" [hoverStateEnabled]="true" [allowColumnReordering]="true"
								[allowColumnResizing]="true" [paging]="{ enabled: true, pageSize: 10 }"
								[filterRow]="{ visible: true }" [height]="325" [(selectedRowKeys)]="arrSelectedClassesList"
								[remoteOperations]="true" [wordWrapEnabled]="true" [showBorders]="true"
								(onSelectionChanged)="onClassesSelectionChanged($event)">
								<dxo-scrolling mode="virtual" rowRenderingMode="virtual" [scrollByThumb]="true"
									[showScrollbar]="'always'" [useNative]="false">
								</dxo-scrolling>
								<dxo-selection [allowSelectAll]="false"></dxo-selection>
							</dx-data-grid>
						</div>
					</dx-drop-down-box>
				</div>
			</div>
		</div>
		<div class="cols1 txtRight cols1addbtn">
			<dx-button class="secondaryBtn button center" type="normal" stylingMode="contained" [useSubmitBehavior]="false"
				icon="add" (onClick)="addExclusionAgreementTabDetails()" text="Add">
			</dx-button>
		</div>
	</div>
</div>
<div class="cf mrgT10"></div>
<div class="dx-field feildCol">
	<div class="dx-field-value" style="width: 100%;" *ngIf="isViewExlusionTabGrid">
		<dx-data-grid #exclusionTabDataGridRef id="gridContainer" [dataSource]="dataSourceExclusionTab"
			[remoteOperations]="false" [showBorders]="true" [columnAutoWidth]="true" [allowColumnReordering]="true"
			[allowColumnResizing]="true" (onToolbarPreparing)="doToolbarPreparing($event)"
			[masterDetail]="{ enabled: true, template: 'tiersTemplate','autoExpandAll': false }"
			(onEditorPreparing)="doOnEditorPreparingExclusionTab($event)"
			(onRowUpdated)="doOnRowUpdatedExclusionTab($event)" (onRowRemoved)="doOnRowRemovedExclusionTab($event)"
			(onSaved)="doOnSavedExclusionTab()" (onRowPrepared)="doOnonRowPreparedExclusionTab($event)"
			[paging]="{ enabled: true, pageSize: 50 }" (onRowValidating)="doOnRowValidatingExclusionTab($event)"
			[height]="'40%'" [customizeColumns]="exclusionGridCustomizeColumns"
			(onInitialized)="initializedInvoiceDataGrid($event)" keyExpr="guid" class="hideFilterPanelInx">

			<dxo-sorting mode="single"></dxo-sorting>
			<dxo-filter-row [visible]="true"></dxo-filter-row>
			<dxo-filter-panel [visible]="true"></dxo-filter-panel>
			<dxo-header-filter [visible]="true"></dxo-header-filter>
			<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
			<!-- <dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>  -->
			<!-- <dxo-pager [visible]="true" [showPageSizeSelector]="true"
									[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true"></dxo-pager> -->
			<dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="false" [useIcons]="true">
			</dxo-editing>

			<div *dxTemplate="let data of 'templateFilterText'">
				<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
					<span>{{filterText}}</span>
				</div>
			</div>
			<!-- 
			<dxi-column caption="Customer/Group" [visible]="!enable_classification_for_commision" dataField="clientname"
				[allowEditing]="false" cellTemplate="cellTemplateCustomer" [allowHeaderFiltering]="false"
				[allowFiltering]="true" [allowSorting]="true">
				<div *dxTemplate="let element of 'cellTemplateCustomer'">
					{{element.data.clientname || ''}}
				</div>
			</dxi-column> -->
			<dxi-column caption="Customers" dataField="clientname" dataType="string" [allowEditing]="false"
				cellTemplate="cellTemplateCustomer" [visible]="!is_use_territory_for_commission_contract"
				[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true">
				<div *dxTemplate="let element of 'cellTemplateCustomer'">
					{{element.data.clientname || ''}}
				</div>
			</dxi-column>
			<dxi-column caption="Territory Name" dataField="territoryname" dataType="string" [allowEditing]="false"
				[visible]="is_use_territory_for_commission_contract" cellTemplate="cellTemplateTerritory"
				[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true">
				<div *dxTemplate="let element of 'cellTemplateTerritory'">
					{{element.data.territoryname || ''}}
				</div>
			</dxi-column>

			<dxi-column caption="SKU"
				[visible]="isQBOnlineErpUser && !enable_classification_for_commision && commissionDetails.tiers_type === 'single_items'"
				dataField="itemsku" dataType="string" [allowEditing]="false" cellTemplate="cellTemplateItemSku"
				[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true">
				<div *dxTemplate="let element of 'cellTemplateItemSku'">
					{{element.data.itemsku || ''}}
				</div>
			</dxi-column>
			<dxi-column caption="Item(s)" dataField="items"
				[visible]="!enable_classification_for_commision && commissionDetails.tiers_type === 'multiple_items'"
				dataType="string" [allowEditing]="false" cellTemplate="cellTemplateItems" [allowHeaderFiltering]="false"
				[allowFiltering]="true" [allowSorting]="true">
				<div *dxTemplate="let element of 'cellTemplateItems'">
					{{element.data.items && element.data.items.length > 0 ? element.data.items[0].itemname : ''}}
					<span
						*ngIf="element.data.items[0] && (element.data.items.length === 2 || element.data.items.length >= 3)"
						id="itemsToolTip_{{element.data.guid}}">
						+ {{element.data.items.length-1}}
						{{ element.data.items.length === 2 ? 'Other' : element.data.items.length >= 3 ?
						'Others' : ''}}
					</span>
					<dx-tooltip target="#itemsToolTip_{{element.data.guid}}" showEvent="dxhoverstart" hideEvent="dxhoverend"
						position="right">
						<div *dxTemplate="let d = d; of: 'content'">
							<div class="tooltip-wrapper">
								<div class="greenTipout">
									<ol class="tipList">
										<li *ngFor="let item of element.data.items; let p = index;">
											<a>{{item.itemname || ''}}</a>
										</li>
									</ol>
								</div>
							</div>
						</div>
					</dx-tooltip>
				</div>
			</dxi-column>
			<dxi-column caption="Item"
				[visible]="!enable_classification_for_commision && commissionDetails.tiers_type === 'single_items'"
				dataField="itemname" dataType="string" [allowEditing]="false" cellTemplate="cellTemplateItemName"
				[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true">
				<div *dxTemplate="let element of 'cellTemplateItemName'">
					{{element.data.itemname || ''}}
				</div>
			</dxi-column>
			<dxi-column caption="Classes" [visible]="enable_classification_for_commision" dataField="classname"
				dataType="string" [allowEditing]="false" cellTemplate="cellTemplateClassesName"
				[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true">
				<div *dxTemplate="let element of 'cellTemplateClassesName'">
					{{element.data.classname || ''}}
				</div>
			</dxi-column>
			<dxi-column caption="Description"
				[visible]="!enable_classification_for_commision && commissionDetails.tiers_type === 'single_items'"
				dataField="description" dataType="string" [allowEditing]="false" cellTemplate="cellTemplateItemDesc"
				[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true">
				<div *dxTemplate="let element of 'cellTemplateItemDesc'">
					{{element.data.description || ''}}
				</div>
			</dxi-column>

			<dxi-column caption="Commission Type" dataField="tier_basis" dataType="string" [allowEditing]="true"
				[setCellValue]="setCellValueTierBasisColumn" cellTemplate="cellTemplateTierBasis"
				[allowHeaderFiltering]="true" [allowFiltering]="false" [allowSorting]="false">
				<dxo-lookup [dataSource]="listOfTierBasis" valueExpr="code" [displayExpr]="getDisplayExprTierbasis">
				</dxo-lookup>
				<dxi-validation-rule type="required"></dxi-validation-rule>
				<div *dxTemplate="let element of 'cellTemplateTierBasis'">
					{{element.data.tier_basis || '' | filterFromList:listOfTierBasis : 'code' : 'label' | uppercase}}
				</div>
			</dxi-column>

			<dxi-column caption="Commission Basis" dataField="commission_basis" dataType="string" [allowEditing]="true"
				[setCellValue]="setCellValueCommissionBasisColumn" cellTemplate="cellTemplateCommissionBasis"
				[allowHeaderFiltering]="true" [allowFiltering]="false" [allowSorting]="false" [width]="150">
				<dxo-lookup [dataSource]="listOfCommissionBasis" valueExpr="code"
					[displayExpr]="getDisplayExprCommissionbasis">
				</dxo-lookup>
				<!-- <dxi-validation-rule type="required"></dxi-validation-rule> -->
				<div *dxTemplate="let element of 'cellTemplateCommissionBasis'">
					{{element.data.commission_basis || '' | filterFromList:listOfCommissionBasis : 'code' : 'label' |
					uppercase}}
				</div>
			</dxi-column>

			<dxi-column caption="UOM" dataField="selectedUom" dataType="string"
				[visible]="!enable_classification_for_commision && commissionDetails.tiers_type === 'single_items'"
				[allowEditing]="true" [setCellValue]="setCellValueUomColumn" cellTemplate="cellTemplateUomCode"
				[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="false">
				<dxo-lookup [dataSource]="listOfUoms" valueExpr="code" [displayExpr]="getDisplayExprUomName">
				</dxo-lookup>
				<div *dxTemplate="let element of 'cellTemplateUomCode'">
					<span *ngIf="element.data.commission_basis === 'dollarperuom'">
						{{element.data.selectedUom || '' | filterFromList:listOfUoms : 'code' : 'name' | uppercase}}
					</span>
				</div>
			</dxi-column>

			<dxi-column caption="Rate" dataField="rate" dataType="number" [allowEditing]="true"
				[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="false"
				[editorOptions]="{ format: 'decimal', showClearButton: true }" cellTemplate="cellTemplateRate"
				[width]="100">
				<!-- <dxi-validation-rule type="required"></dxi-validation-rule> -->
				<div *dxTemplate="let element of 'cellTemplateRate'">
					<span *ngIf="(element.data.commission_basis === 'dollar' || element.data.commission_basis === 'dollarperuom') &&
						(element.data.tier_basis === 'notiers')">
						{{element.data.rate || 0 | customCurrencyPipe:customCurrencyOption }}
					</span>
					<span *ngIf="element.data.commission_basis === 'percentage' &&  (element.data.tier_basis === 'notiers')">
						{{(element.data.rate || 0 | number:decimalPointPercentageFormat) + '%'}}
					</span>
				</div>
			</dxi-column>

			<dxi-column type="buttons" caption="Action" [allowFiltering]="false" [allowHeaderFiltering]="false"
				[fixed]="true" fixedPosition="right" [allowSorting]="false" alignment="center" [allowResizing]="false"
				[minWidth]="150" [width]="150" [allowExporting]="false">
				<dxi-button name="edit" cssClass="dx-grid-edit menuTip"></dxi-button>
				<dxi-button name="delete" cssClass="dx-grid-delete menuTip"></dxi-button>
			</dxi-column>
			<div *dxTemplate="let c of 'tiersTemplate'">
				<!-- #region customer age tiers content -->
				<app-commission-tierbase-customer-age-tiers-tab
					(saveCommissionCustomerTiersEvent)="saveCommissionCustomerTiersData($event)"
					[customer_age_tiers]="c.data.customer_age_tiers" [tiersData]="getCustomerAgeTiersByItemDetails(c)"
					*ngIf="isCustomerAgeTiers">
				</app-commission-tierbase-customer-age-tiers-tab>
				<!--endregion-->

				<dx-data-grid #tiersItemDetailssDataGridRef *ngIf="!isCustomerAgeTiers" id="tiersItemDetailssDataGridRef"
					[dataSource]="getTiersByItemDetails(c)" (onInitNewRow)="doOnInitNewRowTiersItmTab($event,c)"
					(onRowInserted)="doOnRowInsertedTiersItmTab($event,c)"
					(onEditorPreparing)="doOnEditorPreparingTiersItmTab($event,c)"
					(onRowUpdated)="doOnRowUpdatedTiersItmTab($event,c)" (onRowRemoved)="doOnRowRemovedTiersItmTab($event,c)"
					(onSaved)="doOnSavedTiersItmTab($event,c)" keyExpr="guid" [showBorders]="true" [columnAutoWidth]="true"
					[allowColumnResizing]="true"
					[masterDetail]="{ enabled: true, template: 'itemsTemplate','autoExpandAll': false }">
					<dxo-filter-row [visible]="false"></dxo-filter-row>
					<dxo-filter-panel [visible]="false"></dxo-filter-panel>
					<dxo-header-filter [visible]="false"></dxo-header-filter>

					<dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true"
						[allowAdding]="!(c.data.tier_basis === 'notiers')" [useIcons]="true">
					</dxo-editing>

					<dxi-column caption="Tier Minimum" dataField="mintier" dataType="number" [allowEditing]="true"
						[editorOptions]="{ format: 'decimal', showClearButton: true }" cellTemplate="cellTemplateMinTier">
						<!-- [customizeText]="doCustomizeTextTierMinMax($event,c)" -->
						<div *dxTemplate="let element of 'cellTemplateMinTier'">
							{{doCustomizeTextTierMinMax(element.data.mintier || 0,c,'mintier') }}
						</div>
					</dxi-column>

					<dxi-column caption="Tier Maximum" dataField="maxtier" dataType="string" [allowEditing]="true"
						[editorOptions]="{ showClearButton: true }" cellTemplate="cellTemplateMaxTier">
						<!-- [customizeText]="doCustomizeTextTierMinMax($event,c)" -->
						<dxi-validation-rule type="required"></dxi-validation-rule>
						<div *dxTemplate="let element of 'cellTemplateMaxTier'">
							{{doCustomizeTextTierMinMax(element.data.maxtier || '',c,'maxtier') }}
						</div>
					</dxi-column>

					<dxi-column
						[caption]="'Rate '+ c.data.commission_basis === 'dollar' ? '('+globalCurrencySymbol+')' : c.data.commission_basis === 'percentage' ? '(%)' :'('+globalCurrencySymbol+')'"
						dataField="rate" dataType="number" [allowEditing]="true"
						[editorOptions]="{ format: 'decimal', showClearButton: true }" cellTemplate="cellTemplateRate"
						[visible]="commissionDetails.tiers_type === 'single_items'">
						<dxi-validation-rule type="required"
							*ngIf="commissionDetails.tiers_type === 'single_items'"></dxi-validation-rule>
						<div *dxTemplate="let element of 'cellTemplateRate'">
							<span *ngIf="(c.data.commission_basis === 'dollar' || c.data.commission_basis === 'dollarperuom')">
								{{element.data.rate || 0 | customCurrencyPipe:customCurrencyOption }}
							</span>
							<span *ngIf="c.data.commission_basis === 'percentage'">
								{{(element.data.rate || 0 | number:decimalPointPercentageFormat) + '%' }}
							</span>
						</div>
					</dxi-column>

					<dxi-column type="buttons" caption="Action" [allowFiltering]="false" [allowHeaderFiltering]="false"
						[fixed]="true" fixedPosition="right" [allowSorting]="false" alignment="center" [allowResizing]="false"
						[minWidth]="150" [width]="150" [allowExporting]="false">
						<dxi-button name="edit" cssClass="dx-grid-edit menuTip"></dxi-button>
						<dxi-button name="delete" cssClass="dx-grid-delete menuTip"></dxi-button>
					</dxi-column>

					<div *dxTemplate="let item of 'itemsTemplate'">
						<dx-data-grid #itemsOfTiersDataGridRef *ngIf="!isCustomerAgeTiers"
							[dataSource]="getItemsByTiersDetails(item)"
							(onEditorPreparing)="doEditorPreparingItemTierItmTab($event,item,c)"
							(onRowUpdated)="doRowUpdatedItemTierItmTab($event,item,c)"
							(onRowRemoved)="doRowRemovedItemTierItmTab($event,item,c)"
							(onSaved)="doOnSavedItemTierItmTab($event,item,c)" keyExpr="erp_primary_key" [showBorders]="true"
							[allowColumnResizing]="true" [columnAutoWidth]="true">
							<dxo-filter-row [visible]="true"></dxo-filter-row>
							<dxo-filter-panel [visible]="true"></dxo-filter-panel>
							<dxo-header-filter [visible]="true"></dxo-header-filter>
							<dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="false"
								[useIcons]="true"> </dxo-editing>

							<dxi-column caption="Item" dataField="itemname" dataType="string" [allowEditing]="false"
								editCellTemplate="itemsListEditor" cellTemplate="cellTemplateItemName"
								[allowHeaderFiltering]="false" [allowSorting]="true">
								<div *dxTemplate="let element of 'cellTemplateItemName'">
									{{element.data.itemname}}
								</div>
							</dxi-column>

							<dxi-column caption="SKU" [visible]="isQBOnlineErpUser && !enable_classification_for_commision"
								dataField="itemsku" dataType="string" [allowEditing]="false" cellTemplate="cellTemplateItemSku"
								[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true">
								<div *dxTemplate="let element of 'cellTemplateItemSku'">
									{{element.data.itemsku || ''}}
								</div>
							</dxi-column>

							<dxi-column caption="Description" dataField="description" dataType="string" [allowEditing]="false"
								[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true"
								cellTemplate="cellTemplateItemDesc">
								<div *dxTemplate="let element of 'cellTemplateItemDesc'">
									{{element.data.description || ''}}
								</div>
							</dxi-column>

							<dxi-column caption="UOM" dataField="selectedUom" dataType="string"
								[allowEditing]="c.data.commission_basis === 'dollarperuom'"
								[setCellValue]="setCellValueUomColumn" cellTemplate="cellTemplateUomCode"
								[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="false">
								<dxo-lookup [dataSource]="listOfUoms" valueExpr="code" [displayExpr]="getDisplayExprUomName">
								</dxo-lookup>
								<div *dxTemplate="let element of 'cellTemplateUomCode'">
									<span *ngIf="c.data.commission_basis === 'dollarperuom'">
										{{element.data.selectedUom || '' | filterFromList:listOfUoms : 'code' : 'name' |
										uppercase}}
									</span>
								</div>
							</dxi-column>

							<dxi-column
								[caption]="'Rate '+ c.data.commission_basis === 'dollar' ? '('+globalCurrencySymbol+')' : c.data.commission_basis === 'percentage' ? '(%)' :'('+globalCurrencySymbol+')'"
								dataField="rate" dataType="number" [allowEditing]="true" [allowHeaderFiltering]="false"
								[allowFiltering]="false" [allowSorting]="false"
								[editorOptions]="{ format: 'decimal', showClearButton: true }"
								cellTemplate="cellTemplateItemRate" [width]="100">
								<dxi-validation-rule type="required"></dxi-validation-rule>
								<div *dxTemplate="let element of 'cellTemplateItemRate'">
									<span
										*ngIf="(c.data.commission_basis === 'dollar' || c.data.commission_basis === 'dollarperuom')">
										{{element.data.rate || 0 | customCurrencyPipe:customCurrencyOption }}
									</span>
									<span *ngIf="c.data.commission_basis === 'percentage'">
										{{(element.data.rate || 0 | number:decimalPointPercentageFormat) + '%' }}
									</span>
								</div>
							</dxi-column>

							<dxi-column type="buttons" caption="Action" [allowFiltering]="false" [allowHeaderFiltering]="false"
								[fixed]="true" fixedPosition="right" [allowSorting]="false" alignment="center"
								[allowResizing]="false" [minWidth]="150" [width]="150" [allowExporting]="false">
								<dxi-button name="edit" cssClass="dx-grid-edit menuTip"></dxi-button>
								<dxi-button name="delete" cssClass="dx-grid-delete menuTip"></dxi-button>
							</dxi-column>

						</dx-data-grid>
					</div>

				</dx-data-grid>
			</div>
		</dx-data-grid>
	</div>
</div>
<div class="cf"></div>