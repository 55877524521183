export class RoyaltiesContractModelUI {
	public ixcode: string;
	public guid: string;
	public baseContractCode: string;
	public percentOrDollar: string;
	public contractName: string;
	public royaltiesPercent: string;
	public employeeCode: string;
	public employeeName: string;
	public startDate: any;
	public endDate: any;
	public settlementPeriodStart: string;
	public settlementPeriodEnd: string;
	public settlementPeriod: string;
	public agreementBy: string;
	public allproducts: boolean;
	public allclients: boolean;
	public isSettlementPeriodClosed: boolean;
	public is_active: boolean;
	public selectVendorId: string;
	public selectVendor: string;
	public clients: CustomerTabModel[] = [];
	public items: ProductAndGroupTabModel[] = [];
	public excludedItems: ExclusionTabModel[] = [];
	public rules: RuleTabModel[] = [];
	public current_settlement_invoice_total: any;
	public current_settlement_commission_total: any;
	public current_settlement_creditmemo_total: any;
	public contractCalendar: string;
	public startSettlementPeriodOn: any;
	public amortized_payments: any[] = [];
	constructor() {
		this.startSettlementPeriodOn = '1';
		this.contractCalendar = 'fiscal_year';
	}
}

export class RoyaltiesContractModelRequest {
	public ixcode: string;
	public guid: string;
	public baseContractCode: string;
	public percentOrDollar: string;
	public contractName: string;
	public royaltiesPercent: string;
	public employeeCode: string;
	public employeeName: string;
	public startDate: any;
	public endDate: any;
	public settlementPeriodStart: string;
	public settlementPeriodEnd: string;
	public settlementPeriod: string;
	public agreementBy: string;
	public allproducts: boolean;
	public allclients: boolean;
	public isSettlementPeriodClosed: boolean;
	public is_active: boolean;
	public current_settlement_invoice_total: any;
	public current_settlement_commission_total: any;
	public selectVendorId: string;
	public selectVendor: string;
	public clients: CustomerTabModel[] = [];
	public items: ProductAndGroupTabModel[] = [];
	public excludedItems: ExclusionTabModel[] = [];
	public customer_items_groups?: ExclusionTabModel[] = [];
	public rules: RuleTabModel[] = [];
	public contractCalendar: string;
	public startSettlementPeriodOn: string;
	public amortized_payments: any[] = [];
	public distribution_channels?: string;
}

export class CustomerTabModel {
	public code: string;
	public name: string;
}

export class ProductAndGroupTabModel {
	public itemcode: string;
	public itemname: string;
	public itemOrGroup: string;
}

export class ExclusionTabModel {
	public clientcode: string;
	public clientname: string;
	public itemOrGroup: string;
	public productcode: string;
	public productname: string;
	public rate: string;
}

export class ExclusionTierbaseTabModel {
	public guid: string;
	public erp_primary_key: any;
	public clientguid: string;
	public clientcode: string;
	public clientname: string;
	public mycustomers: boolean;
	public allcustomers: boolean;
	public allcustomergroups: boolean;
	public itemsku: string;
	public itemOrGroup: any;
	public itemname: string;
	public allproducts: boolean;
	public description: string;
	public tier_basis: string; // Value Could be "volume" | "revenue" | "notiers"
	public commission_basis: any;  // Value could be "doller" | "percentage" | "dollerperuom"
	public uomlist: any;
	public selectedUom: string;
	public listPrice: any;
	public rate: any;
	public royaltiesfinalprice: any;
	public tiers: any
	constructor() {
		this.allcustomers = false;
		this.allcustomergroups = false;
		this.allproducts = false;
	}
}

export class RuleTabModel {
	public condition: string;
	public criterion: string;
	public name: string;
	public ruleBonusType: string;
	public ruleRate: string;
}

export class Invoices {
	linenum: string;
	itemsku: string;
	itemdescription: string;
	price: number;
	linetotal: number;
	quantity: number;
	grossprice: number;
	grosstotal: number;
	costperitem: number;
	pickstatus: string;
	linestatus: string;
	taxpercentageperrow: number;
	taxtotal: number;
	uomcode: string;
	discountpercent: number;
	grossprofit: number;
	commissionRate: string;
	commissionType: string;
	condition: string;
	criterion: string;
	itemname: string;
	description: string;
	isRuleApplied: boolean;
	commissionRateIn: string;
	commissionAmount: number;
	invoiceCode: number;
	invoiceDate: string;
	clientCode: string;
	clientName: string;
	year: number;
	month: string;
	monthYear: string;
}

export class ExtraCommissionBasedRules {
	commissionAmount: number;
	commissionRate: number;
	commissionRateIn: string;
	commissionType: string;
	condition: string;
	criterion: string;
}

export class ARcreditMemos {
	clientCode: string;
	clientName: string;
	commissionAmount: string;
	commissionRate: string;
	commissionRateIn: string;
	commissionType: string;
	condition: string;
	criterion: string;
	doctype: string;
	description: string;
	invoiceCode: string;
	isRuleApplied: string;
	itemsku: string;
	// itemdescription: string;
	itemname: string;
	linetotal: number;
	price: number;
	quantity: number;
}

export class MiscellaneousDeductionRules {
	commissionAmount: number;
	commissionRate: number;
	commissionRateIn: string;
	commissionType: string;
	condition: string;
	criterion: string;
	linetotal: string;
}

export class RoyaltiesSettlemntRptModel {
	public ixcode: string;
	public contractGuid: string;
	public contractName: string;
	public selectVendor: string;
	public calculate_royalties_by: string;
	public currency_code: string;
	public calculationOnBasis: string;
	public settlementPeriodStartDate: any;
	public settlementPeriodEndDate: any;
	public created_date: any;
	public remark: string;
	public apInvoiceCreatedInERP: string;//Open,Processing,Success,Error
	public settlementValue: any;
	public totalRoyaltiesGenerated: any;
	public totalRoyaltiesPayable: any;
	public subusername: string;
	public apInvoiceNumber: string;
	public currencyFormat: any;
	constructor() {

	}
}