import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuardService } from '../services/auth-guard.service';
import { CrmsModule } from './../pages/crms/crms.module';
import { BusinessplansModule } from './../pages/businessplans/businessplans.module';
import { ContractsModule } from './../pages/contracts/contracts.module';
import { ReportsModule } from './../pages/reports/reports.module';
import { SalesModule } from './../pages/sales/sales.module';
import { UsermanagementsModule } from './../pages/usermanagements/usermanagements.module';
import { ProductsModule } from './../pages/products/products.module';
import { ApplicationConfigurationsModule } from './../pages/application-configurations/application-configurations.module';
import { environment } from "@environments/environment";
const routes: Routes = [{
	path: '',
	component: PagesComponent,
	children: [
		{
			path: 'dashboard',
			component: DashboardComponent,
			canActivate: [AuthGuardService],
			data: {
				title: 'Dashboard',
				breadcrumb: [
					{
						label: 'Dashboard',
						url: ''
					}
				]
			}
		},
		{
			path: 'crms',
			loadChildren: () => import('./crms/crms.module').then(m => m.CrmsModule),
			canActivateChild: [AuthGuardService]
		},
		{
			path: 'businesspartners',
			loadChildren: () => import('./businessplans/businessplans.module').then(m => m.BusinessplansModule),
			canActivateChild: [AuthGuardService]
		},
		{
			path: 'contracts',
			loadChildren: () => import('./contracts/contracts.module').then(m => m.ContractsModule),
			canActivateChild: [AuthGuardService]
		},
		{
			path: 'reports',
			loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
			canActivate: [AuthGuardService]
		},
		{
			path: 'sales',
			loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule),
			canActivateChild: [AuthGuardService]
			// canActivate: [AuthGuardService]
		},
		{
			path: 'usermanagements',
			loadChildren: () => import('./usermanagements/usermanagements.module').then(m => m.UsermanagementsModule),
			canActivateChild: [AuthGuardService]
		},
		{
			path: 'products',
			loadChildren: () => import('./products/products.module').then(m => m.ProductsModule),
			canActivateChild: [AuthGuardService]
		},
		{
			path: 'apps-configs',
			loadChildren: () => import('./application-configurations/application-configurations.module').then(m => m.ApplicationConfigurationsModule),
			canActivateChild: [AuthGuardService]
		},
		{
			path: 'subscriptions',
			loadChildren: () => import('./subscriptions/subscriptions.module').then(m => m.SubscriptionsModule),
			canActivateChild: [AuthGuardService]
		},
		{
			path: 'notifications',
			loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
			canActivateChild: [AuthGuardService]
		},
		{
			path: 'purchases',
			loadChildren: () => import('./purchases/purchases.module').then(m => m.PurchasesModule),
			canActivateChild: [AuthGuardService]
		},
		{
			path: 'financials',
			loadChildren: () => import('./financials/financials.module').then(m => m.FinancialsModule),
			canActivateChild: [AuthGuardService]
		},
		{
			path: '',
			redirectTo: 'dashboard',
			pathMatch: 'full',
		}
	]
}];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PagesRoutingModule { }
