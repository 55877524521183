import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderState } from '@app/models/loaderstate.model';
import { DynamicScriptLoaderService } from '@app/services/dynamic-script-loader/dynamic-script-loader.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { DataService } from '@app/services/data.service';
import { LoginService } from '@app/services/login.service';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from '@environments/environment';
import { IxDxAlertService } from './services/ix-dx-alert-services/ix-dx-alert-services';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
	public loaderState = new LoaderState();
	public loaderSbsn: Subscription;
	private dialCodeSbsn: Subscription;
	private countrySbsn: Subscription;
	private isPressBackTwice: number = 0;
	private getListOfTimezoneSbsn: Subscription;

	constructor(private dynamicScriptLoader: DynamicScriptLoaderService,
		private loaderService: LoaderService,
		private _DataService: DataService,
		private _RestApiService: RestApiService,
		private _IxDxAlertService: IxDxAlertService,
		private _LoginService: LoginService,
		private _AppCommonSrvc: AppCommonSrvc,
		private _LocationStrategy: LocationStrategy, private _router: Router) {
		this._LocationStrategy.onPopState((onNext) => {
			if (onNext.type === "popstate" && history.state && history.state.navigationId === -1) {
				history.pushState(null, null, onNext['srcElement'].location.href + 'dashboard');
			}

			if (onNext.type === "popstate" && onNext['srcElement'].location.href.endsWith('/dashboard') && history.state === null) {
				this.isPressBackTwice += 1;
				if (this.isPressBackTwice >= 2) {
					const ixCustomDialogOptions: any = {
						popupIconsHtml: '<div class="confirmationIcon"><div class="infoIcon mrgT0"></div></div>',
						showTitle: false,
						subtitle: 'Logout',
						message: 'Are you sure you want to Logout ?',
					};

					setTimeout(() => {
						this._IxDxAlertService.doShowDefaultConfirmAlert(ixCustomDialogOptions).then(result => {
							this.isPressBackTwice = 0;
							if (result) {
								this._LoginService.loginUser = undefined;
								this._router.navigate(['auth/login'], { replaceUrl: true });
							}
						});
					}, 50);
				}
			}

			// set isBackButtonClicked to true.
			this._AppCommonSrvc.setBackClicked(true);
			return false;
		});
	}

	ngOnInit() {
		this.loaderSbsn = this.loaderService.loaderState.subscribe((state: LoaderState) => {
			setTimeout(() => {
				this.loaderState = state;
				// this.loaderState.loaded = state.loaded;
				// this.loaderState.dx_visible = state.dx_visible;
				// this.loaderState.dx_position = state.dx_position;
				// this.loaderState.dx_shadingColor = state.dx_shadingColor;
				// this.loaderState.dx_showPane = state.dx_showPane;
				// this.loaderState.dx_showIndicator = state.dx_showIndicator;
				// this.loaderState.dx_shading = state.dx_shading;
				// this.loaderState.dx_closeOnOutsideClick = state.dx_closeOnOutsideClick;
			}, 50);
		});
		this.loadScripts();
		this.getDialCode();
		this.getCountryList();
		this.getServerTimezoneList();
	}

	ngOnDestroy(): void {
		if (this.loaderSbsn) {
			this.loaderSbsn.unsubscribe();
		}
		if (this.dialCodeSbsn) {
			this.dialCodeSbsn.unsubscribe();
		}
		if (this.countrySbsn) {
			this.countrySbsn.unsubscribe();
		}
		if (this.getListOfTimezoneSbsn) {
			this.getListOfTimezoneSbsn.unsubscribe();
		}
	}

	private loadScripts() {
		// You can load multiple scripts by just providing the key as argument into load method of the service
		if (environment.production && environment.SITE_24X7) {
			this.dynamicScriptLoader.load('site_24x7').then(result => { }).catch(error => console.log(error));
		}
		this.dynamicScriptLoader.load('jquery').then(data => {
			// Script Loaded Successfully
			this.dynamicScriptLoader.load('functions', 'datatables', 'general').then(result => {
				// Script Loaded Successfully
				// this.dynamicScriptLoader.load('highcharts', 'dashboard-highchart', 'crm-highchart').then(highChartResult => {
				// Script Loaded Successfully
				// }).catch(error => console.log(error));
			}).catch(error => console.log(error));
		}).catch(error => console.log(error));
	}

	private getDialCode() {
		if (this.dialCodeSbsn) {
			this.dialCodeSbsn.unsubscribe();
		}
		this.dialCodeSbsn = this._RestApiService.doPhoneCodeReq().subscribe((res: any) => {
			if (res && res.length > 0) {
				this._DataService.setDialCodes(res);
			}
		});
	}

	private getCountryList() {
		if (this.countrySbsn) {
			this.countrySbsn.unsubscribe();
		}
		this.countrySbsn = this._RestApiService.doGetContryReq().subscribe((res: any) => {
			if (res && res.length > 0) {
				this._DataService.setCountries(res);
			}
		});
	}

	private getServerTimezoneList() {
		if (this.getListOfTimezoneSbsn) {
			this.getListOfTimezoneSbsn.unsubscribe();
		}
		this.getListOfTimezoneSbsn = this._RestApiService.doGetTimezoneReq().subscribe(response => {
			this._DataService.setTimezones(response);
		}, error => {
		});
	}


	ngAfterViewInit() {
		setTimeout(() => {
			this.setDxPopupHeightWidth();
		}, 500);
	}


	@HostListener('window:resize', ['$event'])
	public onResize(event) {
		this.setDxPopupHeightWidth();
	}

	private setDxPopupHeightWidth() {
		const innerWidth = window.innerWidth;
		const innerHeight = window.innerHeight;
		const hightwidthRatio = {
			innerWidth: innerWidth,
			innerHeight: innerHeight
		};
		this._AppCommonSrvc.setPopupHeightWidthRatio(hightwidthRatio);
	}

}
